import { HttpBackend, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilocateTokenService } from '../services/token/token.service';
import { UtilocateAdminCacheService } from '../cache/utilocate-admin.service';
import { BaseCompletionCacheService } from '../cache/cache.interface';
import { BaseUtilocateApiService, UtilocateApiRequest } from './baseapi.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { api, apiKeys } from '../../../ENDPOINTS';
import { AutologID } from '../services/user/setting';

export interface RegisterResult {
  isRegistered: boolean;
  CompanyInfo: object;
}

export class PassRegisterResult implements RegisterResult {
  isRegistered = true;
  CompanyInfo: any;
}

export class FailRegisterResult implements RegisterResult {
  isRegistered = false;
  CompanyInfo: any;
}

@Injectable({
  providedIn: 'root',
})
export class UtilocateApiService extends BaseUtilocateApiService {
  IS_LIVE: boolean;
  IS_DEV: boolean;
  isLocalHost: boolean;

  constructor(
    protected utilocateCacheService: UtilocateAdminCacheService,
    protected tokenService: UtilocateTokenService,
    protected handler: HttpBackend,
    protected baseCompletionCacheService: BaseCompletionCacheService,
    protected route: Router
  ) {
    super(tokenService, utilocateCacheService, handler, baseCompletionCacheService, route);

    this.IS_LIVE = environment.production == true;
    this.IS_DEV = environment.production == false && environment.url == null;
    this.isLocalHost = environment.localhost;
  }

  async verifyRegistrationCode(code: string): Promise<RegisterResult> {
    try {
      let url = '/api/authenticate/companyCode'; // localhost

      if (!this.isLocalHost) {
        url = '/nodejs/api/authenticate/companyCode';
      }
      const registerResult = await this.invokeApi('PUT', url, { code: code });
      if (registerResult.status == 200) {
        if (registerResult.body && registerResult.body.results && registerResult.body.results.length > 0) {
          const result: PassRegisterResult = new PassRegisterResult();
          result.CompanyInfo = registerResult.body.results[0];
          return result;
        } else {
          const result: FailRegisterResult = new FailRegisterResult();
          return result;
        }
      } else {
        return new FailRegisterResult();
      }
    } catch (error) {
      console.log(error);
      return new FailRegisterResult();
    }
  }

  async generateTokenFieldSide(userID, password, clientID) {
    try {
      // basic requirement for UtilocateApiRequest
      let request: UtilocateApiRequest = {
        API_TYPE: 'PUT',
        API_KEY: apiKeys.competers.generateAuthTokenU2,
      };

      let stage = 'dev';
      if (environment.production) {
        stage = 'liveV0api2';
      }

      // update URL now because generateToken does not follow the same versioning as other APIs
      request = {
        ...request,
        API_URL:
          'https://sphso382q8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/generateAuthToken',
      };

      request = {
        ...request,

        API_URL_DATA_PARAMS: {
          ClientID: clientID,
          UserID: userID,
        },
        API_URL_TOKEN_PARAMS: {
          ClientID: clientID,
          UserID: userID,
          stage,
        },
        API_BODY: {
          Password: password,
        },
      };

      const result = await this.invokeUtilocateApi(request);
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  async generateTokenU4OfficeSide(email: string, password: string) {
    try {
      // basic requirement for UtilocateApiRequest
      let request: UtilocateApiRequest = {
        API_TYPE: 'PUT',
        API_KEY: apiKeys.competers.generateAuthTokenU4,
      };

      request = {
        ...request,
        API_URL: 'https://sphso382q8.execute-api.ca-central-1.amazonaws.com/{stage}/u4/generateAuthToken',
        API_BODY: {
          Email: email,
          Password: password,
        },
        API_URL_TOKEN_PARAMS: {},
      };

      const result = await this.invokeUtilocateApi(request);
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  async generateTokenU4Cognito(user: any) {
    try {
      // basic requirement for UtilocateApiRequest
      let request: UtilocateApiRequest = {
        API_TYPE: 'PUT',
        API_KEY: apiKeys.competers.generateAuthTokenU4,
      };

      request = {
        ...request,
        API_URL: `https://sphso382q8.execute-api.ca-central-1.amazonaws.com/{stage}/u4/generateAuthToken`,
        API_BODY: {
          Email: user.email,
          Password: '',
          AccessToken: user.accessToken,
          App: 'u4',
          ClientID: user.clientID,
        },
        API_URL_TOKEN_PARAMS: {},
      };

      const result = await this.invokeUtilocateApi(request);
      return JSON.parse(result.body.value).result[0];
    } catch (error) {
      console.log(error);
    }
  }

  async downloadLocatorTickets(): Promise<object | boolean> {
    const request: UtilocateApiRequest = {
      API_KEY: apiKeys.u2.downloadLocatorTickets,
      API_TYPE: 'GET',
    };

    const result = await this.invokeUtilocateApi(request); // added to cache, can end here
    if (!(result instanceof HttpErrorResponse) && result.body) {
      const tickets = JSON.parse(result.body);
      return tickets;
    } else {
      return false;
    }
  }

  /**
   * Creates a "Locate Examined" autolog (DescID 19) for a given AssignmentID 
   *
   * @param {(string | number)} assignmentID
   * @return {Promise<String | false>} the result or false if it errored 
   * @memberof UtilocateApiService
   */
  async createLocateExaminedAutolog(assignmentID: string | number, explanation: string = null) {
    const apiKey = apiKeys.u2.autolog;
    const url = apiKeys.u2[apiKey];
    const type = api[url].type;

    const utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: apiKey,
      API_TYPE: type,
      API_BODY: {
        Autologs: [{
          AssignmentID: assignmentID, 
          DescID: AutologID.LocateExamined, 
          Explaination: explanation ?? "Examined Ticket"
        }]
      },
    };

    try {
      const result = await this.invokeUtilocateApi(utilocateApiRequest);
      if (!(result instanceof HttpErrorResponse) && result.body && result.body.value) {
        return result.body.value;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async getAssignedTickets(userID: string) {
    const apiKey = apiKeys.u2.assignedTickets;
    const url = apiKeys.u2[apiKey];
    const type = api[url].type;

    const utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: apiKey,
      API_TYPE: type,
      API_URL_DATA_PARAMS: {
        UserID: userID.toString(),
      },
      API_BODY: {
        UserID: userID,
      },
    };

    try {
      const result = await this.invokeUtilocateApi(utilocateApiRequest);
      if (!(result instanceof HttpErrorResponse) && result.body && result.body.value) {
        return result.body.value;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async getDigsiteByAssignmentID(assignmentID: string) {
    const url = apiKeys.u2.gatherDigsites;
    const type = api[url].type;

    try {
      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKeys.u2.gatherDigsites,
        API_TYPE: type,
        API_BODY: { AssignmentIDs: [assignmentID] },
      };

      try {
        const result = await this.invokeUtilocateApi(
          // added to cache, can end here
          utilocateApiRequest
        );
        if (!(result instanceof HttpErrorResponse) && result.body && result.body.value) {
          return JSON.parse(result.body.value);
        } else {
          return false;
        }
      } catch (error) {
        console.error(error);
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  /**
   * Downloads a ticket by its primaryID, writes it to the idb under the assignmentID key
   * @param assignmentID
   * @param primaryID
   * @returns
   */
  async downloadTicketByPrimaryID(assignmentID: string, primaryID: string) {
    if (assignmentID == null || primaryID == null)
      throw new Error('Warning (getTicketByPrimaryID): No AssignmentID or PrimaryID provided');

    try {
      const apiKey = apiKeys.u2.UtilocateDownloadTicketPrimary;
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_URL_DATA_PARAMS: {
          AssignmentID: assignmentID.toString(),
          PrimaryID: primaryID.toString(),
        },
      };
      try {
        const result = await this.invokeUtilocateApi(
          // added to cache, can end here
          utilocateApiRequest,
          assignmentID
        );
        if (!(result instanceof HttpErrorResponse) && result.body && result.body.value) {
          const ticket = JSON.parse(result.body.value);
          return ticket;
        } else {
          return false;
        }
      } catch (error) {
        console.error(error);
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Downloads a single ticket and places it into the cache service
   * @param AssignmentID
   * @param PrimaryID
   * @returns
   */
  async downloadTicketByAssignmentID(AssignmentID: string, assignedTicket: boolean = false) {
    if (!AssignmentID) throw new Error('Warning (downloadTicket): No AssignmentID provided');
    try {
      const apiKey = apiKeys.u2.UtilocateDownloadTicketAll;
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_URL_DATA_PARAMS: {
          AssignmentID: AssignmentID.toString(),
        },
      };

      try {
        const result = await this.invokeUtilocateApi(
          // added to cache, can end here
          utilocateApiRequest,
          AssignmentID,
          assignedTicket
        );
        if (!(result instanceof HttpErrorResponse) && result.body && result.body.value) {
          const ticket = JSON.parse(result.body.value);
          return ticket;
        } else {
          return false;
        }
      } catch (error) {
        console.error(error);
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async uploadTickets(ticket: object): Promise<any> {
    try {
      const request: UtilocateApiRequest = {
        API_TYPE: 'PUT',
        API_KEY: apiKeys.u2.uploadTicket,
        API_BODY: ticket,
      };

      const result: HttpResponse<any> = await this.invokeUtilocateApi(request);
      return result;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async callAppCheckIn(body: any) {
    try {
      const request: UtilocateApiRequest = {
        API_TYPE: 'PUT',
        API_KEY: apiKeys.u2.appCheckIn,
        API_BODY: {
          UserGPS: body,
        },
      };

      const result: HttpResponse<any> = await this.invokeUtilocateApi(request);
      if (result && result.ok) {
        return result;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}
