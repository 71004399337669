import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { SearchableDropdownComponent } from '../../../inputs/searchable-dropdown/searchable-dropdown.component';
import { VerticalCollapsibleComponent } from '../../../containers/vertical-collapsible/vertical-collapsible.component';
import { CompetersSlideToggleComponent } from '../../../inputs/competers-slide-toggle/competers-slide-toggle.component';
import { SnackbarService } from 'src/app/modules/shared/snackbar/snackbar.service';
import { UsersService } from 'src/app/shared/services/users/users.service';
import { RouteUserEditorService } from './route-user-editor.service';
import { MatIcon } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompetersCheckboxComponent } from '../../../inputs/competers-checkbox/competers-checkbox.component';
import { CompetersDeprecatedInputComponent } from '../../../inputs/competers-deprecated-input/competers-deprecated-input.component';
import { MapArrayToSelectionOptionPipe } from '../../../inputs/searchable-dropdown/ToSelectionOption.pipe';
import { SnackbarType } from 'src/app/modules/shared/snackbar/snackbar/snackbar';
import { MatDialog } from '@angular/material/dialog';
import { AutologExplanationModalComponent } from '../../../../../modules/shared/ticket/modals/autolog-explaination-modal/autolog-explanation-modal.component';

interface DropdownOption {
  name: string;
  value: string;
}

@Component({
  selector: 'app-route-user-editor',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './route-user-editor.component.html',
  styleUrls: ['./route-user-editor.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIcon,
    CompetersCheckboxComponent,
    SearchableDropdownComponent,
    CompetersDeprecatedInputComponent,
    VerticalCollapsibleComponent,
    FormsModule,
    MapArrayToSelectionOptionPipe,
    CompetersSlideToggleComponent,
  ],
})
export class RouteUserEditorComponent implements OnInit, OnDestroy {
  // services
  private cdr = inject(ChangeDetectorRef);
  private userService = inject(UsersService);
  private snackBarService = inject(SnackbarService);
  protected myService = inject(RouteUserEditorService);

  // signals and observables
  private _destroy$ = new Subject<void>();

  constructor(private dialog$: MatDialog) {}

  // members
  userOptions = null;
  selectedUser = null;
  isRefreshing = false;
  isUpdating = false;

  //setup
  ngOnInit() {
    // Initialize user options
    this.userOptions = this.userService
      .users$$()
      .filter((x) => x['UserID'] !== 0)
      .map((x) => ({
        name: `${x['LastName']}, ${x['FirstName']} - ${
          this.userService.userCategories.find(
            (category) => category.UserCategoryID === x['UserCategoryID']
          ).Title
        }`,
        value: x['UserID'],
      }));

    // trigger change detection
    this.cdr.detectChanges();
    this.myService.clearRouteOrder();
    this.myService.showRouting$.next(true);
  }

  /**
   * Triggers when the user selects a new user from the dropdown
   *
   * @param {DropdownOption[]} $event
   * @memberof RouteUserEditorComponent
   */
  onUserChange($event: DropdownOption[]) {
    this.myService.setSelectedUser($event[0]);
    if (!$event[0]) {
      //we no longer have a user selected, so remove pin marker visibility
      this.myService.showRouting$.next(false);
    }
    this.cdr.detectChanges();
  }

  /**
   * Sets the pin marker visibility from the slide toggle
   *
   * @param {*} $event
   * @memberof RouteUserEditorComponent
   */
  setPinMarkerVisibility($event: any) {
    this.myService.showRouting$.next($event);
  }

  /**
   * Refreshes the tickets for the currently selected user
   *
   * @memberof RouteUserEditorComponent
   */
  refreshTickets() {
    //start refreshing
    if (!this.myService.lastUser) {
      this.snackBarService.openSnackbar(
        'No user selected',
        SnackbarType.warning
      );
      return;
    }

    this.isRefreshing = true;

    //call refresh
    this.myService.refreshLastSelectedUserTickets().then(() => {
      if (this.isRefreshing) {
        this.isRefreshing = false;
        this.cdr.detectChanges();
      }
    });

    // if no response, tell the user we timed out
    setTimeout(() => {
      if (this.isRefreshing) {
        this.snackBarService.openSnackbar(
          'The request timed out',
          SnackbarType.warning
        );
      }
      this.isRefreshing = false;
      this.cdr.detectChanges();
    }, 10000);
  }

  /**
   * Updates the route for the currently selected user
   *
   * @memberof RouteUserEditorComponent
   */
  updateRoutes() {
    if (!this.myService.lastUser) {
      this.snackBarService.openSnackbar(
        'No user selected',
        SnackbarType.warning
      );
      return;
    }

    this.isUpdating = true;

    //confirmation dialog component
    const dialogRef = this.dialog$.open(AutologExplanationModalComponent, {
      width: '427px',
      data: {
        header: 'Reason for re-route',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.calUpdateRoute(result.result);
      } else {
        this.isUpdating = false;
        this.cdr.detectChanges();
      }
    });
  }

  calUpdateRoute(explanationMessage: string) {
    this.myService
      .refreshRouteOrder(explanationMessage)
      //if success, then tell the user we were successful
      .then(() => {
        if (this.isUpdating) {
          this.isUpdating = false;
          this.cdr.detectChanges();
        }
      });

    // if no response, tell the user we timed out
    setTimeout(() => {
      if (this.isUpdating) {
        this.snackBarService.openSnackbar(
          'The request timed out',
          SnackbarType.warning
        );
      }
      this.isUpdating = false;
      this.cdr.detectChanges();
    }, 10000);
  }

  ngOnDestroy() {
    this.myService.showRouting$.next(false);
    this._destroy$.next();
    this._destroy$.complete();
  }
}
