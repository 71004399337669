import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-drawing-overlay',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule
  ],
  template: `
    <div class="box-border flex flex-col justify-between w-full h-full p-4">
      <!--      top      -->
      <div class="flex flex-row w-full items-start justify-between">
        <!--        left       -->
        <div class="flex flex-row justify-start items-start max-w-fit w-1/3">
          <ng-content select="[top-left]"></ng-content>
        </div>
        <!--        center       -->
        <div class="flex flex-row justify-center items-start w-1/3">
          <ng-content select="[top-center]"></ng-content>
        </div>
        <!--        right      -->
        <div class="flex flex-row justify-end items-start w-1/3">
          <ng-content select="[top-right]"></ng-content>
        </div>
      </div>
      <!--      bottom      -->
      <div class="flex flex-row justify-between items-center h-[60px]">
        <!--        left       -->
        <div class="w-1/3">
          <ng-content select="[bottom-left]" ></ng-content>
        </div>
        <!--        center     -->
        <div class="w-1/3 relative z-10 grow flex justify-center items-center">
          <ng-content select="[bottom-center]" ></ng-content>
        </div>
        <!--      right     -->
        <div class="flex flex-row justify-end items-center w-1/3">
          <ng-content select="[bottom-right]"></ng-content>
        </div>
      </div>
    </div>
  `,
  styleUrl: './drawing-overlay.component.scss',
})
export class DrawingOverlayComponent {

}
