import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  effect,
  signal,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { SearchableDropdownComponent } from '../../inputs/searchable-dropdown/searchable-dropdown.component';
import { MatRadioModule } from '@angular/material/radio';
import { CompetersDeprecatedInputComponent } from '../competers-deprecated-input/competers-deprecated-input.component';
import { Subject } from 'rxjs';
import { isEqual } from 'lodash-es';

@Component({
  selector: 'app-competers-radio-select',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatRadioModule,
    SearchableDropdownComponent,
    CompetersDeprecatedInputComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CompetersRadioSelectComponent,
    },
  ],
  template: `
    <div>
      <mat-radio-group [value]="chosenLabel$$()">
        <div
          class="radio-options"
          *ngFor="let option of config.dataSource; let i = index">
          <mat-radio-button
            [value]="option.value"
            (change)="onRadioChange($event)">
            <div class="flex flex-col">
              <div
                class="hover:cursor-pointer text-lg font-rajdhani font-semibold uppercase">
                {{ option.label }}
              </div>
              <div
                *ngIf="option.subLabel && option.subLabel.length > 0"
                class="hover:cursor-pointer text-sm font-montserrat font-semibold uppercase">
                {{ option.subLabel }}
              </div>
            </div>
          </mat-radio-button>

          <ng-container>
            <div
              class="relative w-[195px] h-9 pl-11"
              *ngIf="['text', 'number'].includes(option.type)">
              <app-competers-depricated-input
                [fitParentContainer]="true"
                [formControl]="formControl"
                [type]="option.type"
                (ngModelChange)="
                  onSubOptionChange($event, option)
                "></app-competers-depricated-input>
            </div>
          </ng-container>
        </div>
      </mat-radio-group>
    </div>
  `,
  styles: [
    `
      .radio-options {
        margin-bottom: 15px;
      }
    `,
  ],
})
export class CompetersRadioSelectComponent
  implements OnDestroy, ControlValueAccessor, OnInit
{
  @Input() config: CompetersRadioSelectConfiguration;
  @Input() formControl: FormControl;

  private destroy$ = new Subject<void>();
  protected chosenLabel$$ = signal<string>(''); // Store the selected radio option name
  protected chosenValue$$ = signal<any>(null); // Store the sub-option value
  protected val: any = null; // Store the overall value to be passed back

  constructor(private fb: FormBuilder) {
    // Reactively update the onChange handler when either chosenLabel$$ or chosenValue$$ changes

    effect(() => {
      if (this.onChange !== undefined) {
        this.onChange(this.chosenValue$$());
      }
    });

    this.writeValue(null);
  }

  ngOnInit() {
    this.chosenLabel$$.set(this.config.placeholder || null);
    this.chosenValue$$.set(null);
  }

  writeValue(data: any) {
    // Accept an object with label and value
    if (!isEqual(this.val, data)) {
      this.chosenLabel$$.set(data?.label ?? null);
      this.chosenValue$$.set(data?.value ?? null);
    }
  }

  onChange: (data: any) => void = () => {}; // Accept an object with label and value

  onTouched: () => void = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onRadioChange(event: unknown) {
    if (typeof event === 'object' && 'value' in event) {
      this.chosenValue$$.set(event.value);
    }
  }

  onSubOptionChange(event: string, option: CompetersRadioSelectDataSourceItem) {
    if (this.chosenLabel$$() !== option.name) {
      this.chosenLabel$$.set(option.name);
    }
    option.value = event;
    this.chosenValue$$.set(event); // Update chosenValue when sub-option changes
  }
}

export type CompetersRadioSelectConfiguration = {
  dataSource: CompetersRadioSelectDataSourceItem[];
  placeholder?: string;
};

export type CompetersRadioSelectDataSourceItem = {
  label: string;
  subLabel?: string;
  value?: string;
  name: string;
  type?: string;
  subLabelFormat: string;
};
