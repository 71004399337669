import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { UtilocateTokenService } from "../services/token/token.service";
import { AuthenticationService } from "../authentication/authentication.service";
import { localStorageKeys } from "src/app/LOCAL_STORAGE";
import { UserService } from "../services/user/user.service";
import { SettingID } from "../services/user/setting";

@Injectable({
  providedIn: "root",
})
export class CanActivateAuthGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private userService: UserService,
    private tokenService: UtilocateTokenService
  ) { }

  /**
   * checks multiple conditions for whether this route can be activated
   * @param route current activated route
   * @param state current state of router
   * @returns true if canActivate
   */
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    // | Observable<boolean | UrlTree>

    const path = route.routeConfig.path;
    const authQueryParam = route.queryParams.Authorization;

    let canActivate = false;

    try {
      // console.log("path:", path);
      // console.log("authQueryParam:", authQueryParam);

      if (path == "document-viewer") {
        if (authQueryParam) {
          sessionStorage.removeItem(localStorageKeys.DOC_VIEWER_TOKEN_KEY);
          this.tokenService.setToken(
            authQueryParam,
            localStorageKeys.DOC_VIEWER_TOKEN_KEY
          );
          canActivate = true;
        }
      } else {
        if (
          authQueryParam &&
          this.authenticationService.isAuthenticated(authQueryParam)
        ) {
          canActivate = await this.checkWithAuth(
            path,
            authQueryParam,
            route.routeConfig.data
          );
        } else if (this.authenticationService.isAuthenticated()) {
          canActivate = await this.checkWithAuth(
            path,
            this.tokenService.getToken(),
            route.routeConfig.data
          );
        } else {
          if (path != "login/") {
            this.router.navigate(["login"], {
              queryParams: {
                ...route.queryParams,
                returnUrl: path,
              },
            });
          }
        }
      }
    } catch (ex) {
      console.log(ex);
    }

    if (canActivate) {
      return true;
    } else {
      if (path == "document-viewer") {
        return this.router.parseUrl("security-troubleshoot");
      } else {
        if (this.authenticationService.isAuthenticated()) {
          if (this.userService.isSettingActive(SettingID.HOME_WORKSPACE)) {
            return this.router.parseUrl('app/home');
          } else if (this.userService.isSettingActive(SettingID.TICKET_SUMMARY)) {
            return this.router.parseUrl('app/ticket-summary');
          }
          return this.router.parseUrl('app/settings');
        } else {
          return this.router.parseUrl("no-auth");
        }
      }
    }
  }

  /**
   * cleans session storage, refreshes logged in user, checks settings for access
   * @param path path being navigated to
   * @param token authentication token for refreshing logged in user
   * @returns true if user's settings allow access to path
   */
  private async checkWithAuth(
    path: string,
    token: string,
    data: any
  ): Promise<boolean> {
    let canActivate: boolean;

    await this.cleanSessionInTransition(path);
    const loginResult = await this.userService.loginWithToken(token);
    if (loginResult) {
      canActivate = await this.canSettingsActivate(path, data);
    }

    return canActivate;
  }

  /**
   * checks path specific setting for isActive
   * @param path path of route being navigated to
   * @returns true if setting is active
   */
  private async canSettingsActivate(path: string, data: any) {
    let canActivate: any = false;
    try {
      if (path) {
        if (data && data.SettingID) {
          if (this.userService.user.settings) {
            let settingID: SettingID = data.SettingID;
            if (this.userService.isSettingActive(settingID)) {
              if (data.SubSettingID) {
                const settingValue =
                  this.userService.getSettingValue(settingID);
                if (settingValue) {
                  const subSettingIDs = settingValue.split(",");
                  if (subSettingIDs.includes(data.SubSettingID)) {
                    canActivate = true;
                  }
                } else {
                  canActivate = false;
                }
              } else {
                canActivate = true;
              }
            }
          }
        } else {
          canActivate = true;
        }
      } else {
        canActivate = true;
      }
    } catch (error) {
      console.error("canSettingsActivate: failed");
    }
    return canActivate;
  }

  /**
   * updates sessionStorage
   * @param curPath the current path from the route
   * @returns true unless an error is thrown - then false
   */
  private async cleanSessionInTransition(curPath: string) {
    try {
      const prevPath = sessionStorage.getItem(localStorageKeys.URL_KEYS.page);
      if (curPath && curPath.indexOf(prevPath) === -1) {
        sessionStorage.clear();
      }
      sessionStorage.setItem(localStorageKeys.URL_KEYS.page, curPath);
      sessionStorage.setItem(
        localStorageKeys.CACHE_DATE_ADMIN_LOOKUP_KEY,
        "false"
      );
      if (
        !prevPath ||
        !curPath
      ) {
        this.authenticationService.clearAdminCache();
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  /**
   *
   * @param queryParams
   * @param visisbleURLKeys
   * @returns
   */
  cleanURLQueryParams(queryParams, data): object {
    let queryParamsKeys: any = Object.keys(queryParams);
    let newQueryParams = {};

    try {
      // let visisbleURLKeys: any = this.route.snapshot.routeConfig.data.VISIBLE_URL_KEYS;
      if (data && data.VISIBLE_URL_KEYS) {
        let visisbleURLKeys = data.VISIBLE_URL_KEYS;
        let len = queryParamsKeys.length;
        for (let i = 0; i < len; i++) {
          let queryParamsKey = queryParamsKeys[i];
          if (
            visisbleURLKeys &&
            visisbleURLKeys.indexOf(queryParamsKey.toLowerCase()) > -1
          ) {
            newQueryParams[queryParamsKey] = queryParams[queryParamsKey];
          } else {
            newQueryParams[queryParamsKey] = null;
          }
        }
      }
    } catch (error) {
      console.log("cleanURLQueryParams: Failed");
    }
    return newQueryParams;
  }
}
