import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Inject,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DialogPosition, MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject, take } from 'rxjs';
import { NavBarConfig } from 'src/app/routes/fieldside/components/fieldside-navigation-bar/navigation-bar.component';
import { NavBarService } from '../../services/router/nav-bar.service';
import { TicketSyncService } from '../../services/ticket-sync/ticket-sync.service';
import { TicketProtectionID, TicketProtectionResult, TicketService } from '../../ticket.service';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { SettingID } from 'src/app/modules/core/services/user/setting';
import { AssignmentIDCacheResult } from 'src/app/modules/core/cache/cache.interface';
import { ConfirmationDialogComponent } from '../../../confirmation-dialog/confirmation-dialog.component';
import { NavigationStart, Router } from '@angular/router';
import { OverlayService } from '../../../../../shared/services/overlay/overlay.service';
import { UtilocateApiService } from 'src/app/modules/core/api/utilocateapi.service';

export enum TicketModalComponentOpenFromEnum {
  Summary = 1,
  Home = 2,
  Other = 3,
}

export interface TicketModalComponentArgs {
  AssignmentID: string | number;
  PrimaryID?: string | number;
  OpenedFrom: TicketModalComponentOpenFromEnum;
}

@Component({
  selector: 'app-ticket-modal',
  templateUrl: './ticket-modal.component.html',
  styleUrls: ['./ticket-modal.component.scss'],
})
export class TicketModalComponent implements OnInit, OnChanges, OnDestroy {
  // IO
  @ViewChild('container', { static: true }) container: ElementRef;

  // services
  private overlayService = inject(OverlayService);

  // members
  data: TicketModalComponentArgs;
  showSyncBadge: boolean = false;
  showFilter: boolean = true;
  showRouting: boolean = false;
  isFullScreen: boolean = false;
  ticketDataDownloaded: boolean = false;
  temporaryTicket: boolean = false;
  ticketHasChanged: boolean = false;
  errorTicket: boolean = false;
  hasProtectionForMe: boolean = false;
  allowCreateAudit: boolean;
  appToolbarTitle: string;
  originalWidth: string;
  originalHeight: string;
  width: string;
  height: string;
  loadingDialogPosition: DialogPosition;
  ticketChanged: EventEmitter<any> = new EventEmitter();
  private resizeObserver: ResizeObserver;

  // observables
  onSyncClick: Subject<any> = new Subject<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public Data: TicketModalComponentArgs,
    public dialogRef: MatDialogRef<TicketModalComponent>,
    private navBarChanges: NavBarService,
    private ticketSync: TicketSyncService,
    private ticketService: TicketService,
    private userService: UserService,
    public dialog: MatDialog,
    private router: Router,
    private apiService: UtilocateApiService
  ) {
    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationStart) {
        // Close the dialog when the URL changes
        await this.close();
      }
    });
    this.data = this.Data;
    if (
      this.data.OpenedFrom === TicketModalComponentOpenFromEnum.Other ||
      this.data.OpenedFrom === TicketModalComponentOpenFromEnum.Home
    ) {
      this.loadingDialogPosition = { right: '35px' };
      this.downloadAndOpenTicket();
    } else {
      this.ticketExists(this.data.AssignmentID).then((ticket) => {
        if (ticket) {
          this.ticketHasChanged = ticket.ticketChanged;
          this.ticketChanged.emit(this.ticketHasChanged);
          this.ticketDataDownloaded = true;
        } else {
          this.errorTicket = true;
        }
      });
    }

    this.navBarChanges
      .getNavBarChanges()
      .pipe(take(1))
      .subscribe((nextVal: NavBarConfig) => {
        this.showFilter = nextVal.showFilter;
        this.showRouting = nextVal.showRouting;
        this.onSyncClick = nextVal.onSyncClick;
        this.showSyncBadge = nextVal.showSyncBadge;
        this.appToolbarTitle = nextVal.Title;
      });

    this.allowCreateAudit = this.userService.isSettingActive(SettingID.ALLOW_CREATE_AUDIT_TICKETS);
  }

  ngOnInit() {
    this.resizeObserver = new ResizeObserver(() => {
      this.overlayService.triggerResize();
    });
    this.apiService.createLocateExaminedAutolog(this.data.AssignmentID); //Create autolog that the locate was examined
    this.resizeObserver.observe(this.container.nativeElement);
  }

  async ngOnChanges(): Promise<void> {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    const ticket = await this.ticketExists(this.data.AssignmentID);
    if (ticket.ticketChanged) {
      this.ticketHasChanged = true; //TODO: Add badge icon if ticket has changed
      this.ticketChanged.emit(true);
    }
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect();
  }

  async downloadAndOpenTicket() {
    const ticket = await this.ticketExists(this.data.AssignmentID);

    const currentTime = Date.now();
    const fiftyMinutesAgo = currentTime - 50 * 60 * 1000; // 50 minutes in milliseconds

    //if the ticket exists locally and it is less than 50 minutes old
    if (ticket != null && ticket.insertTime && ticket.insertTime.getTime() > fiftyMinutesAgo) {
      //if we were given a primaryID, check to make sure the primaryID we have locally is the one we want
      if (this.data.PrimaryID) {
        const primaryTable = ticket.getTableData('tbCompletions_Primary');
        if (primaryTable) {
          const primaryIDFromDownloadedTicket = primaryTable[0].PrimaryID;
          if (primaryIDFromDownloadedTicket !== this.data.PrimaryID) {
            //remove the old ticket from the idb then download it
            this.ticketService.removeAssignment(this.data.AssignmentID.toString());
            this.downloadTicket(true);
            return; //leave the function
          }
        }
      }

      // IF the ticket is not assigned to this user, delete it locally and re-download it,
      if (!ticket.assigned) {
        this.ticketService.removeAssignment(this.data.AssignmentID.toString()); //remove ticket
        this.downloadTicket(true);
      } else {
        // ELSE (ticket is assigned to this user) show the ticket that is downloaded
        this.ticketSync.getAdminTables().then(() => {
          // nothing
        });
        this.ticketDataDownloaded = true;
      }
    } else {
      //ticket doesn't exist locally or is old, so we need to download it
      this.callTicketProtection(TicketProtectionID.CHECK_TICKET_PROTECTION).then(
        (ticketProtection: TicketProtectionResult) => {
          //no ticket protection OR it has been more than 50 minutes
          if (!ticketProtection) {
            this.errorTicket = true;
          } else if (ticketProtection.result === false || Number(ticketProtection.date) > Date.now() - 300000) {
            this.hasProtectionForMe = true; //set variable to know that we have ticket protectino for this user
            this.downloadTicket(true);
          } else {
            this.decideOverrideTicketProtection('Ticket Protection', ticketProtection);
          }
        }
      );
    }
  }

  async decideTicketProtectionAndOpenTicket(overrideProtection: boolean, ticketProtection) {
    if (overrideProtection) {
      await this.callTicketProtection(TicketProtectionID.REMOVE_TICKET_PROTECTION, ticketProtection.userID); //remove the old ticket protection
      this.hasProtectionForMe = true; //set variable to know that we have ticket protectino for this user
      this.downloadTicket(true);
    } else {
      this.hasProtectionForMe = false;
      this.downloadTicket(true, false);
      //TODO: Open in view only mode? Tell user they can't make changes
    }
  }

  decideOverrideTicketProtection(title: string, ticketProtection): void {
    const warningString = `Warning: ${ticketProtection.userFirstName} ${ticketProtection.userLastName} has this ticket open.
    Would you like to override the ticket protection?`;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: {
        title: title,
        message: warningString,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.decideTicketProtectionAndOpenTicket(result, ticketProtection);
    });
  }

  /**
   * Sets temporary ticket based on input, applies ticket protection, and downloads ticket
   * @param temporaryTicket
   */
  async downloadTicket(temporaryTicket: boolean = false, applyTicketProtection: boolean = true) {
    this.temporaryTicket = temporaryTicket;
    // console.log(`temporaryTicket: ${this.temporaryTicket}`);
    if (applyTicketProtection) await this.callTicketProtection(TicketProtectionID.ADD_TICKET_PROTECTION);

    if (this.loadingDialogPosition) this.ticketSync.setLoadingModalPosition(this.loadingDialogPosition);

    this.ticketSync
      .startSync(false, true, [this.data.AssignmentID.toString()], false, [this.data.PrimaryID.toString()])
      .subscribe({
        //download ticket
        complete: async () => {
          this.ticketDataDownloaded = true;
          this.ticketSync.closeModal();
        },
      });
  }

  toggleFullScreen() {
    if (!this.isFullScreen) {
      // Store original size before entering full screen
      this.originalWidth = this.width;
      this.originalHeight = this.height;
      // Enter full-screen mode
      // Adjust modal size and position as needed
    } else {
      // Exit full-screen mode
      // Restore modal size and position
      this.width = this.originalWidth;
      this.height = this.originalHeight;
    }
    this.isFullScreen = !this.isFullScreen;
    this.overlayService.triggerResize();
  }

  async callTicketProtection(action: number, user?: string): Promise<TicketProtectionResult> {
    try {
      const userForProtection = user ?? this.userService.getUserID();
      //if userForProtection is a string
      if (userForProtection) {
        return await this.ticketService.callTicketProtection(
          this.data.AssignmentID.toString(),
          action,
          userForProtection
        );
      }
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  createTicket() {
    this.ticketService.createAuditTicketModal(false);
  }

  /**
   * Checks if the ticket exists locally
   * @param assignmentID
   * @returns
   */
  async ticketExists(assignmentID: string | number): Promise<AssignmentIDCacheResult> {
    let ticketResult = null;
    try {
      ticketResult = await this.ticketService.getTicketCacheResult(assignmentID.toString());
    } catch (error) {
      console.log(error);
    }
    return ticketResult;
  }

  markTicketChanged() {
    this.ticketHasChanged = true;
    this.ticketChanged.emit(true);
  }

  /**
   * What happens when a user closes this modal
   */
  async onClose() {
    if (this.temporaryTicket && this.ticketHasChanged) {
      this.onSync(true);
    } else {
      this.close();
    }
    await this.ticketService.removeTicketDocumentsLocally(this.data.AssignmentID.toString(), false);
  }

  /**
   * Syncs this ticket, or tickets, depending on where the user came from
   */
  onSync(close: boolean = false) {
    if (this.loadingDialogPosition) this.ticketSync.setLoadingModalPosition(this.loadingDialogPosition);
    this.ticketSync
      .startSync(true, true, [this.data.AssignmentID.toString()], false, [this.data.PrimaryID.toString()])
      .subscribe({
        complete: () => {
          this.ticketSync.closeModal();
          this.ticketHasChanged = false;
          this.ticketChanged.emit(false);
          if (close) this.close();
        },
      });
  }

  /**
   * Handles the removal of ticket assignment and synchronization.
   * If a temporary ticket exists, removes its assignment and calls ticket protection removal.
   * Resets the loading modal position to the center of the screen if applicable.
   * @async
   * @returns {Promise<void>} A Promise that resolves when all tasks are completed.
   */
  async handleTicketRemovalAndSync() {
    if (this.temporaryTicket) {
      try {
        await this.ticketService.removeAssignment(this.data.AssignmentID.toString());
        if (this.hasProtectionForMe) await this.callTicketProtection(TicketProtectionID.REMOVE_TICKET_PROTECTION);
      } catch (error) {
        console.warn(error);
      }
    }
    if (this.loadingDialogPosition) this.ticketSync.setLoadingModalPosition(null); //reset the sync modal position to the center of the screen
  }

  /**
   * Closes this dialog component. Clears temporary storage if needed
   */
  async close() {
    await this.handleTicketRemovalAndSync();
    this.dialogRef.close({ event: 'close', result: true });
  }
}
