import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UserPassLoginService } from '../login.service';
import { PassRegisterResult, RegisterResult } from '../../../core/api/utilocateapi.service';
import { Registration, UtilocateTokenService } from '../../../core/services/token/token.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UtilocateAdminCacheService } from '../../../core/cache/utilocate-admin.service';
import { SnackbarService } from '../../snackbar/snackbar.service';
import { ProgressBarService } from '../../progress-bar/progress-bar.service';
import { UserService } from '../../../core/services/user/user.service';
import { AuthenticationService } from 'src/app/modules/core/authentication/authentication.service';
import { localStorageKeys } from 'src/app/LOCAL_STORAGE';
import { LoggerService } from '../../ticket/services/logger/logger.service';
import { UpdateService } from 'src/app/modules/core/services/update.service';
import { SnackbarType } from '../../snackbar/snackbar/snackbar';
import { SettingID } from 'src/app/modules/core/services/user/setting';

export interface User {
  UserDetails: string;
  UserID: number;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  registerForm: FormGroup;
  loginForm: FormGroup;

  isRegistered: boolean = false;
  ClientID: string;
  ClientDBName: string;
  ClientName: string;
  isOld: string;
  emailClicked: boolean = false;
  regClicked: boolean = false;
  logedIn: boolean = false;
  loading = false;
  submitted = false;
  returnUrl: string;
  car: boolean = false;
  Users: [];
  selectedUserID: string;
  showPassword: boolean = false;
  passwordInput: FormControl = new FormControl('');
  isPasswordEmpty: boolean = true;
  passwordInputReg: FormControl = new FormControl('');
  isPasswordEmptyReg: boolean = true;

  enterPassword: boolean = false;
  checkingEmail: boolean = false;
  // failedLogin: boolean = false;
  // returnUrl: string;

  myControl = new FormControl();
  options: User[] = [];
  filteredOptions: Observable<User[]>;

  constructor(
    private formBuilder: FormBuilder,
    private loggerService: LoggerService,
    private route: ActivatedRoute,
    private userPassLoginService: UserPassLoginService,
    private dialog: MatDialog,
    private utilocateAdminCacheService: UtilocateAdminCacheService,
    private userService: UserService,
    private router: Router,
    private snackBarService: SnackbarService,
    private progressBarService: ProgressBarService,
    private authenticationService: AuthenticationService,
    private updateService: UpdateService,
    private tokenService: UtilocateTokenService
  ) {
    this.userPassLoginService.clearCache();
    // this.utilocateAdminCacheService.clear();
  }

  async ngOnInit(): Promise<void> {
    this.loginForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });

    this.registerForm = this.formBuilder.group({
      companyCode: ['', Validators.required],
    });
    this.isRegistered = this.userPassLoginService.isRegistered();

    this.userLogedIn();
    if (this.isRegistered) {
      sessionStorage.setItem(localStorageKeys.URL_KEYS.page, 'fieldside');
      let registration: Registration = this.userPassLoginService.getRegistration();
      this.ClientID = registration.ClientID;
      this.isOld = registration.isOld;
      this.ClientDBName = registration.ClientDBName;
      this.ClientName = registration.ClientName;
      this.Users = await this.userPassLoginService.getListOfUsers(registration.ClientID, registration.isOld);
      this.setupUserAutocomplete();
    }
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.loading = false;
    this.progressBarService.stop(); // loading
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
    this.userService.firstLogin(false);
  }

  private setupUserAutocomplete() {
    try {
      this.options = this.Users;
      this.filteredOptions = this.loginForm.controls['username'].valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.UserDetails)),
        map((UserDetails) => (UserDetails ? this._filter(UserDetails) : this.options.slice()))
      );
    } catch (error) {
      this.loggerService.error(error);
    }
  }

  displayFn(user: User): string {
    return user && user.UserDetails ? user.UserDetails : '';
  }

  resetUserField() {
    this.loginForm.controls['username'].setValue({});
  }

  async userLogedIn() {
    if (this.authenticationService.isAuthenticated()) {
      if (this.isRegistered) {
        this.router.navigate(['fieldside/ticket-summary'], {
          queryParams: {
            firstSync: true,
          },
        });
      } else {
        if (this.userService.isSettingActive(SettingID.HOME_WORKSPACE)) {
          this.router.navigate(['app/home']);
        } else if (this.userService.isSettingActive(SettingID.TICKET_SUMMARY)) {
          this.router.navigate(['app/ticket-summary']);
        } else {
          this.router.navigate(['app/settings']);
        }
      }
    }
  }

  async resetApp() {
    this.progressBarService.start();
    try {
      if (confirm(`Are you sure you want to force and update?`)) {
        sessionStorage.clear();
        localStorage.clear();
        await this.clearBrowserCachesUpdate();
        this.userService.clearCompletionsAdminCacheService();
        this.userService.clearIDBCache();
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
          for (let registration of registrations) {
            registration.unregister();
          }
        });
        await this.updateService.activateUpdate();
      }
    } catch (error) {
      console.error(error);
    }
    this.progressBarService.stop();
    window.location.reload();
  }

  clearBrowserCachesUpdate() {
    caches.keys().then(function (cacheNames) {
      return Promise.all(
        cacheNames
          .filter(function (cacheName) {
            console.log(`Clearing ${cacheName}`);
            return true;
          })
          .map(function (cacheName) {
            return caches.delete(cacheName);
          })
      );
    });
    this.snackBarService.openSnackbar('Caches cleared', SnackbarType.success);
  }

  login() {
    this.router.navigate(['fieldside/ticket-summary']);
  }

  loginClick(btnText) {
    if (btnText === 'email') {
      this.emailClicked = true;
    } else {
      this.regClicked = true;
    }
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  backBtn() {
    this.emailClicked = false;
    this.regClicked = false;
    this.isRegistered = false;
    this.tokenService.destroyRegistration(); //clear fieldside db name
  }

  openPrivacyPolicy() {
    try {
      const dialogRef = this.dialog.open(PrivacyPolicyDialogComponent);

      dialogRef.afterClosed().subscribe();
    } catch (error) {
      this.loggerService.error(error);
    }
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();
    return this.options.filter((option) => option.UserDetails.toLowerCase().includes(filterValue));
  }

  get logins() {
    return this.loginForm.controls;
  }

  async onRegister() {
    this.loading = true;
    this.progressBarService.start(); // loading
    try {
      let companyCode = this.registerForm.get('companyCode').value;
      let registerResult: RegisterResult | boolean =
        await this.userPassLoginService.verifyClientRegistrationCode(companyCode);

      if (registerResult instanceof PassRegisterResult) {
        this.ClientID = registerResult.CompanyInfo['ClientID'];
        this.ClientDBName = registerResult.CompanyInfo['ClientDBName'];
        this.isOld = registerResult.CompanyInfo['isOld'];
        this.ClientName = registerResult.CompanyInfo['ClientName'];

        this.snackBarService.openSnackbar('Registration successful.', null);

        try {
          this.Users = await this.userPassLoginService.getListOfUsers(this.ClientID, this.isOld);
          this.setupUserAutocomplete();
          this.isRegistered = true;
        } catch (error) {
          this.loggerService.error(error);
        }
      } else {
        this.snackBarService.openSnackbar('Registration failed.');
      }
    } catch (error) {
      this.loggerService.error(error);
    }
    this.loading = false;
    this.progressBarService.stop(); // done
  }

  async onSubmits() {
    this.submitted = true;
    this.loading = true;
    this.progressBarService.start(); // loading
    try {
      if (!this.loginForm.controls['username'].value.UserID) {
        for (let i in this.options) {
          if (this.options[i].UserDetails == this.loginForm.controls['username'].value.toString()) {
            this.selectedUserID = this.options[i].UserID.toString();
            break;
          }
        }
      } else {
        this.selectedUserID = this.loginForm.controls['username'].value['UserID'];
      }

      // this.selectedUserID = this.loginForm.controls["username"].value["UserID"];
      if (this.loginForm.valid) {
        let result = await this.userPassLoginService.authenticate(
          this.selectedUserID,
          this.loginForm.get('password').value,
          this.ClientID
        );
        if (result) {
          this.userService.firstLogin(true);
          this.router.navigate(['fieldside/ticket-summary'], {
            queryParams: {
              firstSync: true,
            },
          });
        } else {
          this.snackBarService.openSnackbar('Invalid user password combination');
        }
      } else {
        this.snackBarService.openSnackbar('Invalid login information');
      }
    } catch (error) {
      this.loggerService.error(error);
    }
    this.loading = false;
    this.progressBarService.stop(); // done
  }

  /**
   * attempt to login via userService
   * if successful, navigate to home, else display snackbar
   */
  async onSubmit() {
    try {
      if (this.loginForm.valid) {
        this.progressBarService.start();
        this.tokenService.destroyRegistration(); //clear fieldside db name

        let loginResult = await this.userService.login(
          this.loginForm.get('username').value,
          this.loginForm.get('password').value
        );
        if (loginResult) {
          this.progressBarService.stop();
          if (this.userService.isSettingActive(SettingID.HOME_WORKSPACE)) {
            this.router.navigate(['app']);
          } else if (this.userService.isSettingActive(SettingID.TICKET_SUMMARY)) {
            this.router.navigate(['app/ticket-summary']);
          } else {
            this.router.navigate(['app/settings']);
          }
        } else {
          this.snackBarService.openSnackbar('Invalid user password combination');
        }
      } else if (this.emailClicked && !this.enterPassword) {
        this.onContinue();
      } else {
        this.snackBarService.openSnackbar('Invalid login information');
      }
    } catch (error) {
      this.loggerService.error(error);
      this.snackBarService.openSnackbar('Invalid login information');
    }
    this.progressBarService.stop();
  }

  onPasswordCancel() {
    if (this.enterPassword) {
      this.enterPassword = false;
    }
  }

  async onContinue() {
    if (this.loginForm.get('username').value) {
      //check email using api
      this.checkingEmail = true;
      let result = await this.userService.checkEmail(this.loginForm.get('username').value);

      if (result != null && result.exists) {
        if (result.isFederated) {
          localStorage.setItem('username', this.loginForm.get('username').value);
          localStorage.setItem('client_id', result['clientId']);

          const authorizationURL = result['url'];
          const url = new URL(authorizationURL);
          const redirectURI = this.getRedirectURI();
          url.searchParams.set('response_type', result['responseType']);
          url.searchParams.set('redirect_uri', redirectURI);
          url.searchParams.set('client_id', result['clientId']);
          url.searchParams.set('scope', result['scope']);
          url.searchParams.set('idp_identifier', result['identifier']);
          // // url.searchParams.set('code_challenge_method', 'S256');
          // // url.searchParams.set('code_challenge', btoa('test1'))
          window.location.assign(url.href);
        } else {
          this.enterPassword = true;
        }
      } else {
        this.snackBarService.openSnackbar('Invalid login information');
      }
      this.checkingEmail = false;
    } else {
      this.snackBarService.openSnackbar('Invalid login information');
    }
  }

  getRedirectURI() {
    return window.location.href.split('login')[0] + 'authorized-user';
  }
}

@Component({
  selector: 'app-privacy-policy-dialog',
  templateUrl: 'privacy-policy-dialog.html',
})
export class PrivacyPolicyDialogComponent {}
