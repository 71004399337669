import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { UserService } from '../user/user.service';
import { CellComponent } from 'tabulator-tables';

@Injectable({
  providedIn: 'root',
})
export class DatetimeService {
  constructor(
    private userService: UserService
  ) { }

  extractDateString(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const dateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return dateString
  }

  localDateToFormattedString(date: Date, format: string = 'yyyy-MM-dd HH:mm:ss'): string {
    if (date) {
      const dateString = this.extractDateString(date);
      const userTimezone = this.userService.getUserTimezone();

      return DateTime.fromFormat(dateString, 'yyyy-MM-dd HH:mm:ss', { zone: userTimezone }).toFormat(format);
    } else {
      throw null;
    }
  }

  localDateToDBDateStr(date: unknown, format: string = 'yyyy-MM-dd HH:mm:ss'): string {
    let checkedDate: Date | null =
      date instanceof Date ? date : typeof date === 'string' && this.isStringVaildDate(date) ? new Date(date) : null;

    if (checkedDate instanceof Date) {
      const dateString = this.extractDateString(checkedDate);

      const dbTimezone = this.userService.getWebDatabaseTimezone();
      const userTimezone = this.userService.getUserTimezone();
      const convertedDate = DateTime.fromFormat(dateString, format, { zone: userTimezone })
      const returnDate = convertedDate.setZone(dbTimezone).toFormat(format);
      return returnDate;
    } else {
      throw null;
    }
  }

  isStringVaildDate(str: string): boolean {
    const dateRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
    const dateTimeRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}$/;
    const eventDateTimeRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{1} [0-9]{2}:[0-9]{2}:[0-9]{2}$/;
    const dbDateRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.000Z$/;

    let isDate = false;

    if (dateRegex.test(str) || dateTimeRegex.test(str) || dbDateRegex.test(str) || eventDateTimeRegex.test(str)) {
      isDate = true;
    }
    return isDate;
  }

  dbDateToFormattedLocalDate(dbDate: string, format: string = 'yyyy-MM-dd HH:mm:ss'): string {
    if (dbDate) {
      if (dbDate.includes('T')) {
        const dateParts = dbDate.split('T');
        dbDate = dateParts[0] + ' ' + dateParts[1].split('.')[0];
      }
      const dbTimezone = this.userService.getWebDatabaseTimezone();
      const userTimezone = this.userService.getUserTimezone();
      dbDate = dbDate + ' ' + dbTimezone;
      let date = DateTime.fromFormat(dbDate, 'yyyy-MM-dd HH:mm:ss z', {
        setZone: true,
      });
      return date.setZone(userTimezone).toFormat(format);

    } else {
      throw null;
    }
  }

  dbDateToLocalDate(dbDate: string): Date {
    if (dbDate) {
      if (dbDate.includes('T')) {
        const dateParts = dbDate.split('T');
        dbDate = dateParts[0] + ' ' + dateParts[1].split('.')[0];
      }
      const dbTimezone = this.userService.getWebDatabaseTimezone();
      const userTimezone = this.userService.getUserTimezone();
      dbDate = dbDate + ' ' + dbTimezone;
      let date = DateTime.fromFormat(dbDate, 'yyyy-MM-dd HH:mm:ss z', {
        setZone: true,
      });
      date = date.setZone(userTimezone);

      return date.toJSDate();
    } else {
      throw null;
    }
  }

  dateTimeFromDbDate(dbDate: string): DateTime {
    if (dbDate) {
      if (dbDate.includes('T')) {
        const dateParts = dbDate.split('T');
        dbDate = dateParts[0] + ' ' + dateParts[1].split('.')[0];
      }
      const dbTimezone = this.userService.getWebDatabaseTimezone();
      dbDate = dbDate + ' ' + dbTimezone;
      return DateTime.fromFormat(dbDate, 'yyyy-MM-dd HH:mm:ss z', {
        setZone: true,
      });
    } else {
      throw null;
    }
  }

  getDateWindow(unit: 'years' | 'months' | 'days', quantity: number): { lower: Date; upper: Date } {
    const lower = DateTime.local()
      .minus({ [unit]: quantity })
      .toJSDate();
    const upper = DateTime.local()
      .plus({ [unit]: quantity })
      .toJSDate();
    return {
      lower,
      upper,
    };
  }

  getStartOfWeek() {
    return DateTime.local().startOf('week');
  }

  // returns dateA > dateB
  compareDates(dateA: string, dateB: string): boolean {
    if (this.isStringVaildDate(dateA) && this.isStringVaildDate(dateB)) {
      return this.dateTimeFromDbDate(dateA).startOf('day') > this.dateTimeFromDbDate(dateB).startOf('day');
    } else {
      return false;
    }
  }

  /**
   * adds minutes to date and returns the ISO string of the new date
   *
   * @param {string} date
   * @param {number} minutes
   * @return {*}
   * @memberof DatetimeService
   */
  addMinutesToDate(date: string, minutes: number): string {
    const newDate = DateTime.fromISO(date);
    newDate.plus({ minutes: minutes });
    return newDate.toISO();
  }

  isValidDateRange(lower: DateLike, upper: DateLike): boolean {
    try {
      if (lower !== null && upper !== null) {
        return new Date(lower) <= new Date(upper);
      } else if (lower === null || upper === null) {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  /**
   * Takes an isoDate and returns based on options passed
   * @param date - ISO string
   * @param options - formatting options as per https://moment.github.io/luxon/#/formatting
   */
  format(date: DateLike, options: Record<string, string> | string): string {
    let dt;
    if (typeof date === 'string') {
      dt = DateTime.fromISO(date);
    } else if (date instanceof Date) {
      dt = DateTime.fromJSDate(date);
    }
    if (typeof options === 'string') {
      options = DateTime[options];
    }
    return dt.toLocaleString(options);
  }
}

export type DateLike = string | Date;
