import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { takeUntil } from 'rxjs/operators';
import { ThrottleClickDirective } from 'src/app/modules/core/directives/click-throttle.directive';
import { TableColumnSelectComponent } from 'src/app/modules/shared/table/table-column-select/table-column-select.component';
import { TableService } from 'src/app/modules/shared/table/table.service';
import { TableComponent } from '../../../modules/shared/table/table/table.component';
import { Subject } from 'rxjs';
import { SelectionType } from '@swimlane/ngx-datatable';
import { TemplateColumn, TicketSearchService } from '../../../shared/services/ticket-search/ticket-search.service';
import { DatetimeService } from '../../../modules/core/services/datetime/datetime.service';
import { MatDialog } from '@angular/material/dialog';
import { HomeWorkspaceService } from '../home-workspace.service';
import { TicketActionsMenuComponent } from './ticket-actions-menu/ticket-actions-menu.component';
import { ButtonDropdownMenuComponent } from '../../../shared/components/menus/button-dropdown-menu/button-dropdown-menu.component';
import { ButtonDropdownSelectComponent } from '../../../shared/components/inputs/button-dropdown-select/button-dropdown-select-select.component';
import { FormsModule } from '@angular/forms';
import { SearchableDropdownComponent } from '../../../shared/components/inputs/searchable-dropdown/searchable-dropdown.component';
import { AsyncPipe, CommonModule, formatDate } from '@angular/common';
import { TicketActionsService } from '../../../shared/services/ticket-actions/ticket-actions.service';
import { Ticket } from '../../../modules/shared/ticket/ticket.service';
import { UserService } from '../../../modules/core/services/user/user.service';
import { SettingID } from '../../../modules/core/services/user/setting';
import { MatSort } from '@angular/material/sort';
import { TabulatorTableComponent } from 'src/app/modules/shared/table/tabulator-table/tabulator-table.component';
import { CallTypeID, LocateStatusID } from 'src/app/modules/shared/ticket-details/ticket-details.module';
import { CellComponent, RowComponent } from 'tabulator-tables';
import { CompetersSearchBarComponent } from '../../../shared/components/inputs/competers-search-bar/competers-search-bar.component';

const columnsToHideFromTemplate: string[] = [
  'assignmentid',
  'locatestatusid',
  'calltypeid',
  'assigned id',
  'regionid',
  'substatusid',
  'hexcolour',
];

@Component({
  selector: 'app-ticket-list-table',
  templateUrl: './ticket-list-table.component.html',
  styleUrls: ['./ticket-list-table.component.scss'],
  providers: [TableService],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ThrottleClickDirective,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    TableColumnSelectComponent,
    TableComponent,
    TicketActionsMenuComponent,
    ButtonDropdownMenuComponent,
    ButtonDropdownSelectComponent,
    FormsModule,
    SearchableDropdownComponent,
    AsyncPipe,
    TabulatorTableComponent,
    CompetersSearchBarComponent,
  ],
})
export class TicketListTableComponent implements OnInit, OnDestroy {
  // IO
  @Input() resizeTrigger: Subject<void>;
  @Input() currentTabView: Subject<number[]>;
  @Input() persistSelectionThroughDataChange: boolean;
  @Output() actionTriggered = new EventEmitter();
  //services
  protected cdr = inject(ChangeDetectorRef);
  protected settingsService = inject(UserService);
  protected tableService = inject(TableService<Ticket>);
  protected ticketActionsService = inject(TicketActionsService);
  protected homeWorkspaceService = inject(HomeWorkspaceService);

  // observables
  private destroy$ = new Subject<void>();
  // members
  selectionType: SelectionType = SelectionType.checkbox;
  totalRecords = 0;
  actions: any = [];
  selected: any = [];
  tableColumns: any[] = [];
  // Setup
  tableTemplate: TemplateColumn[] = [];
  doubleClickCallBack;
  getCellClassCallback;
  rows: any[] = [];
  tmpRows: any[] = [];
  showColumnSelect: boolean = false;
  // eslint-disable-next-line @typescript-eslint/ban-types
  cellFormatters: Function[] = [];
  // eslint-disable-next-line @typescript-eslint/ban-types
  rowFormatter: Function;

  leftPaddingForTableButtons: boolean = false;

  //table
  @ViewChild('tabulatorTable') tabulatorTable: TabulatorTableComponent<Ticket, Pick<Ticket, 'SubNum'>>;

  tableHeight: string;
  searchTerm: string;
  searchableColumnList: { name: string; value: unknown }[] = [];
  selectedColumnToSearch: { name: string; value: unknown } = { name: 'All', value: null };

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public searchService: TicketSearchService,
    private dateService: DatetimeService,
    public dialog: MatDialog,
    private renderer: Renderer2,
    private dateTimeService: DatetimeService,
    private userService: UserService
  ) {
    this.setupColumnFormatters();
  }

  ngOnInit(): void {
    // subscribe to the subject which holds the current tabs
    this.currentTabView.subscribe((event) => {
      // if we're looking at both tabs,
      // then we want to have no padding for the buttons
      if (event.length > 1) {
        this.leftPaddingForTableButtons = false;
      } else {
        // but, if we are only viewing a single tab (map or table - it doesn't matter) then we
        // DO want to have the padding, since the floating tab button will overlap the buttons
        this.leftPaddingForTableButtons = true;
      }
    });

    this.searchService
      .getTemplate()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        try {
          this.tableTemplate = [];
          // if the column Title is in columnsToHideFromTemplate, remove it from the list
          result.forEach((column) => {
            if (columnsToHideFromTemplate.includes(column.Title?.toLowerCase())) {
              column.Visible = 0;
            }

            this.tableTemplate.push(column);
          });
          this.setSearchableColumns(this.tableTemplate);
          this.setTableColumnsFromTempalte(this.tableTemplate);

          //set the columns to select from in dropdown
          const filteredColumns = this.tableTemplate.filter((element) => {
            //if the element is not found in alwaysHideTheseColumns
            if (!columnsToHideFromTemplate.includes(element.Title?.toLowerCase())) {
              return element;
            }
          });
          this.tableService.setColumns(filteredColumns);
        } catch (error) {
          console.error(error);
        }
        this.cdr.detectChanges();
      });
    this.searchService.fetchDefaultTemplate();
    //subscribe to the column updates so we know when to hide or show columns
    this.tableService
      .getColumnUpdates()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: TemplateColumn[]) => {
        console.log('getColumnUpdates');
        if (this.tableTemplate !== result && result.length > 0) {
          this.tableTemplate = result;
          this.setTableColumnsFromTempalte(result);

          //save the template since there are new columns
          this.saveColumnTemplate();

          this.searchService.updateTemplateData(this.tableTemplate);
          this.searchService.saveTemplateData();
          this.setSearchableColumns(this.tableTemplate);
          this.cdr.detectChanges();
        }
      });
  }

  /**
   * Sets this.searchableColumnList based off the input
   *
   * @param {TemplateColumn[]} templateInput
   * @memberof TicketListTableComponent
   */
  setSearchableColumns(templateInput: TemplateColumn[]) {
    this.searchableColumnList = [{ name: 'All', value: null }];
    templateInput.forEach((element) => {
      if (element.Visible) this.searchableColumnList.push({ name: element.Title, value: element.Title });
    });
    //sort searchableColumnList alphabetically
    this.searchableColumnList.sort((a, b) => a.name.localeCompare(b.name));
  }

  /**
   * Sets `this.tableColumns` from the template input.
   * This filters out invisible columns
   * This is called when the template input changes.
   * This is also called when the columns are updated from the settings page.
   * @param templateInput
   */
  setTableColumnsFromTempalte(templateInput: TemplateColumn[]) {
    const visibleColumns = templateInput;

    //order visibleColumns by ColumnOrder
    visibleColumns.sort((a, b) => a.ColumnOrder - b.ColumnOrder);
    //set the tableColumns
    this.tableColumns = [];
    this.tableColumns = visibleColumns;
  }

  formatTitle(title: string) {
    //add a space between a lowercase and uppercase character
    return title.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  onSearchColumnSelectChange($event: { name: string; value: unknown }) {
    this.selectedColumnToSearch = $event;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleRefreshClick() {
    this.searchService.refreshSearchResults();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openTicket(row: any) {
    this.homeWorkspaceService.openTicket({ AssignmentID: row.AssignmentID, PrimaryID: row.SubNum });
  }

  triggerAction(e) {
    this.setSelectedRows();
    const selectedRows = this.selected;

    this.actionTriggered.emit({
      actionID: e.value,
      primaryIDs: selectedRows.map((row) => row['SubNum']),
      assignmentIDs: selectedRows.map((row) => row['AssignmentID']),
    });
  }

  isAllSelected() {
    const numSelected = this.selected.length;
    const numRows = this.rows.length;
    return numSelected === numRows;
  }

  //TODO: Add template for this
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  templateChanged(e: TemplateColumn[]) {
    console.log(e);
  }

  /**
   * Sets up the column formatters for the tabulator table. These functions should
   * be designed not to style the cells, but to format dates, numbers, etc..
   *
   * @memberof TicketListTableComponent
   */
  setupColumnFormatters() {
    this.cellFormatters['Archived'] = this.formatBoolean;
    this.cellFormatters['Call Date'] = this.formatDate;
    this.cellFormatters['TransmitDate'] = this.formatDate;
    this.cellFormatters['Work to Begin Date'] = this.formatDate;
    this.cellFormatters['EntryDate'] = this.formatDate;
    this.cellFormatters['Date Completed'] = this.formatDate;
    this.cellFormatters['App Date'] = this.formatDate;
    this.cellFormatters['Orig. Excavation Date'] = this.formatDate;
    this.rowFormatter = this.formatRow;
  }

  /**
   * Formats a bool into a "yes" or "no"
   * @param {CellComponent} cell
   * @return {string} "yes" or "no"
   * @memberof TicketListTableComponent
   */
  formatBoolean(cell: CellComponent): string {
    const value = cell.getValue();
    if (value != null) {
      return value.toString() === '1' ? 'Yes' : 'No';
    }
  }

  /**
   * Applies a style to a desired cell denoted by its name
   *
   * @private
   * @param {CellComponent[]} cells
   * @param {string} cellFieldName
   * @param {string} classToApply
   * @memberof TicketListTableComponent
   */
  private addStyleToCell(cells: CellComponent[], cellFieldName: string, classToApply: string): void {
    if (!cells || !cellFieldName || !classToApply) {
      return;
    }
    const cellToStyle = cells.find((cell) => cell.getField()?.toLowerCase() === cellFieldName.toLowerCase());
    if (cellToStyle) {
      cellToStyle.getElement().classList.add(classToApply);
    }
  }

  /**
   * Adds style to the entire row
   *
   * @private
   * @param {RowComponent} row
   * @param {string} classToApply
   * @memberof TicketListTableComponent
   */
  private addStyleToRow(row: RowComponent, classToApply: string): void {
    row.getElement().classList.add(classToApply);
  }

  /**
   * Applies style to the row for "LocateStatusID"
   *
   * @private
   * @param {unknown} data
   * @param {RowComponent} row
   * @memberof TicketListTableComponent
   */
  private applyLocateStatusIDStyles(data: unknown, cells: CellComponent[]): void {
    if (!data['LocateStatusID']) return;

    switch (data['LocateStatusID']) {
      case LocateStatusID.DIG_COMPLETED:
      case LocateStatusID.PROJECT_FINISHED:
      case LocateStatusID.LOCATE_COMPLETED:
      case LocateStatusID.PRE_SKETCHED:
        this.addStyleToCell(cells, 'work to begin date', 'tabulator-table-done');
        this.addStyleToCell(cells, 'call date', 'tabulator-table-done');
        break;
    }
  }

  /**
   * Applies style to the row for "Call Type ID"
   *
   * @private
   * @param {unknown} data
   * @param {RowComponent} row
   * @memberof TicketListTableComponent
   */
  private applyCallTypeStyles(data: unknown, cells: CellComponent[]): void {
    if (!data['CallTypeID']) return;

    switch (data['CallTypeID']) {
      case CallTypeID.EMERGENCY:
        this.addStyleToCell(cells, 'call type', 'tabulator-table-emergency');
        break;
      case CallTypeID.PRIVATE:
        this.addStyleToCell(cells, 'call type', 'tabulator-table-private');
        break;
      case CallTypeID.SEWER_LATERAL:
        this.addStyleToCell(cells, 'call type', 'tabulator-table-sewer');
        break;
      case CallTypeID.PLANNING:
        this.addStyleToCell(cells, 'call type', 'tabulator-table-violet');
        break;
      case CallTypeID.PROJECT_WORK:
      case CallTypeID.LARGE_PROJECT:
        this.addStyleToCell(cells, 'call type', 'tabulator-table-white-smoke');
        break;

      case CallTypeID.INSPECTION:
        this.addStyleToCell(cells, 'call type', 'tabulator-table-white-smoke');
        break;

      case CallTypeID.MASTER_PROJECT_WORK:
      case CallTypeID.PRIORITY0:
      case CallTypeID.PRIORITY1:
      case CallTypeID.PRIORITY2:
      case CallTypeID.PRIORITY3:
      case CallTypeID.PRIORITY4:
        this.addStyleToCell(cells, 'call type', 'tabulator-table-call-type-priority');
        break;

      case CallTypeID.PSLL:
      case CallTypeID.PSLL_EMERGENCY:
      case CallTypeID.PSLL_PLANNED:
      case CallTypeID.PSLL_POST_INSPECTION:
      case CallTypeID.PSLL_PROJECT:
        this.addStyleToCell(cells, 'call type', 'tabulator-table-spring-green');
        break;
    }
  }

  /**
   * Applies style to the row for "Meet Req"
   *
   * @private
   * @param {unknown} data
   * @param {RowComponent} row
   * @memberof TicketListTableComponent
   */
  private applyMeetingReqStyles(data: unknown, cells: CellComponent[]): void {
    if (!data['Meet Req']) return;

    if (data['Meeting Req'].toLowerCase() === 'required') {
      this.addStyleToCell(cells, 'meeting req', 'tabulator-table-meeting-req');
    }
  }

  /**
   * Applies style to the row for "App Dat"
   *
   * @private
   * @param {unknown} data
   * @param {RowComponent} row
   * @memberof TicketListTableComponent
   */
  private applyAppointmentDateStyles(data: unknown, cells: CellComponent[]): void {
    if (!data['App Date']) return;

    if (data['App Date'] && data['App Date'].toLowerCase().length > 0) {
      this.addStyleToCell(cells, 'app date', 'tabulator-table-meeting-req');
    }
  }

  /**
   * Applies style to the row for "Status"
   *
   * @private
   * @param {unknown} data
   * @param {RowComponent} row
   * @memberof TicketListTableComponent
   */
  private applyStatusStyles(data: unknown, cells: CellComponent[]): void {
    if (!data['Status']) return;

    switch (data['Status'].toString().toUpperCase()) {
      case 'READY FOR DISPATCH':
        this.addStyleToCell(cells, 'app date', 'tabulator-table-ready-for-dispatch');
        break;
      case 'OFFICE CANCELLED':
        this.addStyleToCell(cells, 'status', 'tabulator-table-cancelled');
        break;
      case 'ASSISTANCE NEEDED':
        this.addStyleToCell(cells, 'status', 'tabulator-table-assistance-needed');
        break;
      case 'REVIEW REQUIRED':
        this.addStyleToCell(cells, 'status', 'tabulator-table-review-required');
        break;
    }
  }

  /**
   * Applies style to the row for "Trans. Type"
   *
   * @private
   * @param {unknown} data
   * @param {RowComponent} row
   * @memberof TicketListTableComponent
   */
  private applyTransmitTypeStyles(data: unknown, cells: CellComponent[]): void {
    if (!data['Trans. Type']) return;

    switch (data['Trans. Type']) {
      case 'CANCELLED':
        this.addStyleToCell(cells, 'status', 'tabulator-table-cancelled');
        break;
    }
  }

  /**
   * Applies style to the row for "Third Party"
   *
   * @private
   * @param {unknown} data
   * @param {RowComponent} row
   * @memberof TicketListTableComponent
   */
  private applyThirdPartyStyle(data: unknown, cells: CellComponent[]): void {
    if (!data['Third Party']) return;

    switch (data['Third Party']) {
      case 0:
        this.addStyleToCell(cells, 'thirdparty', 'tabulator-table-ivory');
        break;
    }
  }

  /**
   * Applies style to the row for "Fax Grid Type"
   *
   * @private
   * @param {unknown} data
   * @param {RowComponent} row
   * @memberof TicketListTableComponent
   */
  private applyFaxGridTypeStyle(data: unknown, row: RowComponent): void {
    if (!data['Fax Grid Type']) return;

    switch (data['Fax Grid Type']) {
      case 'FAX':
        this.addStyleToRow(row, 'tabulator-table-salmon');
        break;
    }
  }

  /**
   * Applies the "Archived" style to the row
   *
   * @private
   * @param {*} data
   * @param {*} row
   * @return {*}
   * @memberof TicketListTableComponent
   */
  private applyArchivedStyle(data: unknown, row: RowComponent): void {
    if (!data['Archived']) return;

    switch (data['Archived']) {
      case 1:
        this.addStyleToRow(row, 'tabulator-table-archived');
        break;
    }
  }

  /**
   * Checks weather a row from the datatable is considered "late"
   *
   * @param {{}} data the data row
   * @param {Date} dateNow the date right now
   * @return {boolean} whether the row is late or not
   * @memberof TicketListTableComponent
   */
  isAssignmentRowLate(data: object, dateNow: Date): boolean {
    let late = false;

    const completedStatuses = [
      LocateStatusID.DIG_COMPLETED,
      LocateStatusID.PROJECT_FINISHED,
      LocateStatusID.LOCATE_COMPLETED,
      LocateStatusID.REVIEW_REQUIRED,
    ];

    //the locate is completed, so it cannot be late
    if (completedStatuses.includes(data['LocateStatusID'])) {
      return late;
    }

    if (data['CallTypeID'] === CallTypeID.EMERGENCY && !data['TransmitDate']) {
      //get transmit date 120 minutes in the future
      const transmitDateFuture = new Date(this.dateTimeService.addMinutesToDate(data['Orig. Excavation Date'], 120));
      // if now is greater than transmit date 120 minutes in the future
      if (dateNow > transmitDateFuture) {
        late = true;
      }
    } else if (data['CallTypeID'] === CallTypeID.PRIORITY0 && !data['TransmitDate']) {
      const dayAfterTransmitDate = new Date(
        this.dateTimeService.addMinutesToDate(data['Orig. Excavation Date'], 24 * 60)
      );
      //if now is greater than day after transmit date
      if (dateNow > dayAfterTransmitDate) {
        late = true;
      }
    } else if (data['Work to Begin Date']) {
      if (dateNow > new Date(data['Work to Begin Date'])) {
        late = true;
      }
    } else if (
      data['SubStatus'].toString().toLowerCase() === 'lookup required' ||
      data['SubStatus'].toString().toLowerCase() === 'suggested clear'
    ) {
      const dayAfterEntryDate = new Date(this.dateTimeService.addMinutesToDate(data['EntryDate'], 24 * 60));

      //if now is greater than day after entry date
      if (dateNow > dayAfterEntryDate) {
        late = true;
      }
    }
    return late;
  }

  /**
   * Function to format the row for the table. This function
   * runs for each row and is given to the tabulator table
   *
   * @param {object} row
   * @memberof TicketListTableComponent
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatRow = (row: RowComponent) => {
    const data = { ...row.getData() };
    const cells = row.getCells();
    const dateNow = new Date(Date.now());

    const rowIsLate = this.isAssignmentRowLate(data, dateNow);

    if (data['Orig. Excavation Date'] && data['Work to Begin Date']) {
      if (data['Orig. Excavation Date'] !== data['Work to Begin Date']) {
        this.addStyleToCell(cells, 'excavationdate', 'tabulator-table-sandy-brown');
      }
    }

    if (rowIsLate) {
      if (
        !this.userService.isSettingActive(SettingID.DISABLE_LATE_COLOURING_FOR_LOCATORS) ||
        this.userService.getSettingValue(SettingID.DISABLE_LATE_COLOURING_FOR_LOCATORS) !== '1'
      ) {
        this.addStyleToCell(cells, 'work to begin date', 'tabulator-table-late');
        this.addStyleToCell(cells, 'call date', 'tabulator-table-late');
      }
    }

    this.applyArchivedStyle(data, row);
    this.applyFaxGridTypeStyle(data, row);
    this.applyThirdPartyStyle(data, cells);
    this.applyLocateStatusIDStyles(data, cells);
    this.applyTransmitTypeStyles(data, cells);
    this.applyCallTypeStyles(data, cells);
    this.applyStatusStyles(data, cells);
    this.applyMeetingReqStyles(data, cells);
    this.applyAppointmentDateStyles(data, cells);
  };

  formatDate = (cell: CellComponent) => {
    const value = cell.getValue();
    if (value != null) {
      return this.dateTimeService.dbDateToFormattedLocalDate(value);
    }
  };

  /**
   * Sets the selected rows from the tabulator table.
   * The selected rows are stored in the table service.
   * The table service is used to store the selected rows.
   */
  setSelectedRows() {
    this.selected = [];
    const selectedRows = this.tabulatorTable.getSelectedRows();
    selectedRows.forEach((row) => {
      const newRow = { ...row };
      this.selected.push(newRow);
    });
  }

  /**
   * Updates the column order and saves it
   * @param columns
   */
  colReorder(columns: any[]) {
    console.log('colReorder');
    for (let i = 0; i < columns.length; i++) {
      const colTitle = columns[i].getField();
      if (colTitle) {
        const colToUpdate = this.tableTemplate.find((x: TemplateColumn) => x.Title === colTitle);
        if (colToUpdate) {
          colToUpdate.ColumnOrder = i; //set the order
        }
      }
    }
    this.saveColumnTemplate();
  }

  /**
   * Updates the column width and saves it
   * @param colChangeEvent
   */
  colWidthChange(colChangeEvent: { title: string; width: number }) {
    const colToUpdate = this.tableTemplate.find((x: TemplateColumn) => x.Title === colChangeEvent.title);
    if (colToUpdate) {
      colToUpdate.Width = colChangeEvent.width;
    }
    this.saveColumnTemplate();
  }

  /**
   * Saves the current column template to the db
   *
   * @param {*} [template=this.tableTemplate]
   * @memberof TicketListTableComponent
   */
  saveColumnTemplate(template: TemplateColumn[] = this.tableTemplate): void {
    //just make sure the invisible columns stay invisible
    template.forEach((column) => {
      if (columnsToHideFromTemplate.includes(column.Title?.toLowerCase())) {
        column.Visible = 0;
      }
    });

    //then we save
    this.searchService.updateTemplateData(template);
    this.searchService.saveTemplateData();
  }

  downloadTable() {
    this.tabulatorTable.downloadTable();
  }

  protected readonly SettingID = SettingID;
}
