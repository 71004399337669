import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { isEqual, reduce } from 'lodash-es';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { SettingID } from 'src/app/modules/core/services/user/setting';
import { FAMItem } from '../../fab/floating-action-menu/floating-action-menu.component';
import { LocateStatusID } from '../../ticket-details/ticket-details.module';
import { AutologExplanationModalComponent } from '../modals/autolog-explaination-modal/autolog-explanation-modal.component';
import { ConfirmationModalComponent } from '../modals/generic-confirmation-modal/confirmation-modal.component';
import { TicketService } from '../ticket.service';
import { EditUtilitiesModalComponent } from '../modals/edit-utilities-modal/edit-utilities-modal/edit-utilities-modal.component';
import {
  CreateTicketComponentService,
  OptionsFillID,
} from 'src/app/modules/create-ticket/create-ticket-component.service';
import { SnackbarService } from '../../snackbar/snackbar.service';
import { SnackbarType } from '../../snackbar/snackbar/snackbar';

@Component({
  selector: 'app-ticket-completions',
  templateUrl: 'ticket-completions.component.html',
  styleUrls: ['./ticket-completions.component.scss'],
})
export class TicketCompletionsComponent implements OnInit, OnChanges {
  @Input() CompletionsBillingDetails: object;
  @Input() CompletionsPrimaryDetails: object;
  @Input() CompletionsCommonDetails: object;
  @Input() isU2AuditTicket: boolean;
  @Input() primaryID: number;
  @Input() assignmentID: number;
  @Input() activeUtilities: number[] = [];
  @Input() locateStatusID: number;

  @Output() UtilitiesChanged = new EventEmitter<unknown>();
  @Output() CompletionsChanges = new EventEmitter<unknown>();
  @Output() VerifyEvent = new EventEmitter<unknown>();
  @Output() MarkAsCompleteEvent = new EventEmitter<unknown>();
  @Output() Swipe = new EventEmitter<unknown>();

  BillingDetailProps: unknown = {};
  PrimaryDetailProps: unknown = {};
  CommonDetailProps: unknown = {};

  billingLoaded = false;
  commonLoaded = false;
  primaryLoaded = false;

  BillingNavLink: unknown[];
  PrimaryNavLink: unknown[];
  CommonNavLink: unknown[];

  billingAlreadyListening = false;

  fabMenu: FAMItem[];
  bottomMenuOpen: boolean = false;
  completionsAllowed: unknown;
  isPrimary: boolean = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unlocatableClicked: any;

  //TODO: add more locate statusIDs as needed
  completedLocateStatusIDs = [LocateStatusID.LOCATE_COMPLETED, LocateStatusID.COMPLETED_BY_LSP];

  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    private ticketService: TicketService,
    private snackBarService: SnackbarService,
    private createticketService: CreateTicketComponentService
  ) {}

  async ngOnInit() {
    this.completionsAllowed = this.userService.isSettingActive(SettingID.ALLOW_COMPLETE);
    const verifyAllowed = await this.ticketService.checkUnlocatbaleVerifyAllowed();
    if (verifyAllowed) {
      this.completionsAllowed = false;
    }

    this.addUnlocatableBtn();
    this.unlocatableClicked = this.ticketService.getUnlocatableValue();
  }

  async ngOnChanges(changes) {
    if (this.isU2AuditTicket != null) {
      if (this.isU2AuditTicket) {
        this.fabMenu = [
          {
            label: 'Complete Ticket',
            icon: 'check_circle',
            action: this.markComplete,
          },
          {
            label: 'Verify Inputs',
            icon: 'verify',
            action: this.onVerifyCompletion,
            iconType: 'svg',
          },
        ];
      } else {
        const ticketIsCompleted = this.completedLocateStatusIDs.indexOf(this.locateStatusID) != -1;
        //if the ticket is not completed, or it is completed but they have the setting to edit completed tickets
        if (!ticketIsCompleted || (ticketIsCompleted && this.userService.isSettingActive(SettingID.EDIT_COMPLETED))) {
          this.fabMenu = [
            {
              label: 'Complete Ticket',
              icon: 'yes-filled',
              action: this.markComplete,
              iconType: 'svg',
            },
            {
              label: 'Mark as Ongoing',
              icon: 'ongoing',
              action: this.markOngoing,
              iconType: 'svg',
            },
            {
              label: 'Assistance Needed',
              icon: 'warning',
              action: this.markAssitanceNeeded,
              iconType: 'svg',
            }
          ]
        } else {
          this.fabMenu = [];
        }
 
        if (this.userService.isSettingActive(SettingID.ON_HOLD) && !ticketIsCompleted) {
          this.fabMenu.push({
            label: 'Mark as On Hold',
            icon: 'on_hold',
            action: this.markOnHold,
            iconType: 'svg',
          });
        }
        if (this.userService.isSettingActive(SettingID.NOT_CLEAR) && !ticketIsCompleted) {
          this.fabMenu.push({
            label: 'Mark as Not Clear',
            icon: 'not_clear',
            action: this.markNotClear,
            iconType: 'svg',
          });
        }
        if (!ticketIsCompleted || (ticketIsCompleted && this.userService.isSettingActive(SettingID.EDIT_COMPLETED))) {
          this.fabMenu.push({
              label: 'Verify Inputs',
              icon: 'verify',
              action: this.onVerifyCompletion,
              iconType: 'svg',
          });
        }

        if (this.userService.isSettingActive(SettingID.ADD_REMOVE_UTILITIES) && !ticketIsCompleted) {
          this.fabMenu.unshift({
            label: 'Edit Utilities',
            icon: 'edit',
            action: this.editUtilities,
          });
        }
      }
    }

    if (this.CompletionsBillingDetails) {
      this.billingLoaded = false;
      console.log(this.CompletionsBillingDetails);
      const viewsKeys = Object.keys(this.CompletionsBillingDetails);
      this.BillingDetailProps = {};

      //wait for timeout so it loads properly
      await new Promise((resolve) => setTimeout(resolve, 100)); //refresh the view by hiding then showing it

      for (let i = 0; i < viewsKeys.length; i++) {
        const viewsKey = viewsKeys[i];
        const viewKey = this.CompletionsBillingDetails[viewsKey].key;

        this.BillingDetailProps[viewsKey] = {
          view: this.CompletionsBillingDetails[viewsKey],
          views: this.CompletionsBillingDetails,
        };

        this.billingAlreadyListening = true;

        let compareAgainst: object = this.CompletionsBillingDetails[viewsKey].formGroup.value;

        //when a value changes in billing, do something
        this.CompletionsBillingDetails[viewsKey].formGroup.valueChanges.subscribe((nextVal) => {
          const changedIDs = reduce(
            nextVal,
            function (result, value, key) {
              return isEqual(
                value == 0 ? false : value == true ? true : value,
                compareAgainst[key] == true ? true : compareAgainst[key] == false ? false : compareAgainst[key]
              )
                ? result
                : result.concat(key);
            },
            []
          );

          const changedRows = reduce(
            nextVal,
            function (result, value, key) {
              if (changedIDs.indexOf(key) > -1) {
                result[key] = value;
              }
              return result;
            },
            {}
          );

          compareAgainst = nextVal;
          this.CompletionsChanges.emit({
            formValue: changedRows,
            viewKey,
            CompletionType: 'Billing',
          });
        });
      }

      this.BillingNavLink = viewsKeys;
      this.billingLoaded = true;
    }

    if (this.CompletionsPrimaryDetails && Object.keys(this.CompletionsPrimaryDetails).length !== 0) {
      const viewsKeys = Object.keys(this.CompletionsPrimaryDetails);
      for (let i = 0; i < viewsKeys.length; i++) {
        const viewsKey = viewsKeys[i];
        const viewKey = this.CompletionsPrimaryDetails[viewsKey].key;
        this.PrimaryDetailProps[viewsKey] = {
          view: this.CompletionsPrimaryDetails[viewsKey],
          views: this.CompletionsPrimaryDetails,
        };

        let compareAgainst: object = this.CompletionsPrimaryDetails[viewsKey].formGroup.value;
        this.CompletionsPrimaryDetails[viewsKey].formGroup.valueChanges.subscribe((nextVal) => {
          const changedIDs = reduce(
            nextVal,
            function (result, value, key) {
              return isEqual(
                value == 0 ? false : value == true ? true : value,
                compareAgainst[key] == true ? true : compareAgainst[key] == false ? false : compareAgainst[key]
              )
                ? result
                : result.concat(key);
            },
            []
          );

          const changedRows = reduce(
            nextVal,
            function (result, value, key) {
              if (changedIDs.indexOf(key) > -1) {
                result[key] = value;
              }
              return result;
            },
            {}
          );
          compareAgainst = nextVal;

          this.CompletionsChanges.emit({
            formValue: changedRows,
            viewKey,
            CompletionType: 'Primary',
          });
        });
      }

      this.PrimaryNavLink = viewsKeys;
      this.primaryLoaded = true;
    } else {
      this.PrimaryDetailProps = {};
      this.PrimaryNavLink = [];
      this.primaryLoaded = false;
    }

    if (this.isU2AuditTicket == false && this.CompletionsCommonDetails) {
      // console.log(this.CompletionsCommonDetails);
      const viewsKeys = Object.keys(this.CompletionsCommonDetails);
      for (let i = 0; i < viewsKeys.length; i++) {
        const viewsKey = viewsKeys[i];
        const viewKey = this.CompletionsCommonDetails[viewsKey].key;

        this.CommonDetailProps[viewsKey] = {
          view: this.CompletionsCommonDetails[viewsKey],
          views: this.CompletionsCommonDetails,
        };

        let compareAgainst: object = this.CompletionsCommonDetails[viewsKey].formGroup.value;
        this.CompletionsCommonDetails[viewsKey].formGroup.valueChanges.subscribe((nextVal) => {
          const changedIDs = reduce(
            nextVal,
            function (result, value, key) {
              return isEqual(
                value == 0 ? false : value == true ? true : value,
                compareAgainst[key] == true ? true : compareAgainst[key] == false ? false : compareAgainst[key]
              )
                ? result
                : result.concat(key);
            },
            []
          );

          const changedRows = reduce(
            nextVal,
            function (result, value, key) {
              if (changedIDs.indexOf(key) > -1) {
                result[key] = value;
              }
              return result;
            },
            {}
          );
          compareAgainst = nextVal;

          this.CompletionsChanges.emit({
            formValue: changedRows,
            viewKey,
            CompletionType: 'Common',
          });
        });
      }

      this.CommonNavLink = viewsKeys;
      this.commonLoaded = true;
    }
  }

  async getClientUtilities() {
    const utilityList = [];
    const utilityResult = await this.createticketService.getFilterOptionsDB(OptionsFillID.utilities);
    utilityResult.forEach((element) => {
      utilityList.push({ name: element.text, value: element.value });
    });
    return utilityList;
  }

  /**
   * Opens the edit utility component and calls updateTicketUtilities on close
   */
  editUtilities = async () => {
    //1. get the list of utilities

    try {
      const selectedUtilities = this.activeUtilities;
      const utilitList = await this.getClientUtilities();
      const filteredUtilityList = [];

      utilitList.forEach((element) => {
        selectedUtilities.includes(element.value) ? filteredUtilityList.push(element) : null;
      });

      this.dialog
        .open(EditUtilitiesModalComponent, {
          maxWidth: '500px',
          height: 'auto',
          maxHeight: '500px',
          data: { dropdownOptions: utilitList, selectedUtilities: filteredUtilityList },
        })
        .afterClosed()
        .subscribe(async (nextVal) => {
          if (nextVal) {
            await this.updateTicketUtilities(nextVal.result);
          } else {
            this.snackBarService.openSnackbar('No changes made', SnackbarType.warning);
          }
        });
    } catch (error) {
      console.error(error);
      this.snackBarService.openSnackbar('Something went wrong', SnackbarType.warning);
    }
  };

  /**
   * Updates the ticket to include the new utility list
   * @param utilityList
   */
  async updateTicketUtilities(utilityList: { utilities: { value: number; name: string }[]; explanation: string }) {
    const currentUtilityIDs = this.activeUtilities;
    const difference = this.getDifferenceBetweenUtilityList(currentUtilityIDs, utilityList);

    //if the utilities before are the same as the ones we just got - make no changes
    if (difference.length == 0) {
      this.snackBarService.openSnackbar('No changes made', SnackbarType.error);
    } else {
      this.updateTicketUtilityList(difference, utilityList);
    }
  }

  updateTicketUtilityList(
    difference,
    utilityList: { utilities: { value: number; name: string }[]; explanation: string }
  ) {
    this.CompletionsBillingDetails['activeUtilities'] = utilityList.utilities;
    this.UtilitiesChanged.emit({
      difference: difference,
      explanation: utilityList.explanation,
    });
  }

  getDifferenceBetweenUtilityList(
    curUtilityIDs: number[],
    utilityList: { utilities: { value: number; name: string }[]; explanation: string }
  ) {
    const actions: { id: number; action: 'add' | 'remove' }[] = [];
    try {
      const currentUtilityIDs = new Set(curUtilityIDs);
      const newUtilityIds = new Set(utilityList.utilities.map((utility) => utility.value));

      const addedIds = new Set([...newUtilityIds].filter((id) => !currentUtilityIDs.has(id)));
      for (const id of addedIds) {
        actions.push({ id, action: 'add' });
      }

      const removedIds = new Set([...currentUtilityIDs].filter((id) => !newUtilityIds.has(id)));
      for (const id of removedIds) {
        actions.push({ id, action: 'remove' });
      }

      console.log('Actions:', actions);
    } catch (error) {
      console.error(error);
    }
    return actions;
  }

  onVerifyCompletion = () => {
    this.VerifyEvent.emit(true);
  };

  trackByFn(index) {
    return index;
  }

  handleCheckboxChange(event) {
    const key = event[0];
    if (this.unlocatableClicked) {
      this.unlocatableClicked[key] = event[1];
    }
  }

  async addUnlocatableBtn() {
    const billingCats = this.ticketService.getTicketBillingCategories();
    if (billingCats?.includes(36) | billingCats?.includes(37)) {
      this.fabMenu.push({
        label: 'Mark Unlocatable',
        icon: 'unlocatable',
        action: this.markUnlocatable,
        iconType: 'svg',
      });
    }
  }

  checkUnlocatableValues() {
    let modalData: object;

    if (this.unlocatableClicked?.unlocatableVerified && this.unlocatableClicked?.unlocatable) {
      modalData = {
        header: 'Completion failed',
        message: 'Please select one unlocatable status',
      };

      this.dialog
        .open(ConfirmationModalComponent, {
          width: '380px',
          data: modalData,
          disableClose: true,
        })
        .afterClosed();
      return false;
    }
    return true;
  }

  checkUnlocatableVerifiedSetting() {
    let modalData: object;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const allowed: any = this.userService.isSettingActive(SettingID.ALLOW_COMPLETE_UNLOCATABLE_VERIFY);

    if (allowed != '1') {
      modalData = {
        header: 'Completion failed',
        message: 'You do not have permission to mark ticket as Unlocatable Verified',
      };

      this.dialog
        .open(ConfirmationModalComponent, {
          width: '380px',
          data: modalData,
          disableClose: true,
        })
        .afterClosed();
      return false;
    }
    return true;
  }

  async onMarkComplete(locateStatusID) {
    const check = this.checkUnlocatableValues();
    let docsAreUploaded = true;
    if (this.userService.isSettingActive(SettingID.CHECK_DOCUMENT_MANIFEST)) {
      docsAreUploaded = await this.ticketService.verifyManifest(
        this.assignmentID.toString(),
        this.primaryID.toString()
      );
    }
    if (!docsAreUploaded) {
      this.snackBarService.openSnackbar(
        'Ticket documents are still processing. Please sync when online and try again later.',
        SnackbarType.warning
      );
      return;
    }
    if (check) {
      if (this.unlocatableClicked?.unlocatable) {
        locateStatusID = LocateStatusID.LOCATE_UNLOCATABLE.toString();
      } else if (this.unlocatableClicked?.unlocatableVerified) {
        locateStatusID = LocateStatusID.LOCATE_UNLOCATABLE_VERIFIED.toString();
      }
      let modalData: object;
      if (locateStatusID == '24') {
        modalData = {
          header: 'mark as ongoing',
          message: 'Are you sure you want to mark this ticket as ongoing?',
        };

        this.dialog
          .open(ConfirmationModalComponent, {
            width: '380px',
            data: modalData,
          })
          .afterClosed()
          .subscribe((nextVal) => {
            if (nextVal) {
              this.MarkAsCompleteEvent.emit({
                locateStatusID,
              });
            }
          });
      } else if (locateStatusID == LocateStatusID.LOCATE_COMPLETED.toString()) {
        modalData = {
          header: 'complete ticket',
          message: 'Are you sure you want to complete this ticket?',
        };

        this.dialog
          .open(ConfirmationModalComponent, {
            width: '380px',
            data: modalData,
            disableClose: true,
          })
          .afterClosed()
          .subscribe((nextVal) => {
            if (nextVal) {
              this.MarkAsCompleteEvent.emit({
                locateStatusID,
              });
            }
          });
      } else if (locateStatusID == LocateStatusID.ON_HOLD.toString()) {
        modalData = {
          header: 'Mark as on hold',
          message: 'Are you sure you want to mark this ticket as on hold?',
        };

        this.dialog
          .open(ConfirmationModalComponent, {
            width: '380px',
            data: modalData,
            disableClose: true,
          })
          .afterClosed()
          .subscribe((nextVal) => {
            if (nextVal) {
              this.MarkAsCompleteEvent.emit({
                locateStatusID,
              });
            }
          });
          
      } else if (locateStatusID == LocateStatusID.READY_FOR_DISPATCH.toString()) {
          modalData = {
            header: 'Mark as not clear',
            message: 'Are you sure you want to mark this ticket as not clear?',
          };

          this.dialog
            .open(ConfirmationModalComponent, {
              width: '380px',
              data: modalData,
              disableClose: true,
            })
            .afterClosed()
            .subscribe((nextVal) => {
              if (nextVal) {
                this.MarkAsCompleteEvent.emit({
                  locateStatusID,
                });
              }
            });
            
      } else if (locateStatusID == LocateStatusID.LOCATE_UNLOCATABLE.toString()) {
        this.dialog
          .open(AutologExplanationModalComponent, {
            width: '380px',
            data: { header: 'Unlocatable Explanation' },
          })
          .afterClosed()
          .subscribe((nextVal) => {
            if (nextVal) {
              this.MarkAsCompleteEvent.emit({
                locateStatusID,
                autolog: nextVal.result,
              });
            }
          });
      } else if (locateStatusID == LocateStatusID.LOCATE_UNLOCATABLE_VERIFIED.toString()) {
        const settingCheck = this.checkUnlocatableVerifiedSetting();
        if (settingCheck) {
          this.dialog
            .open(AutologExplanationModalComponent, {
              width: '380px',
              data: { header: 'Unlocatable Verified Explanation' },
            })
            .afterClosed()
            .subscribe((nextVal) => {
              if (nextVal) {
                this.MarkAsCompleteEvent.emit({
                  locateStatusID,
                  autolog: nextVal.result,
                });
              }
            });
        }
      } else {
        this.dialog
          .open(AutologExplanationModalComponent, {
            width: '380px',
            data: { header: 'Assistance Needed Explanation' },
          })
          .afterClosed()
          .subscribe((nextVal) => {
            if (nextVal) {
              this.MarkAsCompleteEvent.emit({
                locateStatusID,
                autolog: nextVal.result,
              });
            }
          });
      }
    }
  }

  markAssitanceNeeded = () => {
    this.onMarkComplete('26');
  };

  markOnHold = () => {
    this.onMarkComplete('21');
  };

  markNotClear = () => {
    this.onMarkComplete('15');
  };

  markOngoing = () => {
    this.onMarkComplete('24');
  };

  markComplete = () => {
    this.onMarkComplete('20');
  };

  markUnlocatable = () => {
    this.onMarkComplete('32');
  };

  onSwipe(event) {
    this.Swipe.emit(event);
  }
}
