import { ESRIMapServerExportResponse } from '~lib/types/Esri';

export type DatabaseLayer = {
  layerServiceID: number;
  layerServiceName: string;
  subLayerID: number | null;
  subLayerName: string | null;
  layerURL: string;
  serviceType: ServiceType;
  layerType: LayerType;
  isEditable: number;
  isDefaultOn: number;
  parentGroupID: number;
  serviceSubLayerID: number;
  isLayerEditable: boolean;
  displayName: string;
  index: number;
  utilityID: number | null;
  utilityName: string | null;
  username: string | null;
  password: string | null;
  tokenURL: string | null;
  token?: string;
  subLayers?: DatabaseLayer[];
  esriLayerDefinition?: ESRIMapServerExportResponse;
  config?: LayerConfig;
};

export interface LayerConfig {
  showLabels: boolean;
  isVisible: boolean;
}

export interface DatabaseLayerGroup {
  groupID: number;
  groupName: string;
  parentGroupID: number;
  index: number;
  utilityID: number | null;
}

export type DigSiteShape = {
  graphicID: number;
  type: number;
  coordinates: {
    lat: number;
    lng: number;
  }[];
};

export enum ServiceType {
  EsriMapServer = 1,
  WMSLayerGeoServer,
  EsriFeatureServer,
  EsriBasemap,
  EsriWFSLayer,
  EsriTileLayer,
  GeoserverWFSLayer,
  TileLayer,
  ImageLayer,
  GroupLayer,
  EsriMapServerSubLayer,
  WMSLayerGeoServerSubLayer,
  EsriFeatureServerSubLayer,
}

export enum LayerType {
  GROUP = 'GROUP',
  FEATURE = 'FEATURE',
}

export enum GlobalSubLayers {
  BasemapShapes = 0,
  LocateAreas = 1
}

export type MapLayerControllerResponse = {
  message: string;
  error: string;
  data: MapLayerControllerResponseData;
};

export type MapLayerControllerResponseData = {
  layers: DatabaseLayer[];
  groups: DatabaseLayerGroup[];
};
