import { Injectable } from '@angular/core';
import { COMPLETION_TABLE_NAMES } from 'src/app/modules/core/admin/tables';
import {
  AssignmentIDCacheResult,
  BaseAdminCacheService,
  BaseCompletionCacheService,
  CacheWhereClause,
  CacheWhereClauseType,
  Table,
} from "src/app/modules/core/cache/cache.interface";
import { DatetimeService } from "src/app/modules/core/services/datetime/datetime.service";
import { AutologID } from "src/app/modules/core/services/user/setting";
import { UserService } from "src/app/modules/core/services/user/user.service";
import { safeAwait } from "src/app/modules/core/validators/validator";
import { LocateStatusID } from "../../../ticket-details/ticket-details.module";
import { AutologRow } from "../../../ticket-details/ticket-details/autologs/ticket-autolog";

import {
  CompletionsVerifyResult,
  UtilocateCompletionsVerify,
  VerifyDetails,
  VerifyDetailsType,
} from './utilocate-completions-verify';

@Injectable({
  providedIn: 'root',
})
export class UtilocateCompletionCacheService {
  //this is the storage location we're going to use 

  constructor(
    private datetimeService: DatetimeService,
    private baseCompletionsCacheService: BaseCompletionCacheService,
    private baseAdminCacheService: BaseAdminCacheService,
    private userService: UserService
  ) { }

  /**
   * Queries a desired table 
   * @param {string} tablename 
   * @param {CacheWhereClause} where where claus
   * @param {boolean} withColumns 
   * @param {string} storageIndex default assignments
   * @returns 
   */
  async queryTable(
    tablename: string,
    where?: CacheWhereClause[],
    withColumns?: boolean,
    assignmentID?: string
  ) {
    const result: object[] | Error =
      await this.baseCompletionsCacheService.queryTableData(
        tablename,
        where,
        withColumns,
        assignmentID
      );
    if (!(result instanceof Error)) {
      return result;
    } else {
      throw result;
    }
  }

  /**
 * Returns all info from local db for an assignmentID 
 * @param {string} storageIndex default assignments
 * @returns 
 */
  async queryAssignmentID(assignmentID: string): Promise<AssignmentIDCacheResult> {
    const result = await this.baseCompletionsCacheService.getKey(assignmentID);
    if (result === null) return result;


    const tables: Table[] = [];
    for (const tableKey of Object.keys(result.tables)) {
      const table = new Table(result.tables[tableKey].name, result.tables[tableKey].Columns, result.tables[tableKey].Data);
      tables.push(table);
    }
    return new AssignmentIDCacheResult(result.assigned, result.ticketChanged, result.insertTime, tables);
  }

  /**
   * Sets value for a specified assignmentID in IDB 
   * @param {string} assignmentID 
   * @param {any} value 
   * @returns 
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async setAssignmentID(assignmentID: string, value: any) {
    return this.baseCompletionsCacheService.setKey(assignmentID, value);
  }

  /**
   * Lists all keys in the db 
   * @returns 
   */
  async listKeys() {
    return this.baseCompletionsCacheService.listKeys();
  }

  /**
   * Removes a key from the db 
   * @param key 
   * @returns 
   */
  async removeKey(key: string) {
    return this.baseCompletionsCacheService.removeKey(key);
  }

  async queryTableDataByColumns(
    tablename: string,
    columns: string[],
    where: CacheWhereClause[] = [],
    assignmentID: string
  ): Promise<object[] | Error> {
    return this.baseCompletionsCacheService.queryTableDataByColumns(
      tablename,
      columns,
      where,
      assignmentID
    );
  }

  async getTicketPrimaryTableIDs(
    tablename: string,
    column: string,
    primaryID: string,
    assignmentID: string,
  ) {
    const whereClause: CacheWhereClause = {
      Column: "PrimaryID",
      Value: primaryID,
      ValueType: CacheWhereClauseType.NUMBER,
    };
    const result = await this.baseCompletionsCacheService.queryTableDataByColumns(
      tablename,
      [column],
      [whereClause],
      assignmentID
    );
    if (!(result instanceof Error)) {
      let formattedIDs = [];
      for (const row of result) {
        formattedIDs.push(row[column]);
      }
      formattedIDs = [...new Set([...formattedIDs])];
      return formattedIDs;
    } else {
      throw result;
    }
  }

  async queryTicketPrimaryUtilityIDs(primaryID: string, assignmentID: string) {
    const whereClause: CacheWhereClause = {
      Column: "PrimaryID",
      Value: primaryID,
      ValueType: CacheWhereClauseType.NUMBER,
    };
    const result: object[] | Error =
      await this.baseCompletionsCacheService.queryTableDataByColumns(
        COMPLETION_TABLE_NAMES.tbCompletions_AuxiliaryDetails,
        ["UtilityID"],
        [whereClause],
        assignmentID
      );
    if (!(result instanceof Error)) {
      let formattedUtiltyIDs = [];
      for (const row of result) {
        formattedUtiltyIDs.push(row["UtilityID"]);
      }
      formattedUtiltyIDs = [...new Set([...formattedUtiltyIDs])];
      return formattedUtiltyIDs;
    } else {
      throw result;
    }
  }

  async queryTicketPrimaryBillingCategories(primaryID: string, assignmentID: string) {
    const utilityAuxDetailRows: object[] | Error =
      await this.getTicketPrimaryTableIDs(
        COMPLETION_TABLE_NAMES.tbCompletions_AuxiliaryDetails,
        "AuxiliaryDetailID",
        primaryID,
        assignmentID
      );
    if (!(utilityAuxDetailRows instanceof Error)) {
      const utilityBillingCatRowsWC: CacheWhereClause = {
        Column: "AuxiliaryDetailID",
        Value: utilityAuxDetailRows,
        ValueType: CacheWhereClauseType.ARRAY,
      };
      const utilityBillingCatRows: object[] | Error =
        await this.queryTableDataByColumns(
          COMPLETION_TABLE_NAMES.tbCompletions_Billing,
          ["UtilityBillingCatID"],
          [utilityBillingCatRowsWC],
          assignmentID
        );
      if (!(utilityBillingCatRows instanceof Error)) {
        let formattedBillingCatIDs = [];
        for (const row of utilityBillingCatRows) {
          formattedBillingCatIDs.push(row["UtilityBillingCatID"]);
        }
        formattedBillingCatIDs = [...new Set([...formattedBillingCatIDs])];
        return formattedBillingCatIDs;
      } else {
        throw utilityBillingCatRows;
      }
    } else {
      throw utilityAuxDetailRows;
    }
  }

  /**
   * Updates table data 
   * @param {string} tablename 
   * @param {object} setClause 
   * @param {CacheWhereClause} where 
   * @param {string} assignmentID
   * @returns 
   */
  updateTableData(
    tablename: string,
    setClause: object,
    where: CacheWhereClause[],
    assignmentID: string

  ) {
    return this.baseCompletionsCacheService.updateTableData(
      tablename,
      setClause,
      where,
      assignmentID
    );
  }

  insertTableData(
    tableName: string,
    tableData: unknown[],
    assignmentID: string,
    columns?: unknown[],
  ) {

    return this.baseCompletionsCacheService.insertTableData(
      COMPLETION_TABLE_NAMES[tableName],
      tableData,
      columns,
      assignmentID
    );
  }

  //! completing tickets
  async verifyAuditTicketBeforeComplete(
    primaryID: string,
    utilityID: string,
    assignmentID: string
  ): Promise<VerifyDetails[] | Error> {
    const verifyAuditResult = [];
    const utilocateVerify = new UtilocateCompletionsVerify(
      this.baseCompletionsCacheService,
      this.baseAdminCacheService
    );
    const results: CompletionsVerifyResult[] | Error =
      await utilocateVerify.onlyOneSelectedAudit(primaryID, utilityID, this.baseCompletionsCacheService, assignmentID);

    if (!(results instanceof Error)) {
      const errorMap = {};
      for (let i = 0; i < results.length; i++) {
        const result: CompletionsVerifyResult = results[i];
        if (!result.HasPassed) {
          if (!errorMap[result.FailedID]) {
            errorMap[result.FailedID] = '';
          }
          errorMap[result.FailedID] = result.FailedReason;
        }
      }

      if (Object.keys(errorMap).length > 0) {
        verifyAuditResult.push(new VerifyDetails(VerifyDetailsType.PrimaryDetails, errorMap));
      }

      return verifyAuditResult;
    } else {
      return results;
    }
  }

  async verifyTicketBeforeComplete(
    primaryID: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tbAdmin_Utilities: any[],
    assignmentID: string,
    callTypeID: string
  ): Promise<VerifyDetails[] | Error> {
    const verifyDetailResults = [];
    //instantiate the verfiy object with the correct cache service 
    const utilocateVerify = new UtilocateCompletionsVerify(
      this.baseCompletionsCacheService,
      this.baseAdminCacheService
    );

    const [tbCompletions_Primary, err] = await safeAwait(
      this.queryTable(COMPLETION_TABLE_NAMES.tbCompletions_Primary, [
        {
          Column: 'PrimaryID',
          Value: primaryID,
          ValueType: CacheWhereClauseType.NUMBER,
        },
      ], false, assignmentID)
    );
    if (!err) {
      const data = tbCompletions_Primary[0];
      if (
        data &&
        (data['TimeIn'] == null || data['TimeIn'] == '') &&
        (data['TimeOut'] == null || data['TimeIn'] == '')
      ) {
        verifyDetailResults.push(
          new VerifyDetails(VerifyDetailsType.Primary, {
            [primaryID]: 'TimeIn / TimeOut not completed',
          })
        );
      }
    }

    for (let i = 0; i < tbAdmin_Utilities.length; i++) {
      const Utility = tbAdmin_Utilities[i];
      const { UtilityID } = Utility;

      const [onlyOneSelectedResult, error] = await safeAwait(
        utilocateVerify.onlyOneSelectedManual(primaryID, UtilityID, assignmentID)
      );

      const [callTypeFieldCheckedResult, error4] = await safeAwait(
        utilocateVerify.callTypeFieldsChecked(primaryID, UtilityID, assignmentID, callTypeID)
      );

      const [oneCategorySelectedResult, error14] = await safeAwait(
        utilocateVerify.oneCategorySelected(primaryID, UtilityID, assignmentID)
      );

      const [moreThanOneLessThanNPrimaryDetails, error105] = await safeAwait(
        utilocateVerify.moreThanOneLessThanNPrimaryDetailsSelected(
          primaryID,
          UtilityID,
          assignmentID
        )
      );

      const [VerifyIfFilledThenAnotherFilled, error108] = await safeAwait(
        utilocateVerify.VerifyIfFilledThenAnotherFilled(
          primaryID,
          UtilityID,
          assignmentID
        )
      );

      const [onlyOnePrimaryDetailSelected, error100] = await safeAwait(
        utilocateVerify.onlyOnePrimaryDetailSelected(
          primaryID,
          UtilityID,
          assignmentID
        )
      );

      const [verifyNoOverlappingPrimaryDetails, error106] = await safeAwait(
        utilocateVerify.verifyNoOverlappingPrimaryDetails(
          primaryID,
          UtilityID,
          assignmentID
        )
      );

      const [VerifyIfFilledNExceptionsFilled, error109] = await safeAwait(
        utilocateVerify.VerifyIfFilledNExceptionsFilled(
          primaryID,
          UtilityID,
          assignmentID
        )
      );

      if (!error) {
        if (!onlyOneSelectedResult.HasPassed) {
          verifyDetailResults.push(
            new VerifyDetails(VerifyDetailsType.Billing, {
              [onlyOneSelectedResult.FailedID]: onlyOneSelectedResult.FailedReason,
            })
          );
        }
      } else {
        return error;
      }

      if (!error4) {
        const map = {};
        for (let j = 0; j < callTypeFieldCheckedResult.length; j++) {
          const result: CompletionsVerifyResult =
            callTypeFieldCheckedResult[j];
          if (!result.HasPassed) {
            if (!map[result.FailedID]) {
              map[result.FailedID] = '';
            }
            map[result.FailedID] = result.FailedReason;
          }
        }

        if (Object.keys(map).length > 0) {
          verifyDetailResults.push(new VerifyDetails(VerifyDetailsType.Billing, map));
        }
      } else {
        return error4;
      }

      if (!error14) {
        const map = {};
        for (let j = 0; j < oneCategorySelectedResult.length; j++) {
          const result: CompletionsVerifyResult =
            oneCategorySelectedResult[j];
          if (!result.HasPassed) {
            if (!map[result.FailedID]) {
              map[result.FailedID] = '';
            }
            map[result.FailedID] = result.FailedReason;
          }
        }

        if (Object.keys(map).length > 0) {
          verifyDetailResults.push(new VerifyDetails(VerifyDetailsType.Billing, map));
        }
      } else {
        return error14;
      }

      if (!error105) {
        const map = {};
        for (let j = 0; j < moreThanOneLessThanNPrimaryDetails.length; j++) {
          const result: CompletionsVerifyResult =
            moreThanOneLessThanNPrimaryDetails[j];
          if (!result.HasPassed) {
            if (!map[result.FailedID]) {
              map[result.FailedID] = '';
            }
            map[result.FailedID] = result.FailedReason;
          }
        }

        if (Object.keys(map).length > 0) {
          verifyDetailResults.push(new VerifyDetails(VerifyDetailsType.PrimaryDetails, map));
        }
      } else {
        return error105;
      }

      if (!error108) {
        const map = {};
        for (let j = 0; j < VerifyIfFilledThenAnotherFilled.length; j++) {
          const result: CompletionsVerifyResult =
            VerifyIfFilledThenAnotherFilled[j];
          if (!result.HasPassed) {
            if (!map[result.FailedID]) {
              map[result.FailedID] = '';
            }
            map[result.FailedID] = result.FailedReason;
          }
        }

        if (Object.keys(map).length > 0) {
          verifyDetailResults.push(new VerifyDetails(VerifyDetailsType.PrimaryDetails, map));
        }
      } else {
        return error108;
      }

      if (!error100) {
        const map = {};
        for (let j = 0; j < onlyOnePrimaryDetailSelected.length; j++) {
          const result: CompletionsVerifyResult =
            onlyOnePrimaryDetailSelected[j];
          if (!result.HasPassed) {
            if (!map[result.FailedID]) {
              map[result.FailedID] = '';
            }
            map[result.FailedID] = result.FailedReason;
          }
        }

        if (Object.keys(map).length > 0) {
          verifyDetailResults.push(new VerifyDetails(VerifyDetailsType.PrimaryDetails, map));
        }
      } else {
        return error100;
      }

      if (!error106) {
        const map = {};
        for (let j = 0; j < verifyNoOverlappingPrimaryDetails.length; j++) {
          const result: CompletionsVerifyResult =
            verifyNoOverlappingPrimaryDetails[j];
          if (!result.HasPassed) {
            if (!map[result.FailedID]) {
              map[result.FailedID] = '';
            }
            map[result.FailedID] = result.FailedReason;
          }
        }

        if (Object.keys(map).length > 0) {
          verifyDetailResults.push(new VerifyDetails(VerifyDetailsType.PrimaryDetails, map));
        }
      } else {
        return error106;
      }

      if (!error109) {
        const map = {};
        for (let j = 0; j < VerifyIfFilledNExceptionsFilled.length; j++) {
          const result: CompletionsVerifyResult =
            VerifyIfFilledNExceptionsFilled[j];
          if (!result.HasPassed) {
            if (!map[result.FailedID]) {
              map[result.FailedID] = '';
            }
            map[result.FailedID] = result.FailedReason;
          }
        }

        if (Object.keys(map).length > 0) {
          verifyDetailResults.push(new VerifyDetails(VerifyDetailsType.PrimaryDetails, map));
        }
      } else {
        return error109;
      }
    }
    return verifyDetailResults;
  }

  async verifyDamageInvestigationBeforeComplete(
    primaryID: string,
    utilityID: string
  ) {
    const verifyDamangeInvestigationResult = [];
    const utilocateVerify = new UtilocateCompletionsVerify(
      this.baseCompletionsCacheService,
      this.baseAdminCacheService
    );

    const results: CompletionsVerifyResult[] | Error =
      await utilocateVerify.onlyOneSelectedDamageInvestigation(
        primaryID,
        utilityID
      );
    if (!(results instanceof Error)) {
      const errorMap: object = {};
      for (let i = 0; i < results.length; i++) {
        const result: CompletionsVerifyResult = results[i];
        if (!result.HasPassed) {
          if (!errorMap[result.FailedID]) {
            errorMap[result.FailedID] = '';
          }
          errorMap[result.FailedID] = result.FailedReason;
        }
      }

      if (Object.keys(errorMap).length > 0) {
        verifyDamangeInvestigationResult.push(new VerifyDetails(VerifyDetailsType.PrimaryDetails, errorMap));
      }

      return verifyDamangeInvestigationResult;
    } else {
      return results;
    }
  }

  async markTicketAsAssistanceNeeded(assignmentID: string, userAutologExplaination: string): Promise<boolean> {
    try {
      const whereClause: CacheWhereClause = {
        Column: "AssignmentID",
        Value: assignmentID,
      };

      // update tbCompletions_Assignments
      const assignmentSetClause: object = {
        LocateStatusID: LocateStatusID.ASSISTANCE_NEEDED,
      };
      await this.baseCompletionsCacheService.updateTableData(
        COMPLETION_TABLE_NAMES.tbCompletions_Assignments,
        assignmentSetClause,
        [whereClause],
        assignmentID
      );

      const autolog = new AutologRow(
        assignmentID,
        this.userService.getUserID(),
        AutologID.ASSISTANCE_NEEDED,
        userAutologExplaination,
        this.datetimeService.localDateToDBDateStr(new Date())
      );
      await this.addAutologToAssignment(autolog, assignmentID);

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async markTicketAsLocateStatusID(
    assignmentID: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    locateStatusID: any,
    userAutologExplaination?: string
  ) {
    // console.log(assignmentID, locateStatusID, userAutologExplaination);
    let verifiedAutolog = false
    if (locateStatusID == 38) {
      locateStatusID = 20
      verifiedAutolog = true
    }
    console.log(locateStatusID, verifiedAutolog);

    try {
      const whereClause: CacheWhereClause = {
        Column: "AssignmentID",
        Value: assignmentID,
      };

      // update tbCompletions_Primary
      const primarySetClause: object = {
        CompletingLocatorID: this.userService.getUserID(),
        DateCompleted: this.datetimeService.localDateToDBDateStr(new Date()),
        Archived: 1
      };
      await this.baseCompletionsCacheService.updateTableData(
        COMPLETION_TABLE_NAMES.tbCompletions_Primary,
        primarySetClause,
        [whereClause],
        assignmentID
      );

      // update tbCompletions_Assignments
      const assignmentSetClause: object = {
        LocateStatusID: locateStatusID,
      };
      await this.baseCompletionsCacheService.updateTableData(
        COMPLETION_TABLE_NAMES.tbCompletions_Assignments,
        assignmentSetClause,
        [whereClause],
        assignmentID
      );

      // create autolog
      let autologExplaination: string =
        "Ticket completed using Utilocate Web App";
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let autologDescID: any = AutologID.MarkedAsCompleted;
      if (locateStatusID == LocateStatusID.LOCATE_ON_GOING) {
        autologExplaination =
          "Ticket completed and marked as ongoing using Utilocate Web App";
        autologDescID = AutologID.LocateOngoing;
      } else if (locateStatusID == LocateStatusID.LOCATE_UNLOCATABLE) {
        autologDescID = AutologID.Unlocatable;
        autologExplaination = userAutologExplaination;
      }
      else if (verifiedAutolog) {
        autologDescID = AutologID.UnlocatableVerified
        autologExplaination = userAutologExplaination;
      }
      else if (locateStatusID == LocateStatusID.ON_HOLD) {
        autologDescID = AutologID.OnHold
        autologExplaination =  "Ticket marked as on hold using Utilocate Web App";;
      }
      else if (locateStatusID == LocateStatusID.READY_FOR_DISPATCH) {
        autologDescID = AutologID.NotClear
        autologExplaination =  "Ticket marked as not clear using Utilocate Web App";;
      }


      const autolog = new AutologRow(
        assignmentID,
        this.userService.getUserID(),
        autologDescID,
        autologExplaination,
        this.datetimeService.localDateToDBDateStr(new Date())
      );
      await this.addAutologToAssignment(autolog, assignmentID);

      // finish
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }


  //! extra
  async addAutologToAssignment(autologRow: AutologRow, assignmentID: string) {
    try {
      // insert rows to table data
      await this.baseCompletionsCacheService.insertTableDataRow(
        COMPLETION_TABLE_NAMES.tbCompletions_Autolog,
        autologRow,
        assignmentID
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  async removeDocumentHash(fileName, assignmentID: string) {
    try {
      // insert rows to table data
      await this.baseCompletionsCacheService.removeTableDataRowHash(
        COMPLETION_TABLE_NAMES.tbSync_ExpectedDocumentHash,
        fileName,
        assignmentID
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  async removeDocumentToAssignment(event, assignmentID: string) {
    try {
      // remove the file itself from local table
      await this.baseCompletionsCacheService.removeTableDataRow(
        COMPLETION_TABLE_NAMES.tbCompletions_Documents,
        event.DocumentID,
        assignmentID,
        false
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  async removeS3DocumentToAssignment(event, assignmentID: string) {
    try {
      // remove the file itself from local table
      await this.baseCompletionsCacheService.removeTableDataRow(
        COMPLETION_TABLE_NAMES.tbCompletions_S3Documents,
        event.DocumentID,
        assignmentID,
        true
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Adds a document to an assignment object in the idb 
   * @param documentRow 
   * @param assignmentID 
   * @returns {boolean} success
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async addDocumentToAssignment(documentRow: any, assignmentID: string) {
    try {
      // insert rows to table data
      await this.baseCompletionsCacheService.insertTableDataRow(
        COMPLETION_TABLE_NAMES.tbCompletions_Documents,
        documentRow,
        assignmentID
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
 * Adds a document hash to the table 
 * @param expectedDocumentHashRow 
 * @param assignmentID 
 * @returns {boolean} success
 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async addDocumentHashToExpectedDocHash(expectedDocumentHashRow: any, assignmentID: string) {
    try {
      await this.baseCompletionsCacheService.insertTableDataRow(
        COMPLETION_TABLE_NAMES.tbSync_ExpectedDocumentHash,
        expectedDocumentHashRow,
        assignmentID
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
 * Adds an S3document to an assignment object in the idb 
 * @param documentRow 
 * @param assignmentID 
 * @returns {boolean} success
 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async addS3DocumentToAssignment(documentRow: any, assignmentID: string) {
    try {
      // insert rows to table data
      await this.baseCompletionsCacheService.insertTableDataRow(
        COMPLETION_TABLE_NAMES.tbCompletions_S3Documents,
        documentRow,
        assignmentID
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  async addTicketTagsToAssignment(ticketTagRow: any, assignmentID: string) {
    try {
      // insert rows to table data
      await this.baseCompletionsCacheService.insertTableData(
        COMPLETION_TABLE_NAMES.tbCompletions_AssignmentToTags,
        ticketTagRow,
        null,
        assignmentID
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  async addAuxiliaryDetails(primaryID: string, utilityID, bFieldAdded, AuxiliaryDetailID, assignmentID: string) {
    try {
      // insert rows to table data
      await this.baseCompletionsCacheService.insertTableDataRow(
        COMPLETION_TABLE_NAMES.tbCompletions_AuxiliaryDetails,
        { PrimaryID: primaryID, UtilityID: utilityID, bFieldAdded: bFieldAdded, AuxiliaryDetailID: AuxiliaryDetailID, bFieldRemoved: 0, hasResponded: 0, isBillingAdjustment: 0, isInvoiced: 0 }
        , assignmentID
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  async removeAuxiliaryDetails(AuxiliaryDetailRow, assignmentID: string) {
    try {
      // remove the row 
      const whereClause: CacheWhereClause = {
        Column: "AuxiliaryDetailID",
        Value: AuxiliaryDetailRow.AuxiliaryDetailID,
        ValueType: CacheWhereClauseType.NUMBER,
      };

      //we aren't actually removing, we're setting it to delete so the UploadTicket lambda will handle the rest 
      await this.baseCompletionsCacheService.updateTableData(
        COMPLETION_TABLE_NAMES.tbCompletions_AuxiliaryDetails,
        { bFieldRemoved: 1 },
        [whereClause],
        assignmentID.toString()
      );
      console.log("Removed AuxiliaryDetailID: " + AuxiliaryDetailRow.AuxiliaryDetailID);

      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Adds an entry to tbCompletions_Billing 
   * @param BillingID 
   * @param auxiliaryDetailID 
   * @param utilityBillingCatID 
   * @param bFieldAdded 
   * @param fieldValue 
   * @returns 
   */
  async addBilling(BillingID, auxiliaryDetailID, utilityBillingCatID, bFieldAdded = 1, fieldValue, assignmentID: string) {
    try {
      // insert rows to table data
      await this.baseCompletionsCacheService.insertTableDataRow(
        COMPLETION_TABLE_NAMES.tbCompletions_Billing,
        { BillingID: BillingID, AuxiliaryDetailID: auxiliaryDetailID, UtilityBillingCatID: utilityBillingCatID, bFieldAdded: bFieldAdded, FieldValue: fieldValue, isUpdated: 1 }
        , assignmentID
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Removes an entry from tbCompletions_Billing
   * @param auxiliaryDetailRow 
   * @returns 
   */
  async removeBilling(auxiliaryDetailRow, assignmentID: string) {
    try {
      // remove the row
      await this.baseCompletionsCacheService.removeTableDataRowByWhereClaus(
        COMPLETION_TABLE_NAMES.tbCompletions_Billing, { AuxiliaryDetailID: auxiliaryDetailRow.AuxiliaryDetailID }, assignmentID
      );

      const result = await this.baseCompletionsCacheService.queryTableData(COMPLETION_TABLE_NAMES.tbCompletions_Billing, [], false, assignmentID);
      console.log(result);

      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Adds an entry to tbCompletions_AssignmentPolygons
   * @param assignmentID 
   * @param polygonCode 
   */
  async addAssignmentPolygon(assignmentID: string, polygonCode: string) {
    await this.baseCompletionsCacheService.insertTableDataRow(
      COMPLETION_TABLE_NAMES.tbCompletions_AssignmentPolygons,
      { AssignmentID: Number(assignmentID), PolygonCode: polygonCode },
      assignmentID
    );
  }

  /**
   * Removes an entry from tbCompletions_AssignmentPolygons
   * @param AssignmentID 
   * @param polygonCode 
   */
  async removeAssignmentPolygonRow(assignmentID: string, polygonCode: string) {
    await this.baseCompletionsCacheService.removeTableDataRowByWhereClaus(
      COMPLETION_TABLE_NAMES.tbCompletions_AssignmentPolygons, { AssignmentID: Number(assignmentID), PolygonCode: polygonCode }, assignmentID
    );
  }

  /**
   * Adds an entry to tbCompletions_PrimaryDetails
   * @param primaryID 
   * @param PrimaryDetailsFieldID 
   * @param FieldValue 
   * @param bFieldAdded 
   * @returns 
   */
  async addPrimaryDetails(primaryID, PrimaryDetailsFieldID, FieldValue, bFieldAdded, assignmentID: string) {
    try {
      // insert rows to table data
      await this.baseCompletionsCacheService.insertTableDataRow(
        COMPLETION_TABLE_NAMES.tbCompletions_PrimaryDetails,
        { PrimaryID: primaryID, PrimaryDetailsFieldID: PrimaryDetailsFieldID, FieldValue: FieldValue, bFieldAdded: bFieldAdded },
        assignmentID
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  async clear() {
    this.baseCompletionsCacheService.clear();
  }

  async clearKeys(keys: string[]) {

    //get the local keys 
    const localKeys = await this.baseCompletionsCacheService.listKeys();

    //iterate over the input keys and local keys 
    for (const key of keys) {
      for (const localKey of localKeys) {
        if (localKey.includes(key)) { //if we found an input key that matches a local key 
          await this.baseCompletionsCacheService.removeKey(localKey); //delete that key 
          // ^ We need to use the local key we got from listKeys instead of a random string for some reason 
          try {
            const result = await this.baseCompletionsCacheService.getKey(localKey);
            if (result) {
              console.log("key still exists: " + localKey);
            }
          } catch (error) {
            console.error("error getting key: " + localKey, error);
          }
          continue;
        }
      }

    }
  }
}
