import { LayerManagerService } from 'src/app/modules/drawing-module/services/layer-manager/layer-manager.service';
import { inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import {
  asyncScheduler,
  BehaviorSubject,
  distinctUntilChanged,
  EMPTY,
  firstValueFrom,
  from,
  fromEvent,
  merge,
  Observable,
  of,
  Subject,
} from 'rxjs';
import { isEqual } from 'lodash-es';
import {
  catchError,
  combineLatestWith,
  debounceTime,
  filter,
  finalize,
  map,
  switchMap,
  takeUntil,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { Collection, Feature, Map, MapBrowserEvent, Overlay, View } from 'ol';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Heatmap as HeatmapLayer, VectorImage } from 'ol/layer';
import { DragBox, Draw, Modify, Select, Snap } from 'ol/interaction';
import LayerGroup from 'ol/layer/Group';
import { StyleToolbox } from '../../../../../modules/drawing-module/classes/style-toolbox';
import Style, { StyleFunction, StyleLike } from 'ol/style/Style';
import { Circle as CircleStyle, Fill, Stroke, Text } from 'ol/style';
import Layer from 'ol/layer/Layer';
import {
  DispatchAreaPoint,
  DispatchAreaService,
  DispatchLayer,
} from '../../../../services/dispatch-area/dispatch-area.service';
import { Circle, LineString, Point, Polygon } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import { SelectEvent } from 'ol/interaction/Select';
import { SnackbarType } from '../../../../../modules/shared/snackbar/snackbar/snackbar';
import { DispatchAreaEditorService } from '../dispatch-area-editor/dispatch-area-editor.service';
import { SnackbarService } from '../../../../../modules/shared/snackbar/snackbar.service';
import { getCenter, getWidth } from 'ol/extent';
import { Cluster, Source } from 'ol/source';
import { ADMIN_TABLE_NAMES } from '../../../../../modules/core/admin/tables';
import { Digsite, DigsiteAreaService } from '../../../../services/digsite-area/digsite-area.service';
import Icon from 'ol/style/Icon';
import { Ticket } from '../../../../../modules/shared/ticket/ticket.service';
import { AdminLookupService } from '../../../../../modules/core/admin/admin-lookup.service';
import CallTypeColours from './utils/CallTypeColours';
import { UsersService } from 'src/app/shared/services/users/users.service';
import { sections } from './ticket-pin-legend/content';
import { LocateStatusID } from '../../../../../modules/shared/ticket-details/ticket-details.module';
import { LocationService } from 'src/app/shared/services/location/location.service';
import { getLength } from 'ol/sphere';
import { defaults } from 'ol/interaction/defaults';
import { FeatureInspectorService } from 'src/app/modules/drawing-module/services/feature-inspector.service';
import { Coordinate } from 'ol/coordinate';
import BaseLayer from 'ol/layer/Base';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { SettingID } from 'src/app/modules/core/services/user/setting';

export type LocatorID = number;

@Injectable({
  providedIn: 'root',
})
export class TicketMapService implements OnDestroy {
  // services
  private locationService = inject(LocationService);
  private ngZone = inject(NgZone);
  private userService = inject(UsersService);
  private settingLookupService = inject(UserService);
  private snackBarService = inject(SnackbarService);
  private digSiteService = inject(DigsiteAreaService);
  private adminLookupService = inject(AdminLookupService);
  private dispatchAreaService = inject(DispatchAreaService);
  private dispatchAreaEditorService = inject(DispatchAreaEditorService);
  private layerManagerService = inject(LayerManagerService);
  private featuresInspectorService = inject(FeatureInspectorService);

  // signals and observables
  private destroy$ = new Subject<void>();
  private _menuSelection$ = new BehaviorSubject<Array<number>>([]);
  private _ticketSelection$ = new BehaviorSubject<Array<number>>([]);
  private _locatorOptions$: BehaviorSubject<Array<{ value: number; name: string }>> = new BehaviorSubject([]);
  private _dispatchAreasActive$ = new BehaviorSubject(true);
  private _ticketPinColourScheme$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _locatorCheckins = new BehaviorSubject<unknown>(null);
  private _inspectorData$ = new BehaviorSubject<unknown>([]);
  private _inspectorPosition$ = new BehaviorSubject<Coordinate>(null);

  // members
  private openTicketID: Ticket['AssignmentID'];
  protected _selectedTicket$: BehaviorSubject<Ticket> = new BehaviorSubject(null);
  protected _previewTicket$: BehaviorSubject<Ticket> = new BehaviorSubject(null);
  protected _hoveredLocator$: BehaviorSubject<[string, string]> = new BehaviorSubject(null); // locator name, last created date
  private _publicLayers$ = new BehaviorSubject<Array<BaseLayer>>([]);
  protected selectedLocatorID: LocatorID;
  private _dontFly = false;
  private showRouteOrderAbovePin = false;

  private devicePixelRatio = window.devicePixelRatio;

  // map
  private olMap: Map;
  private view: View;
  // layers
  private ticketSource: VectorSource<Feature<Point>>;
  private ticketCluster: Cluster;
  private ticketPinLayer: VectorImage<VectorSource>;
  private heatMapLayer: HeatmapLayer;
  private digSiteSource: VectorSource;
  private digSiteLayer: VectorLayer<VectorSource>;
  private locatorCheckinSource: VectorSource<Feature<Point>>;
  private locatorCheckinLayer: VectorLayer<VectorSource>;
  private _dispatchLayerGroup = new LayerGroup();
  private _clientLayerGroup = new LayerGroup({
    properties: {
      groupID: -1,
      groupName: 'Utility Layers',
      index: -1,
      parentGroupID: -1,
      utilityID: -1,
    },
  });

  // overlays
  protected ticketInspectorOverlay: Overlay;
  protected ticketPreviewOverlay: Overlay;
  protected locatorCheckinOverlay: Overlay;

  // dispatchAreaUtils
  dispatchAreaLayers = this._dispatchLayerGroup.getLayers().getArray() as Array<
    VectorLayer<VectorSource<Feature<Polygon>>>
  >;
  private selectedArea: Collection<Feature<Polygon>>;
  private dispatchAreaSelect: Select;
  private searchedAreas: Collection<Feature<Polygon>>;
  private areaSearchSelect: Select;

  // interactions etc
  private readonly defaultInteractions = defaults({
    doubleClickZoom: false,
    altShiftDragRotate: false,
    pinchRotate: false,
  });
  private readonly zoomLevel = window.screen.availWidth / document.documentElement.clientWidth;
  private _dragBox: DragBox;
  private _select: Select;
  private styleToolbox = new StyleToolbox();
  private showDispatchAreaColours: boolean = false;
  private dispatchStyle: StyleFunction = (feature) => {
    const { AlgorithmColour } = feature.getProperties()['props'];
    return new Style({
      fill: new Fill({
        color:
          AlgorithmColour && this.showDispatchAreaColours
            ? this.styleToolbox.hexToRGBA(AlgorithmColour, 40)
            : 'rgba(155,155,155,0.5)',
      }),
      stroke: new Stroke({
        color: AlgorithmColour && this.showDispatchAreaColours ? AlgorithmColour : 'rgba(0,0,0,1)',
        width: 2,
      }),
    });
  };
  private invisibleCircleStyle: Style = new Style({
    image: new CircleStyle({
      radius: 10,
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0)',
      }),
      stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0)',
        width: 2,
      }),
    }),
  });
  private ticketStyleCache: Record<string, Style> = {};
  private ticketPinImage = new Image();
  private ticketPinStyle: StyleFunction = (feature: Feature) => {
    const pinStyle = this.ticketStyleCache[feature.get('palette')[this._ticketPinColourScheme$.value]];
    // note: feature.get('ticket').RouteOrder may be NULL. In this case, the user does not have a route order. Show no text
    let pinNumber: string = this.showRouteOrderAbovePin ? feature.get('ticket')?.RouteOrder?.toString() : '';
    if (parseInt(pinNumber) < 0) pinNumber = '';
    if (pinNumber && pinNumber.length > 0) {
      pinNumber = (parseInt(pinNumber) + 1).toString();
    }

    if (pinStyle) {
      pinStyle.getText().setText(pinNumber); //set the pin text
      return [pinStyle, this.invisibleCircleStyle];
    } else {
      const newStyle = new Style({
        image: new Icon({
          crossOrigin: 'anonymous',
          color: feature.getProperties()['palette'][this._ticketPinColourScheme$.value],
          width: 32,
          height: 32,
          img: this.ticketPinImage,
          anchor: [0.5, 0.5],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
        }),
        text: new Text({
          font: '12px Calibri,sans-serif',
          fill: new Fill({ color: '#000' }),
          stroke: new Stroke({ color: '#fff', width: 3 }),
          text: pinNumber, //default the pin text
          offsetY: -20,
        }),
      });

      this.ticketStyleCache[feature.get('palette')[this._ticketPinColourScheme$.value]] = newStyle;

      return [newStyle, this.invisibleCircleStyle];
    }
  };

  private digsiteLayerStyleCache: Record<string, Style> = {};
  private digsiteStyleFunction = (feature) => {
    if (this.digsiteLayerStyleCache[feature.get('area').Colour]) {
      return this.digsiteLayerStyleCache[feature.get('area').Colour];
    } else {
      this.digsiteLayerStyleCache[feature.get('area').Colour] = new Style({
        stroke: new Stroke({
          color: '#000000',
          width: 2,
        }),
        fill: new Fill({
          color: feature.get('area').Colour,
        }),
      });
      return this.digsiteLayerStyleCache[feature.get('area').Colour];
    }
  };

  private locatorCheckinStyle(color = '#00000000') {
    return [
      new Style({
        image: new Icon({
          src: 'assets/custom-icons/map_worker.svg',
          scale: 0.35,
          opacity: 1,
          color: color,
          anchor: [0.5, 0.5],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
        }),
      }),
      this.invisibleCircleStyle,
    ];
  }

  constructor() {
    this.ticketPinImage.src = 'assets/custom-icons/pin_border.svg';
  }

  private reinitializeLocatorCheckinLayer(): void {
    // Clear existing features and re-fetch locator check-ins
    if (this.locatorCheckinSource) {
      this.locatorCheckinSource.clear();
    }
    if (this.userService.locatorCheckins) {
      this.userService.locatorCheckins$.pipe(takeUntil(this.destroy$)).subscribe((checkins) => {
        if (checkins) {
          checkins.forEach((checkin) => {
            const { Coords, ...props } = checkin;
            if (Array.isArray(Coords) && Coords[0]?.length === 2 && !isNaN(Coords[0][0])) {
              const feature = new Feature({
                geometry: new Point(fromLonLat(Coords[0].reverse())),
                ...props,
              });
              feature.setStyle(this.locatorCheckinStyle(props.HexColour));
              this.locatorCheckinSource.addFeature(feature);
            }
          });
        }
      });
    }
  }

  async initializeService() {
    this.setupMap();
    (await this.setupLayers()).forEach((layer) => this.olMap.addLayer(layer));
    this.setupTicketSelection();
    this.setupPointerEvents();

    //reinstantiate the layer, so that it will show
    this.reinitializeLocatorCheckinLayer();
    this._menuSelection$.pipe(takeUntil(this.destroy$)).subscribe((menuSelection) => {
      //close popups
      this.closeOverlays();

      if (this._dispatchLayerGroup) {
        this._dispatchLayerGroup.setVisible(menuSelection.includes(0));
      }
      if (this.digSiteLayer) {
        this.digSiteLayer.setVisible(menuSelection.includes(2));
      }
      if (this.heatMapLayer) {
        this.heatMapLayer.setVisible(menuSelection.includes(5));
      }
      if (this.ticketSource && this.ticketPinLayer) {
        this.ticketPinLayer.setVisible(menuSelection.includes(7));
      }
      if (this.locatorCheckinSource && this.locatorCheckinLayer) {
        this.locatorCheckinLayer.setVisible(menuSelection.includes(4));
      }
    });

    //Get the user categories table
    from(this.adminLookupService.getAdminTables([ADMIN_TABLE_NAMES.tbLogin_UserCategories]))
      .pipe(
        map(([{ Data: userCategoryTable }]) => {
          return userCategoryTable;
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      )
      .subscribe((userCategoryTable: any[]) => {
        //then, get the login table
        from(this.adminLookupService.getAdminTables([ADMIN_TABLE_NAMES.tbLogin_Users]))
          .pipe(
            map(([{ Data: userData }]) => {
              //format objects for the dropdown
              return userData.reduce((acc, x) => {
                const userOverrideValue = userCategoryTable.find((entry) => entry.UserCategoyID === x.UserCategoryID);
                if (x.Archived === 0) {
                  acc.push({
                    value: x.UserID,
                    //Ex:       John Smith - Manager
                    name: `${x.FirstName} ${x.LastName} - ${userOverrideValue.Title}`.trim(),
                  });
                }
                return acc;
              }, []);
            })
          )
          .subscribe((res) => {
            this._locatorOptions$.next(res);
          });
      });

    this._ticketPinColourScheme$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.triggerChangeForTicketSourceFeautureLayer();
    });
  }

  closeOverlays() {
    this.ticketInspectorOverlay.setPosition(undefined);
    this.locatorCheckinOverlay.setPosition(undefined);
    this.ticketPreviewOverlay.setPosition(undefined);
  }

  /**
   * Triggers change detection for the features in ticketSource
   *
   * @memberof TicketMapService
   */
  triggerChangeForTicketSourceFeautureLayer() {
    this.ticketSource.forEachFeature((feature) => {
      feature.changed();
    });
  }

  toggleDispatchLayerColours(value: boolean): void {
    this.showDispatchAreaColours = value;

    // Iterate through the features and trigger the style update
    this._dispatchLayerGroup.getLayersArray().forEach((layer: VectorLayer<VectorSource<Feature<Polygon>>>) => {
      const source = layer.getSource();
      source.forEachFeature((feature: Feature<Polygon>) => {
        feature.changed(); // This will reapply the style
      });
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setupMap() {
    let centerCoordinates = [-8920158.301309984, 5385226.58259767];
    if (
      this.locationService.userLocation &&
      this.locationService.userLocation[0] !== 0 &&
      this.locationService.userLocation[1] !== 0
    ) {
      centerCoordinates = fromLonLat(this.locationService.userLocation);
    }
    this.ngZone.runOutsideAngular(() => {
      this.olMap = new Map({
        pixelRatio: window.devicePixelRatio,
        controls: [],
        interactions: this.defaultInteractions,
      });
      this.view = new View({
        center: centerCoordinates,
        zoom: 12,
      });
      this.olMap.setView(this.view);
    });
  }

  private async setupLayers(): Promise<Array<Layer | LayerGroup>> {
    this.ticketSource = new VectorSource();
    this.ticketPinLayer = new VectorImage({
      visible: false,
      source: this.ticketSource,
      properties: {
        name: 'ticketPinLayer',
      },
    });
    this.ticketPinLayer.setStyle(this.ticketPinStyle);

    this.heatMapLayer = new HeatmapLayer({
      visible: false,
      source: this.ticketSource,
      blur: 25,
      radius: 10,
      properties: {
        name: 'heatmapLayer',
      },
    });
    this.digSiteSource = new VectorSource();
    this.digSiteLayer = new VectorLayer({
      visible: false,
      source: this.digSiteSource,
      properties: {
        name: 'digSiteLayer',
      },
    });
    this.digSiteLayer.setStyle(this.digsiteStyleFunction);

    if (this.dispatchAreaService.userCanModifySetting[0]) {
      this.setupDispatchAreas();
    }

    this.setupLocatorCheckins();

    const osmLayer = this.layerManagerService.getOSMBaseLayer();
    const satLayer = this.layerManagerService.getSatelliteLayer();
    await this.setupClientLayerGroup([osmLayer, satLayer]);

    return [
      osmLayer,
      satLayer,
      this._clientLayerGroup,
      this._dispatchLayerGroup,
      this.heatMapLayer,
      this.digSiteLayer,
      this.ticketPinLayer,
      this.locatorCheckinLayer,
    ];
  }

  async setupClientLayerGroup(extraLayers: BaseLayer[]) {
    let layers = extraLayers;
    if (this.settingLookupService.isSettingActive(SettingID.CLIENT_LAYERS_ON_HOMEPAGE)) {
      const clientLayers = await this.layerManagerService.getClientLayers({}, false);
      this._clientLayerGroup.setLayers(new Collection(clientLayers));
      layers = [...extraLayers, this._clientLayerGroup]
    }
    this._publicLayers$.next(layers);
  }

  private setupLocatorCheckins() {
    this.locatorCheckinSource = new VectorSource();
    this.locatorCheckinLayer = new VectorLayer({
      visible: false,
      source: this.locatorCheckinSource,
      properties: {
        name: 'locatorCheckinLayer',
      },
    });
    // this.locatorCheckinLayer.setStyle(this.locatorCheckinStyle());

    if (this.userService.locatorCheckins === null) {
      this.userService.fetchLocatorCheckins();
    }
    this.userService.locatorCheckins$.pipe(takeUntil(this.destroy$)).subscribe((checkins) => {
      if (checkins === null) {
        this.locatorCheckinSource.clear();
        return;
      }
      checkins.forEach((checkin) => {
        const { Coords, ...props } = checkin;
        if (Array.isArray(Coords) && Array.isArray(Coords[0]) && Coords[0].length === 2 && !isNaN(Coords[0][0])) {
          const feature = new Feature({
            geometry: new Point(fromLonLat(Coords[0].reverse())),
            ...props,
          });

          feature.setStyle(this.locatorCheckinStyle(props.HexColour));
          this.locatorCheckinSource.addFeature(feature);
        }
      });
    });
  }

  private setupDispatchAreas() {
    this.selectedArea = new Collection<Feature<Polygon>>([]);
    this.dispatchAreaSelect = new Select({
      features: this.selectedArea,
      multi: false,
      layers: this.dispatchAreaLayers,
    });
    this.searchedAreas = new Collection<Feature<Polygon>>([]);
    this.areaSearchSelect = new Select({
      features: this.searchedAreas,
      multi: true,
      condition: () => false,
      filter: () => false,
      layers: this.dispatchAreaLayers,
      style: new Style({
        stroke: new Stroke({
          color: 'rgb(218,32,32)',
          width: 3,
        }),
        fill: new Fill({
          color: 'rgba(255,2,2,0.2)',
        }),
      }),
    });
    this._menuSelection$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this._dispatchLayerGroup
        .getLayersArray()
        .map((x) => x.getSource())
        .forEach((source: VectorSource) => {
          source.forEachFeature((feature) => {
            if (feature !== this.dispatchAreaEditorService.feature && feature.get('props')) {
              feature.changed();
            }
          });
        });
    });

    this.dispatchAreaService.dispatchLayers$
      .pipe(
        combineLatestWith(this.dispatchAreaService.dispatchAreas$),
        tap(() => this._dispatchLayerGroup?.getLayers().clear()),
        takeUntil(this.destroy$)
      )
      .subscribe(([layers, areas]) => {
        function setupLayer(layer: DispatchLayer, style: StyleLike, isVisible: boolean) {
          const vectorLayer = new VectorLayer({
            properties: layer,
            visible: isVisible,
            source: new VectorSource({
              features: Object.values(areas)
                .filter((x) => x[0].LayerID === layer.LayerID)
                .map((area) => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  const { Lat, Lng, ...props } = area[0];
                  const geom = new Polygon([area.map((x) => fromLonLat([x.Lng, x.Lat]))]);
                  return new Feature({
                    geometry: geom,
                    props,
                  });
                }),
            }),
          });
          vectorLayer.setStyle(style);
          return vectorLayer;
        }

        // if (layers.length === 0 || Object.keys(areas).length === 0) {
        //   return;
        // }
        const layerValue = this.dispatchAreaEditorService.selectedLayer[0]?.value;

        layers.forEach((layer: DispatchLayer) => {
          let visible: boolean;
          if (
            (layers.length === 1 || layer.isDefault == 1) &&
            (layerValue === undefined || !layers.find((x) => x.LayerID == layerValue))
          ) {
            this.dispatchAreaEditorService.selectedLayer = [{ name: layer.Name, value: layer.LayerID }];
            visible = true;
          } else {
            visible = layerValue == layer.LayerID;
          }
          this._dispatchLayerGroup.getLayers().push(setupLayer(layer, this.dispatchStyle, visible));
        });
      });

    this.dispatchAreaEditorService.selectedLayer$.pipe(takeUntil(this.destroy$)).subscribe(([selected]) => {
      const { value } = selected ?? {};
      this._dispatchLayerGroup.getLayersArray().forEach((layer) => {
        if (selected && layer.get('LayerID') === value) {
          layer.setVisible(true);
        } else {
          layer.setVisible(false);
        }
      });
    });

    try {
      this.olMap.addInteraction(this.dispatchAreaSelect);
      this.olMap.addInteraction(this.areaSearchSelect);

      let snap: Snap;
      let draw: Draw;
      let mod: Modify;
      let source: VectorSource;
      let newFeature: Feature<Polygon>;
      let oldFeature: Feature<Polygon>;
      const { addingArea$, selectedLayer$, modifyingArea$ } = this.dispatchAreaEditorService;

      addingArea$
        .pipe(
          combineLatestWith(
            selectedLayer$,
            modifyingArea$,
            this._menuSelection$.pipe(
              distinctUntilChanged((a, b) => {
                return isEqual(a.includes(0), b.includes(0));
              })
            )
          ),
          takeUntil(this.destroy$)
        )
        .subscribe(([adding, [selectedLayer], modding, menu]) => {
          if (draw) {
            this.olMap.removeInteraction(draw);
            draw.dispose();
            draw = undefined;
          }
          if (mod) {
            this.olMap.removeInteraction(mod);
            mod.dispose();
            mod = undefined;
          }
          if (snap) {
            snap.setActive(false);
            this.olMap.removeInteraction(snap);
            snap.dispose();
            snap = undefined;
          }
          if (source) {
            if (newFeature) {
              source.removeFeature(newFeature);
              newFeature.dispose();
              newFeature = undefined;
            }
            if (oldFeature) {
              oldFeature.setStyle();
              source.addFeature(oldFeature);
              this.selectedArea?.clear();
              oldFeature = undefined;
            }
            source = undefined;
          }
          if (selectedLayer && menu.includes(0)) {
            if (adding || modding) {
              console.log(selectedLayer.value);
              console.log(this._dispatchLayerGroup.getLayers().getArray());
              console.log(this.dispatchAreaLayers);
              source =
                this.dispatchAreaLayers
                  .find((layer) => layer.getProperties()['LayerID'] === selectedLayer.value)
                  ?.getSource() ?? new VectorSource();
              snap = new Snap({
                source,
                pixelTolerance: this.dispatchAreaEditorService.snapTolerance[0]?.value ?? 0,
              });
              snap.setActive(this.dispatchAreaEditorService.snappingEnabled);
              this.dispatchAreaSelect.setActive(false);
              this.areaSearchSelect.setActive(false);
            } else {
              this.selectedArea?.clear();
              this.dispatchAreaSelect.setActive(true);
              this.areaSearchSelect.setActive(true);
            }
            if (adding) {
              this.selectedArea?.clear();
              draw = new Draw({
                source,
                type: 'Polygon',
              });
              draw.on('drawend', (e) => {
                // because interaction order matters; add again later
                this.olMap.removeInteraction(snap);
                if (!(e.feature.getGeometry() instanceof Polygon)) {
                  throw new Error('Draw: Unexpected geometry type');
                }
                newFeature = e.feature as Feature<Polygon>;
                newFeature.setStyle(
                  new Style({
                    fill: new Fill({
                      color: 'rgba(44,216,246,0.3)',
                    }),
                    stroke: new Stroke({
                      color: 'rgba(0,101,255,0.6)',
                      width: 3,
                    }),
                  })
                );
                mod = new Modify({
                  features: new Collection([newFeature]),
                });
                this.olMap.addInteraction(mod);
                this.olMap.addInteraction(snap);
                snap.setActive(this.dispatchAreaEditorService.snappingEnabled);
                mod.setActive(true);
                draw.setActive(false);
                this.dispatchAreaEditorService.feature = newFeature;
              });
              this.olMap.addInteraction(draw);
              this.olMap.addInteraction(snap);
              snap?.setActive(this.dispatchAreaEditorService.snappingEnabled);
            } else if (modding) {
              newFeature = this.selectedArea.getArray()[0];
              oldFeature = this.selectedArea.getArray()[0].clone();
              this.dispatchAreaEditorService.feature = newFeature;
              mod = new Modify({
                features: this.selectedArea,
              });
              this.olMap.addInteraction(mod);
              this.olMap.addInteraction(snap);
              snap?.setActive(this.dispatchAreaEditorService.snappingEnabled);
            }
          } else {
            this.dispatchAreaSelect.setActive(false);
            this.areaSearchSelect.setActive(false);
            this.selectedArea?.clear();
            this.searchedAreas?.clear();
            this.dispatchAreaEditorService.addingArea = false;
            this.dispatchAreaEditorService.modifyingArea = false;
            this.dispatchAreaEditorService.selectedArea = [];
          }
        });

      this.dispatchAreaEditorService.snappingEnabled$.pipe(takeUntil(this.destroy$)).subscribe((snapping) => {
        if (snap) {
          if (snapping) {
            snap.setActive(true);
          } else {
            snap.setActive(false);
          }
        }
      });

      this.dispatchAreaEditorService.areaQuery$
        .pipe(combineLatestWith(this.dispatchAreaService.dispatchAreas$), takeUntil(this.destroy$))
        .subscribe(([[next]]) => {
          this.searchedAreas?.clear();
          const temp = [...this.selectedArea.getArray()];
          this.selectedArea?.clear();
          if (next !== undefined) {
            this.dispatchAreaLayers.forEach((layer) => {
              layer.getSource().forEachFeature((feature) => {
                if (next.value === (feature.get('props') as DispatchAreaPoint).LocatorID) {
                  this.searchedAreas.push(feature);
                }
              });
            });
          }
          this.selectedArea.extend(temp);
        });

      fromEvent(this.dispatchAreaSelect, 'select')
        .pipe(map((x: SelectEvent) => x.selected))
        .subscribe((x: Array<Feature>) => {
          const area = x[0]?.getProperties()['props'] ?? null;
          if (area) {
            const newSelectedArea = [
              {
                name: area['AreaNameShort'] ?? 'error',
                value: area['AreaID'].toString(),
              },
            ];
            if (!isEqual(this.dispatchAreaEditorService.selectedArea, newSelectedArea)) {
              this.dispatchAreaEditorService.selectedArea = newSelectedArea;
            }
          } else {
            this.dispatchAreaEditorService.selectedArea = [];
          }
        });
    } catch (e) {
      console.error(e);
      this.snackBarService.openSnackbar('Failed to load dispatch area editor options', SnackbarType.error);
    }
  }

  private setupTicketSelection() {
    this._select = new Select({
      layers: [this.ticketPinLayer],
      style: [
        new Style({
          image: new Icon({
            crossOrigin: 'anonymous',
            src: 'assets/custom-icons/pin_border.svg',
            width: 32,
            height: 32,
            opacity: 1,
            color: '#ffffff',
            anchor: [0.5, 0.5],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
          }),
        }),
        this.invisibleCircleStyle,
      ],
    });
    this.olMap.addInteraction(this._select);
    this._select.setActive(false);
    const selectedFeatures = this._select.getFeatures();

    this._ticketSelection$.pipe(takeUntil(this.destroy$)).subscribe((ticketSelection) => {
      const features = this.ticketSource.getFeatures();
      selectedFeatures.clear();
      selectedFeatures.extend(features.filter((feature) => ticketSelection.includes(feature.get('ticket').SubNum)));
    });

    this._dragBox = new DragBox();

    this._dragBox.on('boxend', () => {
      const boxExtent = this._dragBox.getGeometry().getExtent();

      const worldExtent = this.view.getProjection().getExtent();
      const worldWidth = getWidth(worldExtent);
      const startWorld = Math.floor((boxExtent[0] - worldExtent[0]) / worldWidth);
      const endWorld = Math.floor((boxExtent[2] - worldExtent[0]) / worldWidth);

      for (let world = startWorld; world <= endWorld; ++world) {
        const left = Math.max(boxExtent[0] - world * worldWidth, worldExtent[0]);
        const right = Math.min(boxExtent[2] - world * worldWidth, worldExtent[2]);
        const extent = [left, boxExtent[1], right, boxExtent[3]];

        const boxFeatures = this.ticketSource
          .getFeaturesInExtent(extent)
          .filter(
            (feature) =>
              !selectedFeatures.getArray().includes(feature) && feature.getGeometry().intersectsExtent(extent)
          );
        const rotation = this.view.getRotation();
        const oblique = rotation % (Math.PI / 2) !== 0;

        if (oblique) {
          const anchor = [0, 0];
          const geometry = this._dragBox.getGeometry().clone();
          geometry.translate(-world * worldWidth, 0);
          geometry.rotate(-rotation, anchor);
          const extent = geometry.getExtent();
          boxFeatures.forEach(function (feature) {
            const geometry = feature.getGeometry().clone();
            geometry.rotate(-rotation, anchor);
            if (geometry.intersectsExtent(extent)) {
              selectedFeatures.push(feature);
            }
          });
        } else {
          selectedFeatures.extend(boxFeatures);
        }
        this._ticketSelection$.next(selectedFeatures.getArray().map((x) => x.get('ticket').SubNum));
      }
    });

    this._dragBox.on('boxstart', () => {
      selectedFeatures?.clear();
      this._ticketSelection$.next([]);
    });

    this.olMap.addInteraction(this._dragBox);

    this._dragBox.setActive(false);
  }

  getLocatorCheckinFeatures(pixel: Array<number>) {
    if (this.locatorCheckinLayer) {
      return from(this.locatorCheckinLayer.getFeatures(pixel)).pipe(
        tap((features: Feature<Point>[]) => this.handleLocatorCheckinFeatures(features)),
        catchError(() => of(null))
      );
    } else {
      return EMPTY;
    }
  }

  getTicketPinFeatures(pixel: Array<number>) {
    if (this.ticketPinLayer) {
      return from(this.ticketPinLayer.getFeatures(pixel)).pipe(
        tap((features: Feature<Point>[]) => {
          this.handleTicketPinFeatures(features);
        }),
        catchError(() => of(null))
      );
    } else {
      return EMPTY;
    }
  }

  handleLocatorCheckinFeatures(features: Feature<Point>[]) {
    if (features.length > 0) {
      this._hoveredLocator$.next([features[0].get('UserName') ?? '', features[0].get('LastCreatedDate') ?? '']);
      this.locatorCheckinOverlay.setPosition(features[0].getGeometry().getCoordinates());
      this.olMap.getTargetElement().style.cursor = 'pointer';
    } else {
      this._hoveredLocator$.next(['', '']);
      this.locatorCheckinOverlay.setPosition(undefined);
      this.olMap.getTargetElement().style.cursor = '';
    }
  }

  handleTicketPinFeatures(features: Feature<Point>[]) {
    if (features.length > 0) {
      this._previewTicket$.next(features[0].get('ticket'));
      this.ticketPreviewOverlay.setPosition(features[0].getGeometry().getCoordinates());
      this.olMap.getTargetElement().style.cursor = 'pointer';
    } else {
      this._previewTicket$.next(null);
      this.ticketPreviewOverlay.setPosition(undefined);
      this.olMap.getTargetElement().style.cursor = '';
    }
  }

  private setupPointerEvents() {
    fromEvent(this.olMap, 'pointermove')
      .pipe(
        throttleTime(50, asyncScheduler, { leading: true, trailing: true }),
        filter(() => !this.dispatchAreaEditorService.addingArea && !this.dispatchAreaEditorService.modifyingArea),
        distinctUntilChanged((a: MapBrowserEvent<UIEvent>, b) => isEqual(a.pixel, b.pixel)),
        switchMap((evt: MapBrowserEvent<UIEvent>) => {
          const toMerge = [];
          try {
            if (this.menuSelection.includes(4)) {
              toMerge.push(this.getLocatorCheckinFeatures(evt.pixel));
            }
            if (this._menuSelection$.value.includes(7)) {
              toMerge.push(this.getTicketPinFeatures(evt.pixel));
            }
            return merge(...toMerge);
          } catch {
            return of(null);
          }
        }),
        catchError(() => {
          return of(null);
        }),
        takeUntil(this.destroy$),
        finalize(() => console.log('pointermove unsubscribed'))
      )
      .subscribe();

    fromEvent(this.olMap, 'singleclick')
      .pipe(
        tap(() => {
          this.selectedLocatorID = null;
        }),
        switchMap((evt: MapBrowserEvent<MouseEvent>) => {
          const menuSelection = this._menuSelection$.value;
          if (menuSelection.includes(7)) {
            return of(
              this.olMap.getFeaturesAtPixel(evt.pixel, {
                layerFilter: (layer: Layer<Source>) => {
                  return (
                    (layer instanceof VectorLayer || layer instanceof VectorImage) && layer === this.ticketPinLayer
                  );
                },
              })
            ).pipe(
              tap(() => {
                this.closeTicketPreview();
              }),
              filter((x: Array<Feature>) => x && x.length > 0),
              map((x: Feature[]) => x[0]),
              tap((x: Feature<Point | Polygon>) => {
                const geom = x.getGeometry();
                const coordinate = (() => {
                  if (geom instanceof Point) {
                    return geom.getCoordinates();
                  } else if (geom instanceof Polygon) {
                    return getCenter(geom.getExtent());
                  }
                })();
                this._selectedTicket$.next(x.get('ticket'));
                this.ticketInspectorOverlay.setPosition(coordinate);
              })
            );
          } else if (menuSelection.includes(9)) {
            this.handleInspection(evt);
          }
          return EMPTY;
        }),
        catchError(() => {
          return EMPTY;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    fromEvent(this.olMap, 'moveend')
      .pipe(
        debounceTime(300),
        map(() => {
          const extent = this.view.calculateExtent(this.olMap.getSize());
          return this.ticketSource
            .getFeaturesInExtent(extent)
            .map((x) => x.getProperties())
            .map((x) => x.ticket.AssignmentID);
        }),
        distinctUntilChanged((x, y) => isEqual(x, y)),
        filter((assignmentIDs) => assignmentIDs.length > 0),
        catchError(() => EMPTY),
        takeUntil(this.destroy$)
      )
      .subscribe((assignmentIDs) => {
        this.digSiteSource?.clear();
        // fetch dig sites
        firstValueFrom(this.digSiteService.fetchDigSiteAreas(assignmentIDs))
          .then((areas) => {
            if (areas instanceof Error) {
              return;
            } else {
              areas.forEach((area: Digsite) => {
                // create dig site features
                const coords = [area['Coordinates'].map((x) => fromLonLat([x.lng, x.lat]))];
                let newFeature: Feature;
                if (area.DigsiteTypeID === 1 && coords[0].length == 2) {
                  newFeature = new Feature({
                    geometry: new Circle(coords[0][0], getLength(new LineString(coords[0]))),
                    area,
                  });
                } else {
                  newFeature = new Feature({
                    geometry: new Polygon(coords),
                    area,
                  });
                }
                // populate dig site source
                this.digSiteSource.addFeature(newFeature);
              });
            }
          })
          .catch((error) => console.log('Failed to render dig sites', error));
      });
  }

  zoomIn() {
    this.view.setZoom(this.view.getZoom() + 1);
  }

  zoomOut() {
    this.view.setZoom(this.view.getZoom() - 1);
  }

  flyToUserLocation(): void {
    let centerCoordinates;

    // Check if user location is available and not [0,0]
    if (
      this.locationService.userLocation &&
      this.locationService.userLocation[0] !== 0 &&
      this.locationService.userLocation[1] !== 0
    ) {
      centerCoordinates = fromLonLat(this.locationService.userLocation);
    } else {
      // Set the center to Guelph, Ontario
      centerCoordinates = fromLonLat([-80.304845, 43.5338411]); // Coordinates of Guelph, Ontario
    }

    this.view.animate({
      center: centerCoordinates,
      zoom: 18,
      duration: 3000,
    });
  }

  goFullScreen() {
    if (document.fullscreenElement) {
      document
        .exitFullscreen()
        .then(() => {
          console.log('exit full screen');
        })
        .catch(() => {
          console.log('could not exit full screen');
        });
    } else {
      this.olMap
        .getTargetElement()
        .requestFullscreen()
        .then(() => {
          console.log('full screen');
        })
        .catch(() => {
          console.log('could not go full screen');
        });
    }
  }

  closeTicketPreview() {
    this._selectedTicket$.next(null);
    this._previewTicket$.next(null);
    this.ticketInspectorOverlay.setPosition(undefined);
    this.ticketPreviewOverlay.setPosition(undefined);
  }

  dragBoxSetup(isOn: boolean) {
    if (isOn) {
      this._dragBox.setActive(true);
      this._select.setActive(true);
      this.olMap.getTargetElement().style.cursor = 'crosshair';
    } else {
      this._dragBox.setActive(false);
      this._select.setActive(false);
      this._select.getFeatures()?.clear();
      this._ticketSelection$.next([]);
      this.olMap.getTargetElement().style.cursor = '';
    }
  }

  public showRoutingPins(toggleValue: boolean) {
    this.showRouteOrderAbovePin = toggleValue;
    this.ticketSource.forEachFeature((feature) => {
      const routeOrder = feature.get('ticket').RouteOrder;
      const palette = feature.get('palette');
      const color = palette[this._ticketPinColourScheme$.value];

      let text = toggleValue && routeOrder !== undefined && routeOrder !== null ? routeOrder.toString() : '';
      //increment route order, since route order starts at 0
      if (text.length > 0) {
        text = (parseInt(text) + 1).toString();
      }

      const newStyle = new Style({
        image: new Icon({
          crossOrigin: 'anonymous',
          color: color,
          width: 32,
          height: 32,
          img: this.ticketPinImage,
          anchor: [0.5, 0.5],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
        }),
        text: new Text({
          font: '12px Calibri,sans-serif',
          fill: new Fill({ color: '#000' }),
          stroke: new Stroke({ color: '#fff', width: 3 }),
          text: text, // Set text based on toggleValue
          offsetY: -25,
        }),
      });

      newStyle.getText().setText(text); //set the pin text
      feature.setStyle([newStyle, this.invisibleCircleStyle]);

      feature.changed();
    });
  }

  public clearTickets() {
    this.ticketSource?.clear();
    if (this.ticketInspectorOverlay) {
      this.ticketInspectorOverlay.setPosition(undefined);
    }
  }

  public addTickets(tickets: Ticket[]) {
    let missingLatLong = false;
    this.ticketSource.addFeatures(
      tickets.reduce((acc: Array<Feature<Point>>, ticket) => {
        const { Latitude, Longitude } = ticket;
        if (!Latitude || !Longitude || Latitude === '' || Longitude === '') {
          missingLatLong = true;
          return acc;
        } else {
          return acc.concat(
            new Feature({
              geometry: new Point(fromLonLat([parseFloat(Longitude), parseFloat(Latitude)])),
              ticket,
              palette: this.generateTicketPalette(ticket),
            })
          );
        }
      }, [])
    );
    return missingLatLong;
  }

  public generateTicketPalette(ticket: Ticket) {
    const arr = new Array(sections.length).fill('#ffffff'); // Initialize array with default color
    const days = this.daysUntilDate(new Date(ticket['ExcavationDate'] ?? ticket['Work to Begin Date']));
    const locateStatusColors = {
      [LocateStatusID.SYSTEM_ANALYZING]: '#323232',
      [LocateStatusID.READY_FOR_DISPATCH]: '#858585',
      [LocateStatusID.PRE_SKETCHED]: '#D3D3D3',
      [LocateStatusID.DISPATCHED_TO_LOCATOR]: '#20B2AA',
      [LocateStatusID.IN_THE_FIELD]: '#00BFFF',
      [LocateStatusID.LOCATE_COMPLETED]: '#0000FF',
      [LocateStatusID.OFFICE_CANCELLED]: '#E72876',
      [LocateStatusID.ASSISTANCE_NEEDED]: '#8B008B',
    };

    const priorityLocateStatusColoring = {
      [LocateStatusID.LOCATE_COMPLETED]: '#0000FF',
    };

    sections.forEach((section, index) => {
      let user;
      switch (index) {
        case 0: // combined
          arr[index] =
            priorityLocateStatusColoring[ticket.LocateStatusID] ||
            CallTypeColours[ticket.CallTypeID] ||
            locateStatusColors[ticket.LocateStatusID] ||
            '#2E7CF6';
          break;
        case 1: // compliance
          arr[index] = '#FF0000';
          if (ticket.LocateStatusID === LocateStatusID.LOCATE_COMPLETED) {
            arr[index] = '#0000FF';
            break;
          }
          if (days <= 0) {
            arr[index] = '#FF0000';
          } else if (days < 1) {
            arr[index] = '#FF6E00';
          } else if (days < 2) {
            arr[index] = '#FFC800';
          } else if (days < 3) {
            arr[index] = '#FFFF00';
          } else if (days < 4) {
            arr[index] = '#C8FF00';
          } else {
            arr[index] = '#00FF00';
          }
          break;
        case 2: // locator
          // Find the user with the matching Assigned ID
          user = this.userService.users.find((user) => ticket['Assigned ID'] === user.UserID);

          // Check if the user has a non-null HexColour property
          if (user && user.HexColour) {
            arr[index] = user.HexColour;
          }
          break;

        case 3: // status
          arr[index] = locateStatusColors[ticket.LocateStatusID] || '#323232';
          break;
        case 4: // call type
          arr[index] = CallTypeColours[ticket.CallTypeID] ?? '#323232';
          break;
        default:
          arr[index] = '#ffffff';
      }
    });
    return arr;
  }

  private daysUntilDate(date: Date) {
    const now = new Date();
    const diff = date.getTime() - now.getTime();
    return Math.floor(diff / (1000 * 3600 * 24));
  }

  public zoomToAllTickets() {
    if (!this.ticketSource.isEmpty()) {
      const width = this.olMap.getSize();
      if (width && width[0] > 800) {
        this.view.fit(this.ticketSource.getExtent(), {
          padding: [50, 200, 50, 200],
          maxZoom: 18,
          duration: 2000,
          easing: (t) => t,
          size: this.olMap.getSize(),
        });
      } else {
        this.view.fit(this.ticketSource.getExtent(), {
          padding: [50, 70, 50, 70],
          maxZoom: 18,
          duration: 2000,
          easing: (t) => t,
          size: this.olMap.getSize(),
        });
      }
    } else {
      this.view.fit([0, 0, 0, 0], {
        padding: [50, 200, 50, 200],
        maxZoom: 2,
        duration: 2000,
        easing: (t) => t,
        size: this.olMap.getSize(),
      });
    }
  }

  public attachOverlay(ref: Overlay) {
    this.olMap?.addOverlay(ref);
  }

  public tidy() {
    this.selectedArea?.clear();
    this.searchedAreas?.clear();
  }

  set ticketSelection(val: Array<number>) {
    this._ticketSelection$.next(val);
  }

  get ticketSelection$(): Observable<number[]> {
    return this._ticketSelection$.pipe(distinctUntilChanged((a, b) => isEqual(a, b)));
  }

  get ticketSelection(): number[] {
    return this._ticketSelection$.value;
  }

  get menuSelection$() {
    return this._menuSelection$.pipe(
      distinctUntilChanged((a, b) => {
        return isEqual(a, b);
      })
    );
  }

  get menuSelection() {
    return this._menuSelection$.value;
  }

  set dontFly(val: boolean) {
    this._dontFly = val;
  }

  get dontFly(): boolean {
    return this._dontFly;
  }

  get selectedTicket$(): Observable<Ticket> {
    return this._selectedTicket$.pipe();
  }

  get selectedTicket(): Ticket {
    return this._selectedTicket$.value;
  }

  get previewTicket$(): Observable<Ticket> {
    return this._previewTicket$.pipe();
  }

  get previewTicket(): Ticket {
    return this._previewTicket$.value;
  }

  get locatorCheckin$(): Observable<[string, string]> {
    return this._hoveredLocator$.pipe();
  }

  get locatorCheckin(): [string, string] {
    return this._hoveredLocator$.value;
  }

  get locatorOptions$(): Observable<Array<{ value: number; name: string }>> {
    return this._locatorOptions$.pipe();
  }

  get locatorOptions(): Array<{ value: number; name: string }> {
    return this._locatorOptions$.value;
  }

  get ticketPinColourScheme$() {
    return this._ticketPinColourScheme$.pipe(distinctUntilChanged((a, b) => isEqual(a, b)));
  }

  get inspectorData$() {
    return this._inspectorData$.pipe();
  }

  get inspectorPosition$() {
    return this._inspectorPosition$.pipe();
  }

  set ticketPinColourScheme(val: number) {
    this._ticketPinColourScheme$.next(val);
  }

  get clientLayers$() {
    return this._publicLayers$.pipe();
  }

  public updateMenuSelection(index: number) {
    const interlocks: Record<number, number[]> = {
      9: [7],
      7: [9],
    };
    const currentSelection = this._menuSelection$.value;
    const newSelection = currentSelection.includes(index)
      ? currentSelection.filter((x) => x !== index)
      : [...currentSelection, index];
    const postInterlock = newSelection.filter((x) => !interlocks[index]?.includes(x));
    this._menuSelection$.next(postInterlock.sort((a, b) => a - b));
  }

  public attachMap(div: HTMLDivElement) {
    this.ngZone.runOutsideAngular(() => {
      this.olMap.setTarget(div);
    });
  }

  public setupDefaultOverlays(
    ticketInspector: HTMLDivElement,
    ticketPreview: HTMLDivElement,
    locatorCheckin: HTMLDivElement
  ) {
    const options = {
      autoPan: {
        animation: {
          duration: 250,
          easing: (t) => t,
        },
      },
    };
    this.ticketInspectorOverlay = new Overlay({
      element: ticketInspector,
      ...options,
    });
    this.ticketPreviewOverlay = new Overlay({
      element: ticketPreview,
      ...options,
    });
    this.locatorCheckinOverlay = new Overlay({
      element: locatorCheckin,
      ...options,
    });
    [this.ticketInspectorOverlay, this.ticketPreviewOverlay, this.locatorCheckinOverlay].forEach((overlay) => {
      this.olMap.addOverlay(overlay);
    });
  }

  private async handleInspection(evt: MapBrowserEvent<MouseEvent>) {
    this.updateInspectorPosition(evt);
    const res = await this.featuresInspectorService.inspectMap(evt);
    this._inspectorData$.next(res ?? []);
  }

  private updateInspectorPosition(evt: MapBrowserEvent<MouseEvent>) {
    console.log(evt.coordinate);
    this._inspectorPosition$.next(evt.coordinate);
  }
}
