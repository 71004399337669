import { Component, ElementRef, inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import {
  SavedSearch,
  SearchCriteria,
  TicketFilter,
  TicketSearchService,
} from '../../../services/ticket-search/ticket-search.service';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, filter, firstValueFrom, Observable, Subject, switchMap } from 'rxjs';
import { first, map, takeUntil, tap } from 'rxjs/operators';
import { CompetersSearchBarComponent } from '../../inputs/competers-search-bar/competers-search-bar.component';
import { VerticalCollapsibleComponent } from '../../containers/vertical-collapsible/vertical-collapsible.component';
import { SelectionModel } from '@angular/cdk/collections';
import {
  CompetersDeprecatedInputComponent,
  InputType,
} from '../../inputs/competers-deprecated-input/competers-deprecated-input.component';
import { MatIconModule } from '@angular/material/icon';
import { ClickStopPropagationDirective } from '../../../../modules/core/directives/click-stop-propagation.directive';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SearchableDropdownComponent } from '../../inputs/searchable-dropdown/searchable-dropdown.component';
import { CompetersDateRangePickerComponent } from '../../inputs/competers-date-range-picker/competers-date-range-picker.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DbOptionToSelectionOptionPipe } from './dbOptionToSelectionOption.pipe';
import { SnackbarService } from '../../../../modules/shared/snackbar/snackbar.service';
import { CompetersCheckboxComponent } from '../../inputs/competers-checkbox/competers-checkbox.component';
import { isEqual } from 'lodash-es';
import { DatetimeService } from 'src/app/modules/core/services/datetime/datetime.service';
import { SnackbarType } from '../../../../modules/shared/snackbar/snackbar/snackbar';
import { ProgressBarService } from '../../../../modules/shared/progress-bar/progress-bar.service';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-advanced-ticket-search',
  standalone: true,
  imports: [
    CommonModule,
    CompetersSearchBarComponent,
    VerticalCollapsibleComponent,
    CompetersDeprecatedInputComponent,
    ClickStopPropagationDirective,
    SearchableDropdownComponent,
    CompetersDateRangePickerComponent,
    ReactiveFormsModule,
    DbOptionToSelectionOptionPipe,
    CompetersCheckboxComponent,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: 'advanced-ticket-search.component.html',
  styles: [
    `
      * {
        @apply box-border;
      }
    `,
  ],
  providers: [DbOptionToSelectionOptionPipe],
})
export class AdvancedTicketSearchComponent implements OnInit, OnDestroy {
  //IO
  @ViewChild('container') container: ElementRef<HTMLDivElement>;
  @ViewChild('olay') olay: ElementRef<HTMLDivElement>;
  @ViewChild('save') saveTemplate: TemplateRef<any>;
  @ViewChild('saveAndSearch') sNsTemplate: TemplateRef<any>;
  @ViewChildren(VerticalCollapsibleComponent)
  collapsibleArr: VerticalCollapsibleComponent[];

  //services && pipes
  protected ticketSearchService: TicketSearchService = inject(TicketSearchService);
  protected dialog = inject(MatDialog);
  private snackbar = inject(SnackbarService);
  private optionPipe = inject(DbOptionToSelectionOptionPipe);
  private dateTimeService = inject(DatetimeService);
  private snackBar = inject(SnackbarService);
  private progressBarService = inject(ProgressBarService);

  // observables && signals
  private _destroy$ = new Subject<void>();
  protected _searchValue$ = new BehaviorSubject('');
  protected _filterCategories$ = this.ticketSearchService.searchFilters$.pipe(
    map(({ filters, categories }) => {
      if (filters === null || categories === null) {
        return [];
      } else {
        const groupedFilters = Object.keys(filters).reduce((acc, key) => {
          const filter = filters[key];
          const filterCategoryID = parseInt(filter['filterCategoryID']);
          if (acc[filterCategoryID] === undefined) {
            acc[filterCategoryID] = [];
          }
          acc[filterCategoryID].push(filter);
          return acc;
        }, {});
        return Object.keys(categories).map((key) => {
          const filterCategoryID = parseInt(categories[key]['filterCategoryID']);
          const filterCategoryName = categories[key]['filterCategoryName'];
          return {
            filterCategoryID,
            filterCategoryName,
            filters: groupedFilters[filterCategoryID],
          };
        });
      }
    })
  );
  protected _selectedFilters: SelectionModel<TicketFilter> = new SelectionModel(true, []);
  protected _filteredOptions$: Observable<any[]> = this.ticketSearchService.searchFilters$.pipe(
    map(({ filters }) => Object.keys(filters).map((k) => filters[k])),
    switchMap((filters) =>
      this._searchValue$.pipe(
        map((predicate) =>
          filters.filter((option) => option['visibleName']?.toLowerCase().includes(predicate.toLowerCase().trim()))
        )
      )
    )
  );

  // members
  protected searchFormGroup: FormGroup;
  protected saveFormGroup: FormGroup;
  protected myDialog: MatDialogRef<unknown>;

  constructor() {
    this.searchFormGroup = new FormGroup({}, (form: FormGroup) => {
      let hasDate = false;
      let hasRequestNumber = false;
      let isOutstandingSearch = false;
      let errors = {};
      const nulls = [];
      const keys = Object.keys(form.value) ?? [];
      const subform = (form as FormGroup).controls;
      // if no keys then the form is invalid
      if (keys.length === 0) {
        errors['noKeys'] = 'no keys';
      }
      // if any of the values are null then the form is invalid
      keys.forEach((key) => {
        if (this.ticketSearchService.getFilterFromID(typeof key === 'string' ? parseInt(key) : key).dataTypeID === 5) {
          hasDate = true;
        }
        if (key === '1') {
          hasRequestNumber = true;
        }
        if (key === '20') {
          isOutstandingSearch = true;
        }
        if (form.value[key].value === null) {
          nulls.push(key);
        }
      });
      // if not one of the date filters then the form is valid
      if (!hasDate && !hasRequestNumber && !isOutstandingSearch) {
        errors['noDate'] = 'no date';
      }
      // check subform validity
      Object.keys(subform).forEach((key) => {
        if (subform[key].invalid) {
          errors = {
            ...errors,
            ...subform[key].errors,
          };
        }
      });
      // return the invalid keys if any
      if (!isEqual(errors, {})) {
        return errors;
      } else {
        return null;
      }
    });
  }

  ngOnInit() {
    // add controls to form group as filters are selected
    this._selectedFilters.changed.pipe(takeUntil(this._destroy$)).subscribe(({ added, removed }) => {
      if (added.length > 0) {
        added.forEach((filter) => {
          this.searchFormGroup.addControl(
            filter['filterID'].toString(),
            new FormGroup(
              {
                excluded: new FormControl(false),
                value: new FormControl([1, 7].includes(filter['dataTypeID']) ? true : null),
              },
              (group: FormGroup) => {
                if (filter['dataTypeID'] == 5) {
                  if (
                    group.value['value'] &&
                    group.value['value']['lower'] &&
                    group.value['value']['upper'] &&
                    this.dateTimeService.isValidDateRange(
                      new Date(group.value['value']['lower']),
                      new Date(group.value['value']['upper'])
                    )
                  ) {
                    return null;
                  } else {
                    return { badDate: 'invalid date range' };
                  }
                }
                if (![null, '', []].includes(group.value['value'])) {
                  return null;
                } else {
                  return { nullValue: 'one or more fields are empty' };
                }
              }
            )
          );
        });
      }
      if (removed.length > 0) {
        removed.forEach((filter) => {
          this.searchFormGroup.removeControl(filter['filterID'].toString());
        });
      }
    });

    // prefill search if editing
    this.ticketSearchService.prefillSearch$
      .pipe(
        filter((search) => search !== null && search !== undefined),
        takeUntil(this._destroy$)
      )
      .subscribe((prefill) => {
        this._selectedFilters.clear();
        if (prefill !== null) {
          // pipe in the criteria from the search the user is editing
          const patch = {};
          prefill.SavedSearchCriteria.forEach((criteria) => {
            const filter = this.criteriaToFilter(criteria);
            const temp = { excluded: false, value: null };
            if ([null, undefined].includes(criteria.isExcluded) || criteria.isExcluded === 0) {
              temp.excluded = false;
            } else {
              temp.excluded = criteria.isExcluded === 1;
            }
            this._selectedFilters.select(filter);
            // iife to calculate the value based on the filter type and transform it if necessary
            temp.value = (() => {
              if ([3, 4].includes(filter.dataTypeID)) {
                return this.optionPipe.transform(
                  (Array.isArray(criteria.Value) ? criteria.Value : criteria.Value.split(',')).map((val) => {
                    return filter.options.find((option) =>
                      typeof val === 'number' ? option.id === val : option.id === parseInt(val)
                    );
                  })
                );
              } else if ([1, 7].includes(filter.dataTypeID)) {
                return true;
              } else if (filter.dataTypeID === 5 && typeof criteria.Value === 'string') {
                return {
                  lower: new Date(criteria.Value.split(',')[0]),
                  upper: new Date(criteria.Value.split(',')[1]),
                };
              } else {
                return criteria.Value;
              }
            })();
            patch[criteria.FilterID] = temp;
          });
          this.searchFormGroup.patchValue(patch);
        }
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  getControl(filter: TicketFilter, key: string) {
    return (this.searchFormGroup.get(filter['filterID'].toString()) as FormGroup).controls[key] as FormControl;
  }

  onSearchValueChange(event: string) {
    this._searchValue$.next(event);
  }

  handleSearch(): SearchCriteria[] {
    this.progressBarService.start();
    if (this.searchFormGroup.valid) {
      const [criteria] = this.assembleSearchCriteria(this._selectedFilters.selected);
      this.ticketSearchService
        .fetchNewSearchResults(this, criteria)
        .then(() => {
          this.snackbar.openSnackbar('Search complete', SnackbarType.success);
        })
        .catch(() => {
          this.snackbar.openSnackbar('Error fetching search results', SnackbarType.error);
        })
        .finally(() => this.progressBarService.stop());
      this.ticketSearchService.advancedSearchIsOpen = false;
      return criteria;
    } else {
      this.notifyError();
      return [];
    }
  }

  assembleSearchCriteria(filters: TicketFilter[]): [SearchCriteria[], string[]] {
    const busted = [];
    return [
      filters.reduce((acc, filter) => {
        const { value, excluded } = this.searchFormGroup.value[filter['filterID'].toString()];
        if (value === null) {
          busted.push(filter['visibleName']);
        } else {
          acc.push(this.filterToCriteria(filter, value, excluded));
        }
        return acc;
      }, []),
      busted,
    ];
  }

  filterToCriteria(filter: TicketFilter, val: unknown, excluded: boolean): SearchCriteria {
    const base: SearchCriteria = {
      FilterID: filter['filterID'],
      DataTypeID: filter['dataTypeID'],
      FilterName: filter['visibleName'],
      isExcluded: excluded ? 1 : 0,
      Value: null,
    };
    console.log('val', val);
    // select
    if ([3, 4].includes(filter['dataTypeID'])) {
      base['Value'] = Array.isArray(val) ? val.map((selection) => selection.value) : [];
      base['ValueDescription'] = Array.isArray(val) ? val.map((selection) => selection.name).join(', ') : '';
      // string or num
    } else if ([2, 6, 10].includes(filter['dataTypeID'])) {
      base['Value'] = typeof val === 'string' ? val : val.toString();
      // boolean or query
    } else if ([1, 7].includes(filter['dataTypeID'])) {
      base['Value'] = 'true';
      base['ValueDescription'] = 'true';
      // date
    } else if ([5].includes(filter['dataTypeID'])) {
      if (val && val['upper'] && val['lower']) {
        base['Value'] = `${new Date(val['lower']).toISOString().split('T')[0] + 'T00:00:00.000Z'},${
          new Date(val['upper']).toISOString().split('T')[0] + 'T23:59:59.999Z'
        }`;
      }
    }
    return base;
  }

  criteriaToFilter(criteria: SearchCriteria): TicketFilter {
    return this.ticketSearchService.getFilterFromID(criteria.FilterID);
  }

  initializeSave() {
    if (this.searchFormGroup.valid) {
      this.saveFormGroup = this.setupSaveForm();
      this.myDialog = this.dialog.open(this.saveTemplate);
      this.ticketSearchService.prefillSearch$.pipe(takeUntil(this.myDialog.afterClosed())).subscribe((prefill) => {
        this.saveFormGroup.patchValue({
          name: prefill?.SearchName ?? '',
        });
      });
      this.myDialog
        .afterClosed()
        .pipe(
          first(),
          tap(() => (this.myDialog = undefined))
        )
        .subscribe();
    } else {
      this.notifyError();
    }
  }

  initializeSaveAndSearch() {
    this.initializeSave();
    this.myDialog
      ?.afterClosed()
      .pipe(first())
      .subscribe((res) => {
        if (res !== 'cancel') {
          this.handleSearch();
        }
      });
  }

  handleSaveClick() {
    if (this.saveFormGroup.valid) {
      this.ticketSearchService
        .createSavedSearch(
          this.saveFormGroup.value['name'],
          this.assembleSearchCriteria(this._selectedFilters.selected)[0]
        )
        .pipe(first())
        .subscribe((result) => {
          const id = result?.body?.create?.savedSearch?.result?.insertId;
          if (result?.status === 200 && id) {
            this.snackbar.openSnackbar('Search saved');
            this.myDialog.close();
            firstValueFrom(this.ticketSearchService.refreshSavedSearches()).then(() => {
              this.ticketSearchService.prefillSearch = this.ticketSearchService.getSavedSearchByID(id);
            });
          } else {
            this.snackbar.openSnackbar('Error saving search');
          }
        });
    } else {
      this.notifyError();
    }
  }

  handleUpdateClick() {
    if (this.saveFormGroup.valid) {
      this.ticketSearchService.prefillSearch$
        .pipe(
          tap((x) => {
            if (x.ClientID <= 0) {
              throw new Error('Cannot update a universal search');
            }
          }),
          switchMap((search: SavedSearch) => {
            return this.ticketSearchService.updateSavedSearch(
              search.SavedSearchID,
              this.saveFormGroup.value['name'],
              this.assembleSearchCriteria(this._selectedFilters.selected)[0]
            );
          }),
          first()
        )
        .subscribe((result) => {
          const id = result?.body?.update?.savedSearch?.SavedSearchID;
          if (result?.status === 200 && id) {
            this.snackbar.openSnackbar('Search updated');
            this.myDialog.close();
            firstValueFrom(this.ticketSearchService.refreshSavedSearches()).then(() => {
              this.ticketSearchService.prefillSearch = this.ticketSearchService.getSavedSearchByID(id);
            });
          } else {
            this.snackbar.openSnackbar('Error saving search');
          }
        });
    } else {
      this.notifyError();
    }
  }

  setupSaveForm() {
    return new FormGroup(
      {
        name: new FormControl(),
      },
      (form) => {
        const invalid = [];
        const keys = Object.keys(form.value) ?? [];
        if (keys.length === 0) {
          return {
            invalid: keys,
          };
        }
        let i = 0;
        while (i < keys.length) {
          const key = keys[i];
          if (form.value[key] === null || form.value[key] === '') {
            invalid.push(key + ' cannot be empty');
          }
          i++;
        }
        if (invalid.length > 0) {
          return {
            invalid,
          };
        } else {
          return null;
        }
      }
    );
  }

  handleBackdropClick() {
    this.ticketSearchService.advancedSearchIsOpen = false;
  }

  cancelEdit() {
    this.ticketSearchService.editingSearch = false;
    this.ticketSearchService.prefillSearch = null;
    this._selectedFilters.clear();
  }

  clearAdvancedSearch() {
    this._selectedFilters.clear();
    this.searchFormGroup.reset();
  }

  notifyError() {
    if (this.searchFormGroup.errors) {
      const { noDate, badDate, nullValue } = this.searchFormGroup.errors;
      if (noDate) {
        this.snackbar.openSnackbar('Current search requires a date range', SnackbarType.warning);
      } else if (badDate) {
        this.snackbar.openSnackbar('Invalid date range', SnackbarType.warning);
      } else if (nullValue) {
        this.snackbar.openSnackbar('One or more fields are empty', SnackbarType.warning);
      } else {
        this.snackbar.openSnackbar('Invalid search criteria', SnackbarType.error);
      }
    }
  }

  protected readonly InputType = InputType;
  protected readonly FormControl = FormControl;
}
