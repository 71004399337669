import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import Layer from 'ol/layer/Layer';
import { TabbedContainerComponent } from "../../../../containers/tabbed-container/tabbed-container.component";
import { ContainerTabComponent } from "../../../../containers/tabbed-container/container-tab/container-tab.component";
import { LayerListComponent } from "../../../../../../modules/drawing-module/layer-switcher/layer-list/layer-list.component";
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-layer-switcher',
  standalone: true,
  imports: [MatIconModule, TabbedContainerComponent, ContainerTabComponent, LayerListComponent],
  template: `
    <div class="relative">
      <button (click)="isOpen = !isOpen" class="flex justify-center items-center">
        <mat-icon style="width: 24px; height: 24px; font-size: 24px" svgIcon="layers" />
      </button>
      <div class="absolute top-0 right-full pr-3 {{isOpen ? 'pointer-events-auto' : 'hidden overflow-hidden'}}">
        <app-tabbed-container class="tabbed-container" menu [tabs]="tabs">
          <app-container-tab [tab]="tabs[0]">
            <app-layer-list
              (layerSelectionChanged)="layerSelectionChanged.emit($event)"
              class="layers"
              [layers]="layers"></app-layer-list>
          </app-container-tab>
          <!-- <app-container-tab [tab]="tabs[1]">
            <app-legend class="legend"></app-legend>
          </app-container-tab> -->
        </app-tabbed-container>
      </div>
    </div>
  `,
  styleUrls: ['../ticket-map.component.scss'],
})
export class LayerSwitcherComponent {
  @Input() layers: Observable<Array<Layer>> = of([]);
  @Output() layerSelectionChanged = new EventEmitter<unknown>()

  protected isOpen = false;
  tabs = [
    { title: 'layers', index: 0 },
    // { title: 'legend', index: 1 },
  ];
}
