import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "SplitCamelCase",
  standalone: true,
})
export class SplitCamelCasePipe implements PipeTransform {
  transform(value: string): string {
    if (typeof value !== "string") {
      return value;
    }

    // Special case for "SentToLSP"
    if (value === "SentToLSP") {
      return "Sent To LSP";
    }

    // Check if the string is in camel case using a regular expression
    const isCamelCase = /[a-z][A-Z]/.test(value);

    if (isCamelCase) {
      // Split camel case by identifying where lowercase meets uppercase
      value = value.split(/(?<=[a-z])(?=[A-Z])/).join(" ");
      // Capitalize the first letter of the result
      value = value[0].toUpperCase() + value.slice(1);
    }

    return value;
  }
}

