import { inject, Injectable } from '@angular/core';
import { omit, pickBy } from 'lodash-es';
import { Feature, MapBrowserEvent } from 'ol';
import { GeoJSON } from 'ol/format';
import { Circle } from 'ol/geom';
import Layer from 'ol/layer/Layer';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { GeoserverService } from './geoserver.service';
import { DatabaseLayer, ServiceType } from './layer-manager/types/layer.types';

@Injectable({
  providedIn: 'root',
})
export class FeatureInspectorService {
  private geoserverService = inject(GeoserverService);
  private reader: GeoJSON = new GeoJSON();

  constructor() {}

  public async inspectMap(evt: MapBrowserEvent<MouseEvent>) {
    const data = [];
    const map = evt.map;
    const layers = map.getAllLayers();

    for (let i = 0, len = layers.length; i < len; i++) {
      const layer = layers[i];
      if (!layer.isVisible()) {
        continue;
      }
      switch (layer.get('serviceType')) {
        case ServiceType.EsriMapServer:
        case ServiceType.EsriMapServerSubLayer:
          data.push(...(await this.getEsriMapServerFeatures(evt, layer)));
          break;
        case ServiceType.EsriFeatureServer:
        case ServiceType.EsriFeatureServerSubLayer:
        case ServiceType.GeoserverWFSLayer:
          if (layer instanceof VectorLayer) {
            data.push(...(await this.getVectorLayerFeatures(evt, layer)));
          }
          break;
        case ServiceType.WMSLayerGeoServer:
        case ServiceType.WMSLayerGeoServerSubLayer:
          data.push(...(await this.getGeoserverWMSFeatures(evt, layer)));
          break;
        default:
          break;
      }
    }
    return data.map((props) => pickBy(props, (x: any) => ![null, 'Null', undefined].includes(x)));
  }

  private async getVectorLayerFeatures(evt: MapBrowserEvent<UIEvent>, layer: VectorLayer<VectorSource>) {
    const source = layer.getSource();
    const select = new Feature(new Circle(evt.coordinate, 0.5));
    source.addFeature(select);
    const extent = select.getGeometry().getExtent();
    source.removeFeature(select);
    return source
      .getFeaturesInExtent(extent)
      .map((feature) => omit(feature.getProperties(), 'geometry'))
      .map((attributes) => ({ ...attributes, layer: layer.getProperties() }));
  }

  private async getGeoserverWMSFeatures(evt: MapBrowserEvent<UIEvent>, layer: Layer) {
    const { username, password } = layer.getProperties() as DatabaseLayer;
    const map = evt.map;
    const view = map.getView();
    const layerUrl = layer.get('layerURL');
    if (!layerUrl) {
      return [];
    }
    const layerList = this.buildGeoserverWMSLayerList(layer);
    const url = new URL(layerUrl);
    const size = map.getSize();
    const pixelCoord = map.getPixelFromCoordinate(evt.coordinate).map((x) => Math.round(x));
    const params = {
      INFO_FORMAT: 'application/json',
      REQUEST: 'GetFeatureInfo',
      SERVICE: 'WMS',
      VERSION: '1.1.1',
      WIDTH: size[0].toString(),
      HEIGHT: size[1].toString(),
      X: pixelCoord[0].toString(),
      Y: pixelCoord[1].toString(),
      BUFFER: '10',
      BBOX: `${view.calculateExtent(size).join(',')}`,
      SRS: view.getProjection().getCode(),
      LAYERS: layerList,
      QUERY_LAYERS: layerList,
      EXCEPTIONS: 'JSON',
      FEATURE_COUNT: '1000',
    };
    Object.entries(params).forEach(([key, value]) => {
      url.searchParams.append(key, value);
    });

    const options: RequestInit = {
      headers: {},
    };

    if (username && password) {
      options.headers = {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`,
      };
    }

    try {
      const response = await this.geoserverService.makeRequest(url.toString(), options);
      if (!response.ok) {
        return [];
      }

      const result = await response.json();

      const { exceptions } = result;

      if (exceptions && exceptions.length > 0) {
        return [];
      }

      return this.reader
        .readFeatures(result)
        .map((feature) => omit(feature.getProperties(), 'geometry'))
        .map((attributes) => ({ ...attributes, layer: layer.getProperties() }));
    } catch (e) {
      // do nothing; non-vital
    }
    return [];
  }

  private buildGeoserverWMSLayerList(layer: Layer): string {
    const layers = [];
    const subLayerList = layer.get('subLayers').map((x: DatabaseLayer) => x.subLayerName);
    if (subLayerList) {
      layers.push(...subLayerList);
    }
    return layers.join(',');
  }

  private async getEsriMapServerFeatures(evt: MapBrowserEvent<UIEvent>, layer: Layer) {
    const map = evt.map;
    const view = map.getView();
    const layerUrl = layer.get('layerURL');
    if (!layerUrl) {
      return [];
    }
    const url = new URL(layerUrl + '/identify');
    const params = {
      f: 'json',
      geometryType: 'esriGeometryPoint',
      geometry: evt.coordinate.join(','),
      tolerance: Number(10).toString(),
      mapExtent: view.calculateExtent(map.getSize()).join(','),
      imageDisplay: map.getSize().join(',') + ',96',
      sr: view.getProjection().getCode().split(':')[1],
    };
    Object.entries(params).forEach(([key, value]) => {
      url.searchParams.append(key, value);
    });
    const token = layer.get('token');
    if (token) {
      url.searchParams.append('token', token);
    }

    try {
      const response = await fetch(url);
      if (!response.ok) {
        return [];
      }
      const { results } = await response.json();
      return results.map(({ attributes }) => ({ ...attributes, layer: layer.getProperties() }));
    } catch (e) {
      // do nothing; non-vital
    }
    return [];
  }
}

export type MapServiceIdentifyResult = {
  results: Result[];
};

export type Result = {
  layerId: number;
  layerName: string;
  value: string;
  displayFieldName: string;
  attributes: Attributes;
  geometryType: string;
  hasZ: boolean;
  hasM: boolean;
  geometry: number;
};

export type Attributes = Record<string, any>;

export type GeoserverExceptionResponse = {
  version: string;
  exceptions: Exception[];
};
export type Exception = {
  code: string;
  locator: string;
  text: string;
};
