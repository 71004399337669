import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { ConnectedPosition, OverlayModule } from '@angular/cdk/overlay';
import { filter, takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { BehaviorSubject, combineLatestWith, Subject } from 'rxjs';
import { MatDatepickerModule, MatDateRangePicker } from '@angular/material/datepicker';
import { DatetimeService } from '../../../../modules/core/services/datetime/datetime.service';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { SearchCriteria, TicketSearchService } from '../../../services/ticket-search/ticket-search.service';
import { MatInputModule } from '@angular/material/input';
import { SearchableDropdownComponent } from '../../inputs/searchable-dropdown/searchable-dropdown.component';
import { CompetersDateRangePickerComponent } from '../../inputs/competers-date-range-picker/competers-date-range-picker.component';
import { SnackbarService } from '../../../../modules/shared/snackbar/snackbar.service';
import { SnackbarType } from '../../../../modules/shared/snackbar/snackbar/snackbar';
import forIn from 'lodash-es/forIn';
import { keys } from 'lodash-es';
import { ProgressBarService } from '../../../../modules/shared/progress-bar/progress-bar.service';
import { spacedStringToCamelCase } from '../../../functions/text';
import { InputComponent } from '../../inputs/input/input.component';

@Component({
  selector: 'app-quick-ticket-search',
  templateUrl: './quick-ticket-search.component.html',
  styleUrls: ['./quick-ticket-search.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    OverlayModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSliderModule,
    MatButtonModule,
    MatInputModule,
    SearchableDropdownComponent,
    CompetersDateRangePickerComponent,
    InputComponent,
  ],
})
export class QuickTicketSearchComponent implements OnInit, OnDestroy {
  //services
  public ticketSearchService: TicketSearchService = inject(TicketSearchService);
  private router: Router = inject(Router);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private datetimeService: DatetimeService = inject(DatetimeService);
  private snackBar = inject(SnackbarService);
  private progressBarService = inject(ProgressBarService);

  // observables
  private destroy$: Subject<void> = new Subject<void>();
  protected _initialized$ = new BehaviorSubject(false);

  private _defaultCallDateRange = this.datetimeService.getDateWindow('years', 1);
  isOpen = false;
  formData: FormDropdownData = {};
  quickSearchForm: FormGroup;
  searchCriteria: any[] = [];
  searchFields: SearchField[] = [];
  dateFields: DateRange[] = [];
  searchBarElementWidth: number;
  protected isFullScreen: boolean = false;

  protected searchOutstanding = false;

  positionPairs: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
    },
  ];

  constructor() {}

  ngOnInit() {
    this.ticketSearchService.fetchFormData();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        takeUntil(this.destroy$)
      )
      .subscribe((event) => {
        if (event instanceof NavigationStart && event.url !== '/app/home') {
          this.isOpen = false;
        }
      });

    this.ticketSearchService.formData$
      .pipe(
        combineLatestWith(this.ticketSearchService.searchFilters$),
        filter(([x, y]) => x !== null && ![null, undefined].includes(y.filters)),
        takeUntil(this.destroy$)
      )
      .subscribe(([formData]) => {
        this.formData = formData;
        this.searchFields = this.populateSearchFields();
        this.dateFields = this.populateDateFields();
        this.buildForm();
        this.quickSearchForm.patchValue({});
        if (Object.keys(this.formData).length > 0) {
          this._initialized$.next(true);
        }
      });

    const searchBarElement = document.getElementById('quick-search-root');
    this.checkWindowAndUpdate(searchBarElement.offsetWidth);
    const obs = new ResizeObserver((entries) => {
      this.checkWindowAndUpdate(entries[0].borderBoxSize[0].inlineSize);
    });
    obs.observe(searchBarElement);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.quickSearchForm.reset();
  }

  private checkWindowAndUpdate(size: number) {
    if (window.innerWidth > 640) {
      this.searchBarElementWidth = size;
      this.isFullScreen = false;
    } else {
      this.searchBarElementWidth = window.innerWidth;
      this.isFullScreen = true;
    }
  }

  toggleOutstandingSearch() {
    this.searchOutstanding = !this.searchOutstanding;
    this.quickSearchForm.patchValue({ outstanding: this.searchOutstanding });
  }

  navigateToHome(): Promise<any> {
    return this.router.navigate(['home'], { relativeTo: this.activatedRoute });
  }

  openSearchForm(): void {
    this.isOpen = this._initialized$.value;
  }

  handleAdvancedSearchClick(e: Event): void {
    e.preventDefault();
    this.isOpen = false;
    this.navigateToHome().then(() => {
      this.ticketSearchService.advancedSearchIsOpen = true;
    });
  }

  resetForm(e: Event) {
    e.preventDefault();
    this.quickSearchForm.reset({ call: this._defaultCallDateRange });
  }

  handleBackdropClick(): void {
    this.isOpen = false;
  }

  submitSearch(e: Event) {
    e.preventDefault();
    this.progressBarService.start();
    if (this.quickSearchForm.valid) {
      this.searchCriteria = [
        ...this.searchFieldsToCriteria(this.searchFields),
        ...this.dateFieldsToCriteria(this.dateFields),
      ];
      this.ticketSearchService
        .fetchNewSearchResults(
          this,
          this.searchCriteria,
          this.quickSearchForm.value['limit-slider-control'] ?? undefined
        )
        .then(() => {
          this.snackBar.openSnackbar('Got results for quick search', SnackbarType.success);
        })
        .catch(() => {
          this.snackBar.openSnackbar('Error fetching search results', SnackbarType.error);
        })
        .finally(() => this.progressBarService.stop());
      this.isOpen = false;
    } else {
      const { date, values } = this.quickSearchForm.errors ?? {};
      if (date) {
        this.snackBar.openSnackbar('Date required for this search', SnackbarType.warning);
      } else if (values) {
        this.snackBar.openSnackbar('Please enter at least one search value', SnackbarType.warning);
      } else {
        let found = false;
        forIn(this.quickSearchForm['controls'], (value, key) => {
          if (value.errors) {
            found = true;
            this.snackBar.openSnackbar(`${key}: ${keys(value.errors)[0] ?? 'Invalid value'}`, SnackbarType.warning);
            return false;
          }
        });
        if (!found) {
          this.snackBar.openSnackbar('Invalid search', SnackbarType.warning);
        }
      }
      this.progressBarService.stop();
    }
  }

  searchFieldsToCriteria(searchFormFields: SearchField[]): SearchCriteria[] {
    return searchFormFields
      .filter((field) => {
        let validCriteria = true;
        switch (field.dataTypeID) {
          case 7:
            validCriteria = this.quickSearchForm.value[field.name] === true;
            break;
          default:
            validCriteria =
              this.quickSearchForm.value[field.name] !== null &&
              this.quickSearchForm.value[field.name] !== '' &&
              this.quickSearchForm.value[field.name].length > 0;
            break;
        }
        return validCriteria;
      })
      .map((field) => {
        const searchVal = this.quickSearchForm.value[field.name];
        return {
          FilterID: field.filterID,
          DataTypeID: field.dataTypeID,
          FilterName: field.title,
          Value: Array.isArray(searchVal) ? searchVal.map((selection) => selection.value) : searchVal,
          ValueDescription: Array.isArray(searchVal)
            ? searchVal.map((selection) => selection.name).join(', ')
            : searchVal.name,
          isExcluded: 0,
        };
      });
  }

  dateFieldsToCriteria(dateFields: DateRange[]): SearchCriteria[] {
    const result: Array<SearchCriteria> = dateFields
      .filter(
        (field) =>
          this.quickSearchForm.value[field.name] &&
          this.quickSearchForm.value[field.name]?.lower !== null &&
          this.quickSearchForm.value[field.name]?.upper !== null
      )
      .map((field) => {
        const lowerFormattedDateTime = this.formatDateForQuery(this.quickSearchForm.value[field.name]?.lower);
        const upperFormattedDateTime = this.formatDateForQuery(this.quickSearchForm.value[field.name]?.upper);
        return {
          FilterID: field.filterID,
          DataTypeID: field.dataTypeID,
          FilterName: field.title,
          Value: `${lowerFormattedDateTime},${upperFormattedDateTime}`,
          isExcluded: 0,
        };
      });

    return result as SearchCriteria[];
  }

  formatDateForQuery(inputDate) {
    // Create a new Date object from the input string
    const date = new Date(inputDate);

    // Extract the components of the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Construct the formatted date string
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;

    return formattedDate;
  }

  buildForm() {
    const formGroupFields = this.formatFormControlsFields();
    this.quickSearchForm = new FormGroup(
      { ...formGroupFields, 'limit-slider-control': new FormControl(null) },
      this.quickSearchFormValidator
    );
  }

  quickSearchFormValidator(g: FormGroup): ValidationErrors | null {
    const values = g.getRawValue();
    const keys = Object.keys(values);
    let hasDateRange = false;
    let isOutstandingSearch = false;
    for (let i = 0, len = keys.length; i < len; i++) {
      if (
        ['call', 'transmit', 'entry', 'workToBegin', 'completion'].includes(keys[i]) &&
        values[keys[i]] !== null &&
        values[keys[i]].upper !== null &&
        values[keys[i]].lower !== null
      ) {
        hasDateRange = true;
        break;
      }
      if (keys[i] === 'outstanding' && values[keys[i]] === true) {
        isOutstandingSearch = true;
        break;
      }
    }
    const hasRequestNumber = values['request#'] !== null && values['request#'] !== '';
    const hasUser = values['assignedTo'] !== null && values['assignedTo'] !== '';
    if (hasRequestNumber || hasUser || hasDateRange || isOutstandingSearch) {
      return null;
    } else if (!hasDateRange) {
      //set date true because any other fields require date to be there
      return { date: true } as ValidationErrors;
    }
    for (const key of keys) {
      if (values[key] !== null && values[key] !== '' && typeof values[key] !== 'object') {
        return null;
      } else if (Array.isArray(values[key]) && values[key].length > 0) {
        return null;
      }
    }
    return { values: true } as ValidationErrors;
  }

  formatFormControlsFields() {
    const formGroupFields = {};
    this.searchFields.map((field) => {
      field.name = spacedStringToCamelCase(field.title);
      formGroupFields[field.name] = new FormControl(null);
      field.formControl = formGroupFields[field.name];
    });
    this.dateFields.map((field) => {
      field.name = spacedStringToCamelCase(field.title);
      const { upper, lower } = field.default ?? { upper: null, lower: null };
      formGroupFields[field.name] = new FormControl(
        {
          lower,
          upper,
        },
        { validators: this.validDateRange }
      );
      field.formGroup = formGroupFields[field.name];
    });
    return formGroupFields;
  }

  validDateRange(g: FormGroup): ValidationErrors | null {
    const { lower, upper } = g.getRawValue() ?? { lower: null, upper: null };
    if (lower !== null && upper !== null) {
      if (lower <= upper) {
        return null;
      } else {
        return {
          'upper is less than lower in date-range': true,
        } as ValidationErrors;
      }
    } else if (lower === null && upper === null) {
      return null;
    } else {
      return {
        'one date value is null': true,
      } as ValidationErrors;
    }
  }

  clearDateRangePicker(formGroup: FormGroup, ref: MatDateRangePicker<any>) {
    formGroup.reset();
    ref.close();
  }

  formatLabel(value: number): string {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return `${value}`;
  }

  populateSearchFields() {
    return [
      {
        title: 'Request #',
        type: 'numbers',
        dataTypeID: 10,
        filterID: 65,
      },
      {
        title: 'Address',
        type: 'text',
        dataTypeID: 2,
        filterID: 8,
      },
      {
        title: 'Region',
        type: 'select',
        choices: this.formData.regions,
        dataTypeID: 4,
        filterID: 53,
      },
      {
        title: 'Assigned To',
        type: 'select',
        choices: this.formData.locators,
        dataTypeID: 4,
        filterID: 12,
      },
      {
        title: 'Completed By',
        type: 'select',
        choices: this.formData.locators,
        dataTypeID: 4,
        filterID: 13,
      },
      {
        title: 'Status',
        type: 'select',
        choices: this.formData.locateStatus,
        dataTypeID: 4,
        filterID: 3,
      },
      {
        title: 'Sub-status',
        type: 'select',
        choices: this.formData.subStatus,
        dataTypeID: 4,
        filterID: 39,
      },
      {
        title: 'Call Type',
        type: 'select',
        choices: this.formData.callTypes,
        dataTypeID: 4,
        filterID: 5,
      },
      {
        title: 'Utility',
        type: 'select',
        choices: this.formData.utilities,
        dataTypeID: 4,
        filterID: 6,
      },
      {
        title: 'Area Name',
        type: 'select',
        choices: this.formData.areaName,
        dataTypeID: 4,
        filterID: 15,
      },
      {
        title: 'Sub #',
        type: 'number',
        dataTypeID: 6,
        filterID: 30,
      },
      {
        title: 'Excavator',
        type: 'text',
        dataTypeID: 2,
        filterID: 14,
      },
      {
        title: 'City',
        type: 'text',
        dataTypeID: 2,
        filterID: 9,
      },
      {
        title: 'Tag',
        type: 'select',
        choices: this.ticketSearchService.getFilterFromID(51).options.map((x) => ({
          name: x.Tags,
          value: x.id,
        })),
        dataTypeID: 4,
        filterID: 51,
      },
      {
        title: 'outstanding',
        type: 'boolean',
        dataTypeID: 7,
        filterID: 20,
      },
    ];
  }

  populateDateFields() {
    return [
      {
        title: 'Call',
        type: 'dateRange',
        dataTypeID: 5,
        filterID: 2,
        default: this.datetimeService.getDateWindow('years', 1),
      },
      {
        title: 'Transmit',
        type: 'dateRange',
        dataTypeID: 5,
        filterID: 40,
      },
      {
        title: 'Entry',
        type: 'dateRange',
        dataTypeID: 5,
        filterID: 41,
      },
      {
        title: 'Work to Begin',
        type: 'dateRange',
        dataTypeID: 5,
        filterID: 4,
      },
      {
        title: 'Completion',
        type: 'dateRange',
        dataTypeID: 5,
        filterID: 11,
      },
    ];
  }
}

// Interfaces && types

export type SearchField = {
  title: string;
  name?: string;
  type: string;
  dataTypeID: number;
  filterID: number;
  choices?: { name; value }[];
  value?: string | number;
  formControl?: FormControl;
};

type DateRange = {
  lower?: any;
  upper?: any;
  formGroup?: FormGroup;
  default?: { lower: any; upper: any };
} & SearchField;

type dropDownOption = {
  name: string;
  value: number;
};

type FormDropdownData = {
  locators?: dropDownOption[];
  utilities?: dropDownOption[];
  callTypes?: dropDownOption[];
  subStatus?: dropDownOption[];
  locateStatus?: dropDownOption[];
  regions?: dropDownOption[];
  areaName?: dropDownOption[];
};
