import {
  Component,
  OnInit,
  NgModule,
  Renderer2,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SchedulerMainPanelComponent } from "./panels/scheduler-main-panel/scheduler-main-panel.component";
import { SchedulerFilterPanelComponent } from "./panels/scheduler-filter-panel/scheduler-filter-panel.component";
import { SchedulerViewComponent } from "./views/scheduler-view/scheduler-view.component";
import { SchedulerShowHideRigsPanelComponent } from "./panels/scheduler-show-hide-resources-panel.component/scheduler-show-hide-resources-panel.component";
import { SchedulerTicketPanelComponent } from "./panels/scheduler-ticket-panel/scheduler-ticket-panel.component";
import { SchedulerFilterViewComponent } from "./views/scheduler-filter-view/scheduler-filter-view.component";
import { SchedulerTicketViewComponent } from "./views/scheduler-ticket-view/scheduler-ticket-view.component";
import {
  DropDownListAllModule,
  MultiSelectAllModule,
} from "@syncfusion/ej2-angular-dropdowns";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TreeViewAllModule } from "@syncfusion/ej2-angular-navigations";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { SchedulerAdvancedSearchDialogComponent } from "./dialogs/scheduler-advanced-search-dialog/scheduler-advanced-search-dialog.component";
import { ActivatedRoute, Router } from "@angular/router";
import { SchedulerService } from "./services/scheduler.service";
import { DatePickerModule } from "@syncfusion/ej2-angular-calendars";
import { SchedulerOptionsDialogComponent } from "./dialogs/scheduler-options-dialog/scheduler-options-dialog.component";
import { SchedulerEditEventOptionsModule } from "./dialogs/scheduler-edit-event-options/scheduler-edit-event-options.component";
import { SchedulerCreateTicketPanelComponent } from "./panels/scheduler-create-ticket-panel/scheduler-create-ticket-panel.component";
import { SchedulerCreateTicketViewComponent } from "./views/scheduler-create-ticket-view/scheduler-create-ticket-view.component";
import { ComponentMessagingService } from "../core/component-messaging/component-messaging.service";
import {
  NavigationRailModule,
  navRailOptions,
} from "./navigation-rail/navigation-rail.component";
import { bottomNavOptions } from "../shared/bottom-navigation/bottom-navigation/bottom-navigation.component";
import {
  Resource,
  SchedulerEventActions,
  SchedulerTicketActions,
  Ticket,
  TicketTag,
  Event,
  EventType,
  SchedulerGetTicketByAssignmentIDVal,
} from "./syncfusion-scheduler/schedulerTypes";
import { LoggerService } from "../core/services/logger/logger.service";
import { ProgressBarService } from "../shared/progress-bar/progress-bar.service";
import {
  ComponentMessage,
  MessageAction,
} from "../core/component-messaging/component-message";
import { MaterialModule } from "../shared/material.module";
import { BottomNavigationModule } from "../shared/bottom-navigation/bottom-navigation.module";
import { AuthenticationService } from "../core/authentication/authentication.service";
import { CreateTicketComponentModule } from "../create-ticket/create-ticket/create-ticket.component";
import { SchedulerCreateTicketDialogModule } from "./dialogs/scheduler-create-ticket-dialog/scheduler-create-ticket-dialog.module";
import { SchedulerCancelTicketDialogModule } from "./dialogs/scheduler-cancel-ticket-dialog/scheduler-cancel-ticket-dialog.module";
import { SnackbarService } from "src/app/modules/shared/snackbar/snackbar.service";
import { SnackbarType } from "src/app/modules/shared/snackbar/snackbar/snackbar";
import { SyncfusionSchedulerComponent } from "./syncfusion-scheduler/syncfusion-scheduler.component";
import { localStorageKeys } from "src/app/LOCAL_STORAGE";
import { DatetimeService } from "../core/services/datetime/datetime.service";

@Component({
  selector: "app-scheduler",
  templateUrl: "./scheduler.component.html",
  styleUrls: ["./scheduler.component.scss"],
  providers: [ComponentMessagingService],
  encapsulation: ViewEncapsulation.None,
})
export class SchedulerPageComponent implements OnInit, AfterViewInit {
  className = "SchedulerPageComponent";

  showFilterPanel: boolean = false;
  showShowHideResourcesPanel: boolean = false;
  showTicketPanel: boolean = false;
  showCreateTicketPanel: boolean = false;
  childProps: any;

  topNav: navRailOptions[];
  bottomNav: navRailOptions[];
  allNav: bottomNavOptions[];

  private RIG_RESOURCE_TYPE_ID = 2;
  private DRILLER_RESOURCE_TYPE_ID = 1;
  private HELPER_RESOURCE_TYPE_ID = 3;
  private NOTE_RESOURCE_ID = 4;
  private RESOURCE_EQUIPMENT_RESOURCE_ID = 5;

  resources: Resource[] = [];

  rigResources: Resource[] = [];
  activeRigResources: Resource[] = [];
  peopleResources: Resource[] = [];
  drillerResources: Resource[] = [];
  helperResources: Resource[] = [];
  resourceEquipmentResources: Resource[] = [];
  ticketTags: TicketTag[] = [];
  events: Event[] = [];
  parentEvents: Event[] = [];
  tickets: Ticket[] = [];

  readonly: boolean = true;

  constructor(
    private logger$: LoggerService,
    private compMsg$: ComponentMessagingService,
    private _renderer2: Renderer2,
    private router$: Router,
    private schedulerService$: SchedulerService,
    private auth$: AuthenticationService,
    private routerService: Router,
    private route: ActivatedRoute,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService,
    private datetimeService: DatetimeService
  ) {
    // set up panel props
    this.childProps = {
      messageService: compMsg$,
    };

    // set up panel listeners
    this.compMsg$.getMessageStream().subscribe((nextMsg: ComponentMessage) => {
      this.onReceivedMessage(nextMsg);
    });

    this.topNav = [
      {
        id: "refresh",
        icon: "refresh",
        label: "Refresh",
        action: this.refreshSchedule,
      },
      // {
      //   id: "createTicket",
      //   icon: "add",
      //   label: "Create",
      //   action: this.createTicket,
      // },
      {
        id: "search",
        icon: "search",
        label: "Tickets",
        action: this.searchTickets,
      },
      {
        id: "filter",
        icon: "filter_list_alt",
        label: "Filter",
        action: this.filterEvents,
      },
      {
        id: "showHideResources",
        icon: "filter_list_alt",
        label: "Toggle Resources",
        action: this.filterRigs,
      },
    ];

    this.bottomNav = [
      // {
      //   id: "setting",
      //   icon: "settings",
      //   label: "Settings",
      //   action: this.openSettings,
      // },
    ];

    this.allNav = [...this.topNav, ...this.bottomNav];
  }

  ngOnInit(): void {
    const newQueryParams = this.cleanURLQueryParams();
    this.routerService.navigate([], {
      queryParams: newQueryParams,
      queryParamsHandling: "merge",
      replaceUrl: true,
    });

    this.progressBarService.stop();
    const userCat = this.auth$.getValueFromUser("USERCATEGORYID");

    if (userCat == 6) {
      this.readonly = false;
    } else {
      this.snackBarService.openSnackbar(
        "The Scheduler is in READ-ONLY",
        SnackbarType.default,
      );
    }
  }

  private cleanURLQueryParams(): object {
    const queryParams: any = this.route.snapshot.queryParams;
    const queryParamsKeys: any = Object.keys(queryParams);
    const newQueryParams = {};

    try {
      const visisbleURLKeys: any =
        this.route.snapshot.routeConfig.data.VISIBLE_URL_KEYS;
      const len = queryParamsKeys.length;
      for (let i = 0; i < len; i++) {
        const queryParamsKey = queryParamsKeys[i];
        if (
          visisbleURLKeys &&
          visisbleURLKeys.indexOf(queryParamsKey.toLowerCase()) > -1
        ) {
          newQueryParams[queryParamsKey] = queryParams[queryParamsKey];
        } else {
          newQueryParams[queryParamsKey] = null;
        }
      }
      return newQueryParams;
    } catch (error) {
      console.log(error);
    }
    return newQueryParams;
  }

  ngAfterViewInit() {
    this.getResources();
    this.getEvents();
    this.getTickets();
    this.getTags();
  }

  // Message Receiving Functions

  onReceivedMessage(compMessage: ComponentMessage) {
    if (compMessage && compMessage.message) {
      if (compMessage.message.reloadEvents) {
        this.getTickets();
        this.getEvents();
      }
    }
  }

  // Data Gathering Functions

  getTickets() {
    this.schedulerService$
      .getTickets({
        action: SchedulerTicketActions.GetTickets,
      })
      .subscribe((response) => {
        this.logger$.log("SchedulerPageComponent: get tickets: ", response);
        if (
          response.status == 1 &&
          response.value != null &&
          response.value.Tickets != null
        ) {
          this.tickets = [];
          this.setTickets(response.value.Tickets);
          this.tickets = [...this.tickets];
        }
      });
  }

  getResources() {
    this.schedulerService$.getAllResources().subscribe((response) => {
      this.logger$.log("SchedulerPageComponent: get resources: ", response);
      if (
        response.status == 1 &&
        response.value != null &&
        response.value.Resources != null
      ) {
        this.setResources(response.value.Resources);
        this.splitResourcesByType();
        this.resources = [...this.resources];
      }
    });
  }

  getEvents() {
    let allEvents = [];
    let allParentEvents = [];
    const ticketAssignmentIDsMissing = [];

    this.schedulerService$
      .getEventsObj({
        action: SchedulerEventActions.GetEventObj,
      })
      .subscribe((response) => {
        if (
          response.status == 1 &&
          response.value != null &&
          response.value.Events != null
        ) {
          const events = response.value.Events;
          const parentEvents = response.value.ParentEvents;

          allEvents = allEvents.concat(events);
          allParentEvents = allParentEvents.concat(parentEvents);

          // If there are no more rows, set events and get missing tickets
          this.events = [];
          this.setEvents(allEvents);
          this.events = [...this.events];
          this.parentEvents = [...this.parentEvents];

          const checkAndPushMissingAssignmentIDs = (events) => {
            for (const event of events) {
              const assignmentIDToCheck = event.AssignmentID;
              if (assignmentIDToCheck && !this.tickets.some(ticket => ticket.AssignmentID === assignmentIDToCheck)) {
                ticketAssignmentIDsMissing.push(assignmentIDToCheck);
              }
            }
          };

          if (this.events) checkAndPushMissingAssignmentIDs(this.events);
          if (this.parentEvents) checkAndPushMissingAssignmentIDs(this.parentEvents);

          ticketAssignmentIDsMissing.forEach(assignmentID => this.getTicketByAssignmentID(assignmentID));
          const currentViewStartDate = new Date(localStorage.getItem(localStorageKeys.SCHEDULER_DATE));
          const currentViewEndDate = new Date();
          currentViewEndDate.setMonth(currentViewStartDate.getMonth() + 1);
          console.log(`getting events for date range ${currentViewStartDate} to ${currentViewEndDate}`);
          this.getEventsByDateRange({ message: { startDate: currentViewStartDate, endDate: currentViewEndDate } });
        }
      });
  }



  /**
   * Gets events by a date range
   * Query is 'date >= start and date <= end'
   * @param Start the start date
   * @param End the end date
   */
  getEventsByDateRange(event) {
    const Start = event.message.startDate;
    const End = event.message.endDate;

    //if i have something within my date range, i probably don't need to get something
    this.schedulerService$
      .getEventsObj({
        action: SchedulerEventActions.GetEventObj,
        DateRange: {
          Start: Start,
          End: End,
        },
      })
      .subscribe((response) => {
        if (
          response.status == 1 &&
          response.value != null &&
          response.value.Events != null
        ) {
          const newEvents = this.formatEvents(response.value.Events);
          const eventsToAdd = newEvents.filter((newEvent) => {
            return !this.events.some(
              (existingEvent) => existingEvent.EventID === newEvent.EventID,
            );
          });
          this.events = this.events.concat(eventsToAdd);
          this.parentEvents = [...this.parentEvents];

          const ticketAssignmentIDsMissing = [];

          const checkAndPushMissingAssignmentIDs = (events) => {
            for (const event of events) {
              const assignmentIDToCheck = event.AssignmentID;
              if (assignmentIDToCheck && !this.tickets.some(ticket => ticket.AssignmentID === assignmentIDToCheck)) {
                ticketAssignmentIDsMissing.push(assignmentIDToCheck);
              }
            }
          };

          checkAndPushMissingAssignmentIDs(this.events);
          checkAndPushMissingAssignmentIDs(this.parentEvents);
          const promiseArr = [];
          this.progressBarService.start();
          ticketAssignmentIDsMissing.forEach(assignmentID => promiseArr.push(this.getTicketByAssignmentID(assignmentID)));
          Promise.all(promiseArr).then(() => {
            this.progressBarService.stop();
          });

          console.log("set events and parent events");
        }
      });
  }


  async getTicketByAssignmentID(assignmentID: number) {
    try {
      const apiBody: SchedulerGetTicketByAssignmentIDVal = {
        action: SchedulerTicketActions.GetTicketByAssignmentID,
        AssignmentID: assignmentID,
      };

      const result = await this.schedulerService$.getTicketByAssignmentID(apiBody).toPromise();

      if (
        result["status"] == 1 &&
        result["value"] &&
        result["value"]["Tickets"] &&
        result["value"]["Tickets"].length > 0
      ) {
        this.tickets.push(result["value"]["Tickets"][0]);
        this.tickets = [...this.tickets];
      }
    } catch (error) {
      console.error("getTicketByAssignmentID: ", error);
    }
  }


  getTags() {
    this.schedulerService$.getTicketTags().subscribe((response) => {
      this.logger$.log("SchedulerPageComponent: get ticketTags: ", response);
      if (response["status"] != "-1") {
        if (Array.isArray(response["TicketTags"])) {
          this.setTags(response["TicketTags"]);
          this.ticketTags = [...this.ticketTags];
        } else if (Array.isArray(response["value"]["TicketTags"])) {
          this.setTags(response["value"]["TicketTags"]);
          this.ticketTags = [...this.ticketTags];
        }
      }
    });
  }

  // Data Manipulation Functions

  splitResourcesByType() {
    this.rigResources = [];
    this.peopleResources = [];
    this.drillerResources = [];
    this.helperResources = [];
    this.resourceEquipmentResources = [];

    this.resources.forEach((resource) => {
      if (resource.ResourceTypeID) {
        if (
          resource.ResourceTypeID == this.RIG_RESOURCE_TYPE_ID ||
          resource.ResourceTypeID == this.NOTE_RESOURCE_ID
        ) {
          this.rigResources.push(resource);
        } else if (resource.ResourceTypeID == this.HELPER_RESOURCE_TYPE_ID) {
          this.peopleResources.push(resource);
          this.helperResources.push(resource);
        } else if (resource.ResourceTypeID == this.DRILLER_RESOURCE_TYPE_ID) {
          this.peopleResources.push(resource);
          this.drillerResources.push(resource);
        } else if (
          resource.ResourceTypeID == this.RESOURCE_EQUIPMENT_RESOURCE_ID
        ) {
          this.resourceEquipmentResources.push(resource);
        }
      }
    });
    this.activeRigResources = this.rigResources;
    this.peopleResources.sort(function (a, b) {
      if (a.ResourceName.toUpperCase() < b.ResourceName.toUpperCase()) {
        return -1;
      } else {
        return 1;
      }
    });
    this.drillerResources.sort(function (a, b) {
      if (a.ResourceName.toUpperCase() < b.ResourceName.toUpperCase()) {
        return -1;
      } else {
        return 1;
      }
    });
    this.helperResources.sort(function (a, b) {
      if (a.ResourceName.toUpperCase() < b.ResourceName.toUpperCase()) {
        return -1;
      } else {
        return 1;
      }
    });
    this.resourceEquipmentResources.sort(function (a, b) {
      if (a.ResourceName.toUpperCase() < b.ResourceName.toUpperCase()) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  formatEvents(events: Object[]) {
    const eventData = [];

    events.forEach((event) => {
      if (
        event["EventID"] != null &&
        event["EventStartDateTime"] != null &&
        event["EventEndDateTime"] != null &&
        event["EventTypeID"] != null &&
        event["Resources"] != null &&
        Array.isArray(event["Resources"])
      ) {
        try {
          // get the resources sorted
          let rigResourceID: number;
          const peopleResourceIDs: number[] = [];
          const resourceEquipmentIDs: number[] = [];

          event["Resources"].forEach((resource) => {
            if (
              resource["ResourceTypeID"] == this.RIG_RESOURCE_TYPE_ID ||
              resource.ResourceTypeID == this.NOTE_RESOURCE_ID
            ) {
              rigResourceID = resource["ResourceID"];
            } else if (
              resource["ResourceTypeID"] == this.DRILLER_RESOURCE_TYPE_ID ||
              resource["ResourceTypeID"] == this.HELPER_RESOURCE_TYPE_ID
            ) {
              peopleResourceIDs.push(resource["ResourceID"]);
            } else if (
              resource["ResourceTypeID"] == this.RESOURCE_EQUIPMENT_RESOURCE_ID
            ) {
              resourceEquipmentIDs.push(resource["ResourceID"]);
            }
          });

          let eventName = "";
          if (event["EventTypeID"] == 2) {
            if (
              event["Ticket"] != null &&
              event["Ticket"]["ExcavatorCompany"] != null
            ) {
              eventName = event["Ticket"]["ExcavatorCompany"];
            }
          } else {
            eventName = event["EventName"];
          }

          // make base event
          const newEvent: Event = {
            EventID: event["EventID"],
            StartTime: new Date(event["EventStartDateTime"]),
            EndTime: new Date(event["EventEndDateTime"]),
            ResourceID: rigResourceID,
            EventName: eventName,
            PeopleResourceIDs: peopleResourceIDs,
            ResourceEquipmentIDs: resourceEquipmentIDs,
            EventTypeID: event["EventTypeID"],
            JobNumber: " ",
          };

          // add notes to event
          if (event["EventNotes"]) {
            newEvent.EventNotes = event["EventNotes"];
          }

          if (event["EventColour"]) {
            newEvent.EventColour = event["EventColour"];
          } else if (newEvent.EventTypeID == 3) {
            newEvent.EventColour = "#fff0ab";
          } else {
            newEvent.EventColour = "#ffffff";
          }

          if (event["UserResponsibleID"]) {
            newEvent.UserResponsibleID = event["UserResponsibleID"];
          }

          if (event["UserResponsibleResourceID"]) {
            newEvent.UserResponsibleResourceID =
              event["UserResponsibleResourceID"];
          }

          // add ticket info to event
          const ticket = event["Ticket"];
          if (ticket) {
            if (ticket["AssignmentID"]) {
              newEvent.AssignmentID = ticket["AssignmentID"];
            }

            if (ticket["Location"]) {
              newEvent.Location = ticket["Location"];
            }

            if (ticket["City"]) {
              newEvent.City = ticket["City"];
            }

            if (ticket["TicketTags"]) {
              newEvent.Tags = ticket["TicketTags"];
            }

            if (ticket["ContactName"]) {
              newEvent.ContactName = ticket["ContactName"];
            }

            if (ticket["ExtentOfWork"]) {
              newEvent.ExtentOfWork = ticket["ExtentOfWork"];
            }

            if (ticket["UserResponsible"]) {
              newEvent.UserResponsible = ticket["UserResponsible"];
            }
          }

          // if the event is a parent child type set the parentEventID
          if (event["EventTypeID"] == EventType.PARENT_CHILD) {
            let eventStart: Date;

            if (event["ParentEventID"] != null) {
              const parentEvent = this.getParentEvent(event["ParentEventID"]);

              if (parentEvent == null) {
                this.getEventByID(event["ParentEventID"])
                  .then((parentEvent) => {
                    eventStart = parentEvent["StartTime"];
                    newEvent.ParentEventID = event["ParentEventID"];
                    this.continueProcessing(newEvent, eventStart, event);
                  })
                  .catch((error) => {
                    // Handle the error if needed
                    console.error(error);
                  });
              } else {
                eventStart = parentEvent["StartTime"];
                newEvent.ParentEventID = event["ParentEventID"];
                this.continueProcessing(newEvent, eventStart, event);
              }
            } else {
              eventStart = new Date(event["EventStartDateTime"]);
              newEvent.ParentEventID = null;
              const eventIDToAdd = newEvent.EventID;

              // Check if the event with the same eventID already exists in this.parentEvents
              if (
                !this.parentEvents.some(
                  (existingEvent) => existingEvent.EventID === eventIDToAdd,
                )
              ) {
                this.parentEvents.push(newEvent);
              }

              this.continueProcessing(newEvent, eventStart, event);
            }
          }

          eventData.push(newEvent);
        } catch (ex) {
          this.logger$.error("Error Creating Event: ", ex);
          this.logger$.error("Error Creating Event: ", event);
        }
      } else {
        this.logger$.error(
          "Error Creating Event: Event didn't have required information",
        );
      }
    });

    // Return the object containing events and parentEvents
    return eventData;
  }

  /**
   * Sets the current active rigs to the input 
   *
   * @param {Resource[]} newActiveValue
   * @memberof SchedulerPageComponent
   */
  updateActiveRigs(newActiveValue: Resource[]) {
    this.activeRigResources = newActiveValue;
  }

  continueProcessing(newEvent, eventStart, event) {
    // set the Eventstart/end
    newEvent.EventStart = eventStart;
    newEvent.EventEnd = new Date(event["ProjectEventEndDate"]);

    if (event["Ticket"] && event["Ticket"]["JobNumber"]) {
      const mod = this.getJobNumberModifier(
        newEvent.EventStart,
        newEvent.StartTime,
      );
      // eslint-disable-next-line no-self-assign
      newEvent.EventName = newEvent.EventName;
      newEvent.JobNumber = event["Ticket"]["JobNumber"] + mod;
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  setEvents(events: Object[]) {
    events.forEach((event) => {
      if (
        event["EventID"] != null &&
        event["EventStartDateTime"] != null &&
        event["EventEndDateTime"] != null &&
        event["EventTypeID"] != null &&
        event["Resources"] != null &&
        Array.isArray(event["Resources"])
      ) {
        try {
          // get the resources sorted
          let rigResourceID: number;
          const peopleResourceIDs: number[] = [];
          const resourceEquipmentIDs: number[] = [];

          event["Resources"].forEach((resource) => {
            if (
              resource["ResourceTypeID"] == this.RIG_RESOURCE_TYPE_ID ||
              resource.ResourceTypeID == this.NOTE_RESOURCE_ID
            ) {
              rigResourceID = resource["ResourceID"];
            } else if (
              resource["ResourceTypeID"] == this.DRILLER_RESOURCE_TYPE_ID ||
              resource["ResourceTypeID"] == this.HELPER_RESOURCE_TYPE_ID
            ) {
              peopleResourceIDs.push(resource["ResourceID"]);
            } else if (
              resource["ResourceTypeID"] == this.RESOURCE_EQUIPMENT_RESOURCE_ID
            ) {
              resourceEquipmentIDs.push(resource["ResourceID"]);
            }
          });

          let eventName = "";
          if (event["EventTypeID"] == 2) {
            if (
              event["Ticket"] != null &&
              event["Ticket"]["ExcavatorCompany"] != null
            ) {
              eventName = event["Ticket"]["ExcavatorCompany"];
            }
          } else {
            eventName = event["EventName"];
          }

          event["EventStartDateTime"] = this.datetimeService.dbDateToFormattedLocalDate(event["EventStartDateTime"]);
          event["EventEndDateTime"] = this.datetimeService.dbDateToFormattedLocalDate(event["EventEndDateTime"]);
          event["ProjectEventEndDate"] = this.datetimeService.dbDateToFormattedLocalDate(event["ProjectEventEndDate"]);

          // make base event
          const newEvent: Event = {
            EventID: event["EventID"],
            StartTime: new Date(event["EventStartDateTime"]),
            EndTime: new Date(event["EventEndDateTime"]),
            ResourceID: rigResourceID,
            EventName: eventName,
            PeopleResourceIDs: peopleResourceIDs,
            ResourceEquipmentIDs: resourceEquipmentIDs,
            EventTypeID: event["EventTypeID"],
            JobNumber: " ",
          };

          // add notes to event
          if (event["EventNotes"]) {
            newEvent.EventNotes = event["EventNotes"];
          }

          if (event["EventColour"]) {
            newEvent.EventColour = event["EventColour"];
          } else if (newEvent.EventTypeID == 3) {
            newEvent.EventColour = "#fff0ab";
          } else {
            newEvent.EventColour = "#ffffff";
          }

          if (event["UserResponsibleID"]) {
            newEvent.UserResponsibleID = event["UserResponsibleID"];
          }

          if (event["UserResponsibleResourceID"]) {
            newEvent.UserResponsibleResourceID =
              event["UserResponsibleResourceID"];
          }

          // add ticket info to event
          const ticket = event["Ticket"];
          if (ticket) {
            if (ticket["AssignmentID"]) {
              newEvent.AssignmentID = ticket["AssignmentID"];
            }

            if (ticket["Location"]) {
              newEvent.Location = ticket["Location"];
            }

            if (ticket["City"]) {
              newEvent.City = ticket["City"];
            }

            if (ticket["TicketTags"]) {
              newEvent.Tags = ticket["TicketTags"];
            }

            if (ticket["ContactName"]) {
              newEvent.ContactName = ticket["ContactName"];
            }

            if (ticket["ExtentOfWork"]) {
              newEvent.ExtentOfWork = ticket["ExtentOfWork"];
            }

            if (ticket["UserResponsible"]) {
              newEvent.UserResponsible = ticket["UserResponsible"];
            }
          }

          // if the event is a parent child type set the parentEventID
          if (event["EventTypeID"] == EventType.PARENT_CHILD) {
            let eventStart: Date;
            if (event["ParentEventID"] != null) {
              eventStart = this.getParentEvent(event["ParentEventID"])[
                "StartTime"
              ];
              newEvent.ParentEventID = event["ParentEventID"];
            } else {
              eventStart = new Date(event["EventStartDateTime"]);
              newEvent.ParentEventID = null;
              this.parentEvents.push(newEvent);
            }

            // set the Eventstart/end
            newEvent.EventStart = eventStart;
            newEvent.EventEnd = new Date(event["ProjectEventEndDate"]);

            if (event["Ticket"] && event["Ticket"]["JobNumber"]) {
              const mod = this.getJobNumberModifier(
                newEvent.EventStart,
                newEvent.StartTime,
              );
              // eslint-disable-next-line no-self-assign
              newEvent.EventName = newEvent.EventName;
              newEvent.JobNumber = event["Ticket"]["JobNumber"] + mod;
            }
          }

          this.events.push(newEvent);
        } catch (ex) {
          this.logger$.error("Error Creating Event: ", ex);
        }
      } else {
        this.logger$.error(
          "Error Creating Event: Event didn't have required information",
        );
      }
    });
  }

  //Move to on render?
  getJobNumberModifier(date1: Date, date2: Date) {
    const d1: Date = new Date(date1);
    const d2: Date = new Date(date2);
    let mod = "";
    const dayOfWeek = d1.getDay();
    // Move sunday to end of week
    // Sunday - Saturday : 0 - 6
    // if (dayOfWeek == -1) {
    //   // sunday
    //   dayOfWeek = 0;
    // }
    d1.setDate(d1.getDate() - dayOfWeek);
    const weeks = Math.floor(
      (d2.getTime() - d1.getTime()) / (7 * 24 * 60 * 60 * 1000),
    );
    if (weeks > 0) {
      mod = " - " + String.fromCharCode(weeks + "A".charCodeAt(0));
    }

    return mod;
  }

  getMaxEventID(events): number {
    let eventID = 0;
    for (let i = 0; i < events.length; i++) {
      if (events[i].EventID > eventID) {
        eventID = events[i].EventID;
      }
    }
    return eventID + 1;
  }

  getParentEvent(parentEventID: number): Event {
    let parentEvent: Event = null;
    for (let i = 0; i < this.parentEvents.length; i++) {
      if (this.parentEvents[i]["EventID"] == parentEventID) {
        parentEvent = this.parentEvents[i];
        break;
      }
    }
    return parentEvent;
  }

  getEventByID(eventID: number): Promise<Event> {
    return new Promise((resolve, reject) => {
      this.schedulerService$
        .getEventsObj({
          action: SchedulerEventActions.GetEventObj,
          EventIDArr: [eventID],
        })
        .subscribe((response) => {
          if (
            response.status === 1 &&
            response.value != null &&
            response.value.Events != null
          ) {
            const newEvents = this.formatEvents(response.value.Events);
            resolve(newEvents[0]);
          } else {
            reject("Event not found or an error occurred.");
          }
        });
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  setResources(resources: Object[]) {
    resources.forEach((resource) => {
      if (
        resource["ResourceID"] != null &&
        resource["ResourceTypeID"] != null &&
        resource["ResourceName"] != null
      ) {
        try {
          const newResource: Resource = {
            ResourceID: resource["ResourceID"],
            ResourceTypeID: resource["ResourceTypeID"],
            ResourceName: resource["ResourceName"],
          };
          if (resource["ResourceDesc"] != null) {
            newResource.ResourceDesc = resource["ResourceDesc"];
          }
          if (resource["HexColour"] != null) {
            newResource.HexColour = resource["HexColour"];
          }
          if (resource["UserID"] != null) {
            newResource.UserID = resource["UserID"];
          }
          if (resource["Fullname"] != null) {
            newResource.Fullname = resource["Fullname"];
          }
          if (resource["bSingleton"] != null) {
            newResource.bSingleton = resource["bSingleton"];
          }
          this.resources.push(newResource);
        } catch (ex) {
          this.logger$.error("Error Creating Resource: ", ex);
        }
      } else {
        this.logger$.error(
          "Error Creating Resource: Resource didn't have required information",
        );
      }
    });
  }

  setTickets(tickets) {
    tickets.forEach((ticket) => {
      if (
        ticket["AssignmentID"] != null &&
        ticket["RequestNumber"] != null &&
        ticket["PrimaryID"] != null &&
        ticket["TicketTags"] != null
      ) {
        try {
          const tags = JSON.parse(ticket["TicketTags"]) as number[];
          const newTicket: Ticket = {
            AssignmentID: ticket["AssignmentID"],
            RequestNumber: ticket["RequestNumber"],
            PrimaryID: ticket["PrimaryID"],
            Tags: tags,
          };

          if (
            ticket["LocateSubRegionName"] != null &&
            ticket["LocateAddress"] != null
          ) {
            newTicket.Location =
              ticket["LocateSubRegionName"] +
              ", " +
              ticket["StartHouseNumber"] +
              " " +
              ticket["LocateAddress"];
            newTicket.Address =
              ticket["StartHouseNumber"] + " " + ticket["LocateAddress"];
            newTicket.City = ticket["LocateSubRegionName"];
          }

          if (ticket["LocateSubRegionName"] != null) {
            newTicket.City = ticket["LocateSubRegionName"];
          }

          if (ticket["CallDate"] != null) {
            newTicket.CallDate = new Date(ticket["CallDate"]);
          }

          if (ticket["ExcavationDate"] != null) {
            newTicket.ExcavationDate = new Date(ticket["ExcavationDate"]);
          }

          if (ticket["EntryDate"] != null) {
            newTicket.EntryDate = new Date(ticket["EntryDate"]);
          }

          if (ticket["LocateStatusDesc"] != null) {
            newTicket.LocateStatusDesc = ticket["LocateStatusDesc"];
          }

          if (ticket["LocateStatusID"] != null) {
            newTicket.LocateStatusID = ticket["LocateStatusID"];
          }

          if (ticket["CommentsToExcavator"] != null) {
            newTicket.Comments = ticket["CommentsToExcavator"];
          }

          if (ticket["ExcavatorName"]) {
            newTicket.ExcavatorCompany = ticket["ExcavatorName"];
          }

          if (ticket["Remarks"] != null) {
            newTicket.Comments = ticket["Remarks"];
          }

          // if (ticket["CallerName"] != null) {

          //   newTicket.CallerName = ticket["CallerName"];
          // }

          if (ticket["ExtentOfWork"] != null) {
            newTicket.ExtentOfWork = ticket["ExtentOfWork"];
          }

          if (ticket["ContactName"] != null) {
            newTicket.ContactName = ticket["ContactName"];
          }

          if (ticket["UserResponsible"] != null) {
            newTicket.UserResponsible = ticket["UserResponsible"];
          }

          if (ticket["JobNumber"] != null) {
            newTicket.JobNumber = ticket["JobNumber"];
          }

          if (ticket["isScheduled"] != null) {
            newTicket.isScheduled = ticket["isScheduled"];
          }

          if (ticket["PONum"] != null) {
            newTicket.PONumber = ticket["PONum"];
          }

          let identifierStr = "";
          if (newTicket.ExcavatorCompany) {
            identifierStr = newTicket.ExcavatorCompany;
          }

          if (newTicket.Location) {
            identifierStr = identifierStr + " - " + newTicket.Location;
          }
          newTicket.Identifier = identifierStr;

          this.tickets.push(newTicket);
        } catch (ex) {
          this.logger$.error("setTicket: ", ex);
        }
      }
    });
  }

  setTags(tags) {
    tags.forEach((tag) => {
      if (
        tag["TagID"] != null &&
        tag["TagName"] != null &&
        tag["HexColour"] != null
      ) {
        const newTag = {
          TagID: tag["TagID"],
          TagName: tag["TagName"],
          TagColour: tag["HexColour"],
        };
        this.ticketTags.push(newTag);
      }
    });
  }

  // Navigation Bar Functions

  setNavBtnColor(idPrefix) {
    const icon = document.getElementById(idPrefix + "-icon");
    this._renderer2.addClass(icon, "primary");
    const text = document.getElementById(idPrefix + "-text");
    this._renderer2.addClass(text, "primary");
    const iconBN = document.getElementById("bn-" + idPrefix + "-icon");
    this._renderer2.addClass(iconBN, "primary");
    const textBN = document.getElementById("bn-" + idPrefix + "-text");
    this._renderer2.addClass(textBN, "primary");
  }

  unsetNavBtnColor(idPrefix) {
    const icon = document.getElementById(idPrefix + "-icon");
    this._renderer2.removeClass(icon, "primary");
    const text = document.getElementById(idPrefix + "-text");
    this._renderer2.removeClass(text, "primary");
    const iconBN = document.getElementById("bn-" + idPrefix + "-icon");
    this._renderer2.removeClass(iconBN, "primary");
    const textBN = document.getElementById("bn-" + idPrefix + "-text");
    this._renderer2.removeClass(textBN, "primary");
  }

  createTicket = () => {
    this.refresh();
    if (this.showFilterPanel) {
      this.unsetNavBtnColor("filter");
      this.setNavBtnColor("createTicket");
    }
    if (this.showTicketPanel) {
      this.unsetNavBtnColor("search");
      this.setNavBtnColor("createTicket");
    }
    if (this.showCreateTicketPanel) {
      this.unsetNavBtnColor("createTicket");
    } else {
      this.setNavBtnColor("createTicket");
    }
    if (this.showShowHideResourcesPanel) {
      this.unsetNavBtnColor("showHideResources");
      this.setNavBtnColor("createTicket");
    }
    this.showFilterPanel = false;
    this.showTicketPanel = false;
    this.showShowHideResourcesPanel = false;
    this.showCreateTicketPanel = !this.showCreateTicketPanel;
  };

  searchTickets = () => {
    this.refresh();
    if (this.showFilterPanel) {
      this.unsetNavBtnColor("filter");
      this.setNavBtnColor("search");
    }
    if (this.showCreateTicketPanel) {
      this.unsetNavBtnColor("createTicket");
      this.setNavBtnColor("search");
    }
    if (this.showTicketPanel) {
      this.unsetNavBtnColor("search");
    } else {
      this.setNavBtnColor("search");
    }
    if (this.showShowHideResourcesPanel) {
      this.unsetNavBtnColor("showHideResources");
      this.setNavBtnColor("search");
    }
    this.showFilterPanel = false;
    this.showCreateTicketPanel = false;
    this.showShowHideResourcesPanel = false;
    this.showTicketPanel = !this.showTicketPanel;
  };

  filterEvents = () => {
    this.refresh();
    if (this.showTicketPanel) {
      this.unsetNavBtnColor("search");
      this.setNavBtnColor("filter");
    }
    if (this.showCreateTicketPanel) {
      this.unsetNavBtnColor("createTicket");
      this.setNavBtnColor("filter");
    }
    if (this.showFilterPanel) {
      this.unsetNavBtnColor("filter");
    } else {
      this.setNavBtnColor("filter");
    }
    if (this.showShowHideResourcesPanel) {
      this.unsetNavBtnColor("showHideResources");
      this.setNavBtnColor("filter");
    }
    this.showTicketPanel = false;
    this.showCreateTicketPanel = false;
    this.showShowHideResourcesPanel = false;
    this.showFilterPanel = !this.showFilterPanel;
  };

  filterRigs = () => {
    this.refresh();
    if (this.showTicketPanel) {
      this.unsetNavBtnColor("search");
      this.setNavBtnColor("showHideResources");
    }
    if (this.showCreateTicketPanel) {
      this.unsetNavBtnColor("createTicket");
      this.setNavBtnColor("showHideResources");
    }
    if (this.showFilterPanel) {
      this.unsetNavBtnColor("filter");
      this.setNavBtnColor("showHideResources");
    }
    if (this.showShowHideResourcesPanel) {
      this.unsetNavBtnColor("showHideResources");
    } else {
      this.setNavBtnColor("showHideResources");
    }
    this.showTicketPanel = false;
    this.showCreateTicketPanel = false;
    this.showFilterPanel = false;
    this.showShowHideResourcesPanel = !this.showShowHideResourcesPanel;
  };

  openSettings = () => {
    this.router$.navigate(["table", "11"]);
  };


  refresh() {
    const messageToSend: ComponentMessage = {
      action: MessageAction.READY,
      message: {
        refresh: true,
      },
      senderID: this.className,
    };
    this.compMsg$.sendToMessageStream(messageToSend);
  }

  refreshSchedule = () => {
    const messageToSend: ComponentMessage = {
      action: MessageAction.READY,
      message: {
        refreshSchedule: true,
      },
      senderID: this.className,
    };
    this.compMsg$.sendToMessageStream(messageToSend);
  };
}

@NgModule({
  declarations: [
    SchedulerPageComponent,
    SchedulerMainPanelComponent,
    SchedulerViewComponent,
    SchedulerFilterPanelComponent,
    SchedulerShowHideRigsPanelComponent,
    SchedulerTicketPanelComponent,
    SchedulerCreateTicketPanelComponent,
    SchedulerFilterViewComponent,
    SchedulerTicketViewComponent,
    SchedulerCreateTicketViewComponent,
    SchedulerAdvancedSearchDialogComponent,
    SchedulerOptionsDialogComponent,
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    FlexLayoutModule,
    DropDownListAllModule,
    FormsModule,
    ReactiveFormsModule,
    MultiSelectAllModule,
    TreeViewAllModule,
    DragDropModule,
    NavigationRailModule,
    BottomNavigationModule,
    DatePickerModule,
    CreateTicketComponentModule,
    SchedulerEditEventOptionsModule,
    SchedulerCreateTicketDialogModule,
    SchedulerCancelTicketDialogModule,
    SyncfusionSchedulerComponent,
  ],
  exports: [
    SchedulerPageComponent,
    SchedulerMainPanelComponent,
    SchedulerFilterPanelComponent,
    SchedulerShowHideRigsPanelComponent,
    SchedulerCreateTicketPanelComponent,
    SchedulerTicketPanelComponent,
    SchedulerFilterViewComponent,
    SchedulerCreateTicketViewComponent,
    SchedulerTicketViewComponent,
    SchedulerAdvancedSearchDialogComponent,
    SchedulerOptionsDialogComponent,
  ],
})
export class SchedulerModule { }
