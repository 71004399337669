<div *ngIf="canShowAnalyzerButton()" #MainContainer class="relative h-full max-w-60 min-w-36 overflow-hidden flex flex-col rounded-tl-md">
  <!-- Custom Panel Header -->
  <div
    class="bg-primary text-white"
    [ngClass]="{ 'w-[106px] custom-rounded-6 h-9 flex': !panelOpenState, 'w-60 rounded-tl-md h-[22px]': panelOpenState }">
    <div class="flex justify-start items-center" (click)="togglePanel()" tabindex="1">
      <mat-icon *ngIf="panelOpenState" class="text-white h-[18px]" svgIcon="drop_up" />
      <mat-icon *ngIf="!panelOpenState" class="text-white transform -rotate-90 h-[18px]" svgIcon="drop_down" />
      <span *ngIf="!panelOpenState" class="font-rajdhani text-md uppercase font-semibold select-none">Analyzer</span>
      <span *ngIf="panelOpenState" class="font-rajdhani text-sm uppercase font-semibold select-none">Supervisor Analyzer</span>

    </div>
  </div>

  <!-- Custom Panel Body -->
  <div *ngIf="panelOpenState" class="bg-white h-[calc(100%-22px)]">
    <div class="dense-8 h-36 p-3">
      <!-- Supervisor Filter -->
      <mat-form-field class="dropdown flex px-2 font-rajdhani uppercase font-semibold" appearance="outline">
        <mat-label>Supervisor Filter</mat-label>
        <mat-select [(ngModel)]="filterValue" name="supervisor">
          <mat-option
            *ngFor="let supervisor of supervisors"
            [value]="supervisor.value"
            (onSelectionChange)="updatedFilter($event, supervisor.value)">
            {{ supervisor.visibleValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Sorting Options -->
      <mat-form-field class="dropdown flex px-2 font-rajdhani uppercase font-semibold" appearance="outline">
        <mat-label>Sort</mat-label>
        <mat-select [(ngModel)]="sortValue" name="column">
          <mat-option
            *ngFor="let column of columns"
            [value]="column.value"
            (onSelectionChange)="updatedSort($event, column.value)">
            {{ column.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-radio-group [(ngModel)]="sortOrder" class="full-width">
        <mat-radio-button
          color="primary"
          class="sm-horz-margins"
          *ngFor="let columnSort of columnSorts"
          [value]="columnSort.value"
          (change)="updatedSortOrder($event, columnSort.value)">
          {{ columnSort.visibleValue }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-divider></mat-divider>
    <!-- List Container -->
    <cdk-virtual-scroll-viewport itemSize="184" #ListContainer class="w-full bg-white mb-2 h-[calc(100%-164px)]">
      <div *cdkVirtualFor="let json of filteredData">
        <app-sidebar-item
          #locatorSumComps
          [data]="json"
          (updateButtons)="updateComponentButtons($event)"></app-sidebar-item>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
