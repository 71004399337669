import { Injectable } from '@angular/core';
import { ApiService, UtilocateApiRequest } from '../core/api/baseapi.service';
import { api, apiKeys } from 'src/app/ENDPOINTS';
import { AdminActionValues, AdminActions, AdminCriteria, AdminCriteria2Operators, AdminCriteriaValues, AdminOperators, AdminRuleTypes, AdminTriggers, Rule } from './rule-builder-models';
import { ProgressBarService } from '../shared/progress-bar/progress-bar.service';
import { SnackbarService } from '../shared/snackbar/snackbar.service';
import { SnackbarType } from '../shared/snackbar/snackbar/snackbar';

@Injectable({
  providedIn: 'root'
})

export class AutomatedEngineService {

  AdminTables: {
    AdminRuleTypes: AdminRuleTypes[];
    AdminTriggers: AdminTriggers[];
    AdminCriteria: AdminCriteria[];
    AdminActions: AdminActions[];
    AdminOperators: AdminOperators[];
    AdminCriteria2Operators: AdminCriteria2Operators[];
    AdminActionValues: AdminActionValues[];
    AdminCriteriaValues: AdminCriteriaValues[];
  };

  Rules: Rule[];

  constructor(private utilocateAPIService: ApiService, 
    private progressBarService: ProgressBarService, 
    private snackBarService: SnackbarService) {

  }

  /**
   * Get the admin tables - but don't re-query unless you don't have a table 
   * @returns 
   */
  async getAdminTables () {
    if (!this.AdminTables || this.isEmpty(this.AdminTables)) {
      await this.queryAdminTables();
    }
    return this.AdminTables;
  }

  isEmpty(obj: any) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key].length === 0) {
        return true;
      }
    }
    return false;
  }

  async queryAdminTables() {
    this.AdminTables = {
      AdminRuleTypes: [],
      AdminTriggers: [],
      AdminCriteria: [],
      AdminActions: [],
      AdminOperators: [],
      AdminCriteria2Operators: [],
      AdminActionValues: [],
      AdminCriteriaValues: [],
    }


    const apiValue = {
      query: {
        AdminRuleTypes: {
          all: true
        },
        AdminTriggers: {
          all: true
        },
        AdminCriteria: {
          all: true
        },
        AdminActions: {
          all: true
        },
        AdminOperators: {
          all: true
        },
        AdminCriteria2Operators: {
          all: true
        },
        AdminCriteriaValues: {
          all: true
        }
      }
    };

    /**
     * the returns for the action values and action criteria can be large. 
     * in this case, we separate getting the possible values from the admin tables. 
     * This is beacuse if the values were large enough they could cause the query lambda to fail. 
     */
    const adminTables = await this.invokeAPIWithPayload(apiValue);
    const actionValues = await this.invokeAPIWithPayload({query: {AdminActionValues: {all: true}}}); //get the AdminAction values
    const criteriaValues = await this.invokeAPIWithPayload({query: {AdminCriteriaValues: {all: true}}}); //get the criteria values

    if (!adminTables) {
      this.snackBarService.openSnackbar(
        "Error getting Admin Tables",
        SnackbarType.warning
      );
    }

    if (!actionValues) {
      this.snackBarService.openSnackbar(
        "Error getting Action Values",
        SnackbarType.warning
      );
    }

    if (!criteriaValues) {
      this.snackBarService.openSnackbar(
        "Error getting Criteria Values",
        SnackbarType.warning
      );
    }

    this.AdminTables = {...adminTables, ...actionValues, ...criteriaValues};
  }

  async getRules(where: any = {all: true}) {
    const apiValue = {
      query: {
        Rules: where
      }
    };
    return await this.invokeAPIWithPayload(apiValue);
  }

  async getRuleByID(RuleID: number) {
    const apiValue = {
      query: {
        Rule: {
          RuleID: RuleID
        }
      }
    }
    //return the first rule, since there is only one rule with that ID
    const rule = (await this.invokeAPIWithPayload(apiValue)).Rule[0];
    if (rule.Actions == null) rule.Actions = [];
    rule.Actions?.sort((a, b) => a.OrderNumber - b.OrderNumber); //order actions by OrderNumber asc

    if (rule.Criteria == null) rule.Criteria = [];
    return rule;
  }

  removePropertiesFromObjects(objects, propertyNames) {
      objects.forEach(obj => {
        propertyNames.forEach(propName => {
          if (Object.prototype.hasOwnProperty.call(obj, propName)) {
            delete obj[propName];
          }
        });
      });
    }


  /**
   * Creates a new Rule 
   * @param {Rule} Rule 
   */
  async createRule(Rule: Rule) {
    
    const IDColumnnames = ['RuleID', 'Rule2CriteriaID', 'Rule2ActionsID', 'ActionValues'];
    
    // Remove properties from Rule and its Criteria and Actions
    this.removePropertiesFromObjects([Rule], IDColumnnames);
    this.removePropertiesFromObjects(Rule.Criteria, IDColumnnames);
    this.removePropertiesFromObjects(Rule.Actions, IDColumnnames);    

    
    const payload = {
      mutation: {
        Create: {
          Rule
        }
      }
    }
    const newRule = await this.invokeAPIWithPayload(payload);
    if (newRule) {
      this.snackBarService.openSnackbar(
        "Created Rule",
        SnackbarType.success
      );
    } else {
      this.snackBarService.openSnackbar(
        "Error creating rule",
        SnackbarType.error
      );
    }

  }

  /**
   * Input for here is technically a rule, but only the properties that need updating
   * Ex: {RuleID: 12, RuleName: "New Rule Name", Description: "Hello World"} would change rule name and description
   * but would keep everything else (criteria, actions, etc...)
   * @param {any} updateRule 
   */
  async updateRule(Rule: any) {

    this.removePropertiesFromObjects(Rule.Actions, ['ActionValues']);  

    //convert actions to string
    if (Rule.Actions?.length > 0) {
      for (const action of Rule.Actions) {
        if (Array.isArray(action.ActionValue)) {
          action.ActionValue = action.ActionValue?.join(',');
        }
      }
    }

    //convert Rule.criteria from an array to a string 
    if (Rule.Criteria?.length > 0) {
      for (const criteria of Rule.Criteria) {
        //if criteria.Values is an array, convert it to a string
        if (Array.isArray(criteria.Values)) {
          criteria.Values = criteria.Values?.join(',');
        }
      }
    }

    //convert Rule.isActive to a 1 or 0
    if (Rule.isActive == true) {
      Rule.isActive = 1;
    } else if (Rule.isActive == false) {
      Rule.isActive = 0;
    }

    const payload = {
      mutation: {
        Update: {
          Rule
        }
      }
    }
    const result = await this.invokeAPIWithPayload(payload);
    if (result) {
      this.snackBarService.openSnackbar(
        "Updated Rule",
        SnackbarType.success, 
      );
    } else {
      this.snackBarService.openSnackbar(
        "Error updating rule",
        SnackbarType.error
      );
    }
  }

  /**
   * Rule to Delete 
   * {RuleID: X, delete: true, ...Rule}
   * @param {Rule} deleteRule 
   */
  async deleteRule(deleteRule: Rule) {
    const payload = {
      mutation: {
        Delete: {
          Rule: {
            RuleID: deleteRule.RuleID,
            all: true
          }
        }
      }
    }
    const result = await this.invokeAPIWithPayload(payload);
    if (result) {
      this.snackBarService.openSnackbar(
        "Deleted Rule",
        SnackbarType.success
      );
    } else {
      console.log(result);
      this.snackBarService.openSnackbar(
        "Error deleting rule",
        SnackbarType.error
      );
    }
  }

  /**
   * Calls the API with specific payload 
   */
  private async invokeAPIWithPayload(apiValue: any) {
    this.progressBarService.start();
    let result = null;
      try {
        const apiKey = apiKeys.u2.autoEngineController;
        const url = apiKeys.u2[apiKey];
        const type = api[url].type;

        const utilocateApiRequest: UtilocateApiRequest = {
          API_KEY: apiKey,
          API_TYPE: type,
          API_BODY: apiValue,
        };

        const apiResult = await this.utilocateAPIService.invokeUtilocateApi(
          utilocateApiRequest
        );
        if (apiResult.body) {
          result = apiResult.body;
        } else {
          console.log(apiResult.error);
        }
      } catch (error) {
        console.error(error);
      }
      this.progressBarService.stop();
    return result;
  }
}