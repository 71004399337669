import { Component, Input, OnDestroy } from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { isEqual } from 'lodash-es';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-competers-depricated-input',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CompetersDeprecatedInputComponent,
    },
  ],
  template: `
    <div
      class="box-border flex flex-row justify-{{
        showLabel ? 'between' : 'start'
      }} items-center bg-transparent size-full">
      @if (title && showLabel && title.length > 0) {
        <label
          for="me"
          class="font-rajdhani font-semibold text-md uppercase max-w-[80px] overflow-hidden overflow-ellipsis">
          {{ title }}
        </label>
      }
      @if (type === 'number') {
        <input
          #input
          class="placeholder-gray-400 placeholder:font-montserrat placeholder:text-sm box-border border-solid border-1 border-[#D1D3D4] rounded-md w-full {{
            fitParentContainer ? 'size-full' : 'h-8  max-w-[336px]'
          }} px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          [id]="title"
          [placeholder]="placeholder"
          [name]="title"
          [type]="type"
          [disabled]="disabled"
          [ngModel]="val"
          (ngModelChange)="setValue($event)"
          [ngModelOptions]="{ standalone: true }" />
      } @else if (type === 'numbers') {
        <div class="flex flex-col w-full h-full items-end">
          <input
            #input
            class="placeholder-gray-400 placeholder:font-montserrat placeholder:text-sm box-border border-solid border-1 border-[#D1D3D4] rounded-md w-full {{
              fitParentContainer ? '' : 'max-w-[336px]'
            }} h-8 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            [id]="title"
            [placeholder]="placeholder"
            [attr.maxLength]="maxLength > 0 ? maxLength : null"
            [name]="title"
            type="text"
            [disabled]="disabled"
            [ngModel]="val"
            (input)="formatNumber($event)"
            [ngModelOptions]="{ standalone: true }" />
          @if (maxLength > 0) {
            <span [class]="getMaxLengthClass()">
              {{ val?.length ?? 0 }} / {{ maxLength }}
            </span>
          }
        </div>
      } @else if (multiLine) {
        <div class="flex flex-col w-full h-full items-end">
          <textarea
            #input
            class="placeholder-gray-400 font-montserrat text-md box-border border-solid border-1 border-[#D1D3D4] rounded-md w-full {{
              fitParentContainer ? '' : 'max-w-[336px]'
            }} h-20 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            [id]="title"
            [placeholder]="placeholder"
            [attr.maxLength]="maxLength > 0 ? maxLength : null"
            [disabled]="disabled"
            (resize)="(false)"
            [rows]="3"
            [ngModel]="val"
            (ngModelChange)="setValue($event)"
            [ngModelOptions]="{ standalone: true }"
            >{{ val }}</textarea
          >
          @if (maxLength > 0) {
            <span [class]="getMaxLengthClass()">
              {{ val?.length ?? 0 }} / {{ maxLength }}
            </span>
          }
        </div>
      } @else {
        <div class="flex flex-col w-full h-full items-end">
          <input
            #input
            class="placeholder-gray-400 placeholder:font-montserrat placeholder:text-sm box-border border-solid border-1 border-[#D1D3D4] rounded-md w-full {{
              fitParentContainer ? '' : 'max-w-[336px]'
            }} h-8 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            [id]="title"
            [placeholder]="placeholder"
            [attr.maxLength]="maxLength > 0 ? maxLength : null"
            [name]="title"
            [type]="type"
            [disabled]="disabled"
            [ngModel]="val"
            (ngModelChange)="setValue($event)"
            [ngModelOptions]="{ standalone: true }" />
          @if (maxLength > 0) {
            <span [class]="getMaxLengthClass()">
              {{ val?.length ?? 0 }} / {{ maxLength }}
            </span>
          }
        </div>
      }
    </div>
  `,
})
export class CompetersDeprecatedInputComponent
  implements OnDestroy, ControlValueAccessor
{
  @Input() type: string = InputType.text;
  @Input() title: string;
  @Input() showLabel: boolean = true;
  @Input() disabled = false;
  @Input() placeholder: string = '';
  @Input() fitParentContainer: boolean = false;
  /**
   * Whether to show a text area or just an input html element
   *
   * @type {boolean}
   * @memberof CompetersInputComponent
   */
  @Input() multiLine: boolean = false;

  /**
   * The max number of characters the input can be. Applies to only text
   *
   * @type {number}
   * @memberof CompetersInputComponent
   */
  @Input() maxLength: number = 0;

  private destroy$ = new Subject<void>();
  private touched = false;
  protected val: any;

  constructor() {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (val) => {};
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onTouched = (val) => {};

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getMaxLengthClass() {
    if (this.val?.length === this.maxLength && this.maxLength > 0) {
      return 'text-[10px] text-gray-400 pt-[2px] text-warn';
    } else {
      return 'text-[10px] text-gray-400 pt-[2px]';
    }
  }

  writeValue(data: any) {
    this.val = data;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setValue(val) {
    if (val !== undefined && !isEqual(this.val, val)) {
      this.val = val;
      this.onChange(val);
      this.onTouched(val);
    }
  }

  //Format input for array of numbers
  formatNumber(event) {
    let val = event.target.value;
    // Remove any characters that are not digits, spaces, commas, or periods
    val = val.replace(/[^0-9\s,.]/g, '');
    this.val = val;
    event.target.value = val;
    this.onChange(val);
    this.onTouched(val);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}

export const InputType = {
  text: 'text',
  password: 'password',
  email: 'email',
  number: 'number',
  tel: 'tel',
  url: 'url',
};
