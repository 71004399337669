import {ChangeDetectionStrategy, Component, HostListener, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatDivider} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridList, MatGridTile} from '@angular/material/grid-list';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {HomeWorkspaceService} from '../home-workspace.service';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MatIcon} from '@angular/material/icon';
import {MatList} from '@angular/material/list';
import {SidebarItemComponent} from './sidebar-item/sidebar-item.component';
import {EventEmitter} from '@angular/core';
import {ScrollingModule} from '@angular/cdk/scrolling';

@Component({
  selector: 'app-sidebar-list',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatGridList,
    MatDivider,
    MatFormFieldModule,
    MatSelectModule,
    MatRadioModule,
    MatIcon,
    MatGridTile,
    MatList,
    SidebarItemComponent,
    ScrollingModule
  ],
  templateUrl: './sidebar-list.component.html',
  styleUrl: './sidebar-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarListComponent implements OnInit {

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.canShowAnalyzerButton();
  }

  mainData: any[] = []; // main data object
  filteredData: any[] = []; // displayed data
  panelOpenState: boolean = false;
  @Output() expanded: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('MainContainer', {}) elementMainContainer: any;
  @ViewChild('HeadContainer', {}) elementHeadContainer: any;
  @ViewChild('ListContainer', {}) elementListContainer: any;
  @ViewChildren('locatorSumComps') elementLocatorSumComps: QueryList<SidebarItemComponent>;


  filterValue: number = -1;
  supervisors: any = [
    {"value": 1, "visibleValue": "All"}
  ];

  sortValue: string = "Name";
  columns: any = [
    {"value": "Name"},
    {"value": "Total"},
    {"value": "Meets"},
    {"value": "Projects"},
    {"value": "Lates"},
    {"value": "Near Lates"}
  ];

  sortOrder: number = 1;
  columnSorts: any = [
    {"value": 1, "visibleValue": "Ascend"},
    {"value": -1, "visibleValue": "Descend"}
  ];

  constructor(
    private homeWorkspaceService: HomeWorkspaceService
  ) {

  }

  async ngOnInit() {
    this.refreshData()
  }

  async refreshData() {
    const results = await this.homeWorkspaceService.queryLocators();
    // console.log(results);
    if (results != null && results.length > 0) {
      for (const user of results) {
        const tmpUser = user;
        tmpUser["isAdded"] = false;
        this.mainData.push(tmpUser);
        this.filteredData.push(tmpUser);
      }
      this.setupSupervisors();
      this.updatedSort({isUserInput: true}, this.sortValue);
    }
  }

  //triggers when the expansion panel opens or closes 
togglePanel() {
  this.panelOpenState = !this.panelOpenState;
  this.expanded.emit(this.panelOpenState);
}


  updatedFilter(event: any, supervisorID: number) {
    if (event.isUserInput) {
      if (this.mainData != null) {
        // console.log("updatedFilter", event, supervisorID);
        let newFilteredData = [];

        if (supervisorID == -1) {
          newFilteredData = [...this.mainData];
        } else {
          for (const i in this.mainData) {
            const curRow: any = this.mainData[i];

            if (curRow["SupervisorID"] == supervisorID || curRow["UserID"] == supervisorID) {
              newFilteredData.push(curRow);
            }
          }
        }
        this.filteredData = [...newFilteredData];
        // this.changeDetection.detectChanges();
        // console.log("filteredData:", this.filteredData)
      }
    }
  }

  updatedSort(event: any, sortBy: string) {
    if (event.isUserInput) {
      if (this.mainData != null) {
        this.mainData.sort((first: any, second: any) => {
          if (sortBy == "Name") {
            if (first["name"].toLowerCase() < second["name"].toLowerCase()) {
              return -1 * this.sortOrder;
            } else if (first["name"].toLowerCase() > second["name"]) {
              return 1 * this.sortOrder;
            } else {
              return 0;
            }
          } else {
            if (first["ticketInfo"][sortBy] < second["ticketInfo"][sortBy]) {
              return -1 * this.sortOrder;
            } else if (first["ticketInfo"][sortBy] > second["ticketInfo"][sortBy]) {
              return 1 * this.sortOrder;
            } else {
              return 0;
            }
          }
        });
        this.updatedFilter(event, this.filterValue);
      }
    }
  }

  updatedSortOrder(event: any, value: any) {
    // console.log("updatedSortOrder", event, value);
    this.sortOrder = parseInt(value);
    this.updatedSort({isUserInput: true}, this.sortValue);
  }

  setupSupervisors() {
    if (this.mainData != null) {
      const newSupervisors: any = [
        {"value": -1, "visibleValue": "All"}
      ];

      for (const i in this.mainData) {
        const row: any = this.mainData[i];

        if (row["SupervisorID"] != null && row["SupervisorName"] != null && row["SupervisorName"] != "") {
          let isInSupervisors: boolean = false;

          for (const ii in newSupervisors) {
            const curSupervisor: any = newSupervisors[ii];
            if (curSupervisor["value"] == row["SupervisorID"]) {
              isInSupervisors = true;
              break;
            }
          }
          if (!isInSupervisors) {
            newSupervisors.push({"value": row["SupervisorID"], "visibleValue": row["SupervisorName"]});
          }
        }
      }

      // console.log("setupSupervisors newSupervisors:", newSupervisors);
      this.supervisors = newSupervisors;
    }
  }

  //Child Callbacks

  updateComponentButtons($event: object) {
    try {
      // console.log("updateComponentButtons",$event);

      if ($event != null && $event["data"] != null) {
        const eventUserID: number = $event["data"]["UserID"];
        const isAdded = $event["data"]["isAdded"];

        for (const i in this.mainData) {
          const curDat: any = this.mainData[i];
          const curUserID: number = curDat["UserID"];

          if (curUserID == eventUserID) {
            curDat["isAdded"] = isAdded;
            this.mainData[i] = curDat;
            break;
          }
        }
        // let message: any = { "UserID": eventUserID, "AddUser": removeBtnVisibility };
        // changeSelectedUserDispatchRoutingController(message);
        // console.log("mainData:", this.mainData);
      }
    } catch (ex) {
      console.error("failed to updateComponentButtons: ", ex)
    }
  }

  canShowAnalyzerButton():boolean {
    return window.innerWidth > 768;
  }
}

