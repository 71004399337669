import {
  Component,
  computed,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Signal,
  signal,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  ColorPickerComponent,
  ColorPickerEventArgs,
  ColorPickerModule,
  PaletteTileEventArgs,
} from '@syncfusion/ej2-angular-inputs';
import { MatSidenav } from '@angular/material/sidenav';
import {
  lineThickness,
  lineTools,
  lineTypes,
  mainMenu,
  manipulationTools,
  MenuItem,
  selectionTools,
  shapeTools,
} from './drawing-tool-menus';
import { BehaviorSubject, distinctUntilChanged, firstValueFrom, from, Observable, of, Subject, switchMap } from 'rxjs';
import { DrawingToolbarService } from './drawing-toolbar.service';
import {
  CustomLine,
  LineStyle,
  LineThickness,
  LineTools,
  ManipulationTools,
  SelectionTools,
  ShapeTools,
  StickerGroup,
  ToolType,
} from '../utilities/types';
import { ToolbarRadioGroupComponent } from './toolbar-radio-group/toolbar-radio-group.component';
import { combineLatestWith, concatMap, map, startWith, takeUntil, toArray } from 'rxjs/operators';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { ReactiveFormsModule } from '@angular/forms';
import { addClass } from '@syncfusion/ej2-base';
import { MapSymbolsService } from '../services/map-symbols.service';
import { FabOverlayComponent } from '../../../shared/components/containers/fab-overlay/fab-overlay.component';
import { AsyncPipe, NgClass, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { MenuItemToRadioInputPipe } from './drawing-toolbar.pipe';
import { MatCommonModule, MatOption } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { LayerManagerService } from '../services/layer-manager/layer-manager.service';
import { DrawingService, DrawingTypes } from '../drawing.service';
import { isEqual } from 'lodash-es';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CameraModalComponent } from '../../shared/ticket/modals/camera-modal/camera-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { dataURLToFile } from '../../../shared/functions/image';
import { MatTooltip } from '@angular/material/tooltip';
import { AppScrollerComponent } from '../../../shared/components/misc/scroller/scroller.component';
import { GlobalSubLayers } from '../services/layer-manager/types/layer.types';

const customColours = {
  stroke: [
    '#FFFFFF',
    '#ced4da',
    '#6c757d',
    '#343a40',
    '#000000',
    '#C8102E',
    '#FF8200',
    '#FFD100',
    '#007B5F',
    '#0072CE',
    '#6D2077',
    '#FF00FF',
  ],
  fill: [
    '',
    '#FFFFFF',
    '#ced4da',
    '#6c757d',
    '#000000',
    '#C8102E',
    '#FF8200',
    '#FFD100',
    '#007B5F',
    '#0072CE',
    '#6D2077',
    '#FF00FF',
  ],
};

@Component({
  selector: 'app-drawing-toolbar',
  standalone: true,
  imports: [
    MatCommonModule,
    // FabOverlayComponent,
    ReactiveFormsModule,
    NgClass,
    NgTemplateOutlet,
    ToolbarRadioGroupComponent,
    ColorPickerModule,
    MenuItemToRadioInputPipe,
    AsyncPipe,
    MatIcon,
    MatSelect,
    MatOption,
    MatTooltip,
    FabOverlayComponent,
    NgOptimizedImage,
    AppScrollerComponent,
  ],
  templateUrl: './drawing-toolbar.component.html',
  styleUrls: ['./drawing-toolbar.component.scss'],
})
export class DrawingToolbarComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChildren(ToolbarRadioGroupComponent)
  radioGroups: ToolbarRadioGroupComponent<unknown>[];
  @ViewChild('colourPreview') colourPreview: ElementRef;
  @ViewChild('colorpicker') colorPicker: ColorPickerComponent;
  @ViewChild(MatSidenav) openedSidenav: MatSidenav;
  //IO
  @Output() fileChanged = new EventEmitter<File>();
  @Output() deleteClicked = new EventEmitter<void>();
  // members
  toolType = ToolType;
  positionPairs: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 24,
      offsetX: 6,
    },
  ];
  //signals
  mainMenu$$ = signal(mainMenu);
  shapeTools$$ = signal<MenuItem[]>(shapeTools);
  interactionSelection$$: Signal<ToolType>;
  lineTools: MenuItem[] = lineTools;
  lineTypes: MenuItem[] = lineTypes;
  lineThicknesses: MenuItem[] = lineThickness;
  selectionTools: MenuItem[] = selectionTools;
  manipulationTools: MenuItem[] = manipulationTools;
  isPortrait = false;
  realignTrigger$: Subject<void> = new Subject<void>();
  // Triggers before rendering each palette tile.
  public customColors: { [key: string]: string[] } = customColours;
  // To specify number of columns to be rendered.
  public colCount: number = 3;
  // get stickers from the service and format them for the radio group
  protected stickers$ = this.myService.getStickers().pipe(
    map((group) =>
      group.map((sticker) => {
        return {
          value: sticker,
          altText: sticker['StickerFileName'].split('.')[0],
          imgSource: '',
        };
      })
    )
  );
  // get stickers from the form and format them for the preview
  protected stickerPreview$$: Signal<SafeUrl>;
  protected symbols$: Observable<
    Array<{
      value: symbol;
      altText: string;
      imgSource: string;
    }>
  >;
  // get utility lines from the service and format them for the radio group
  protected UtilityLinesData$: Observable<CustomLine[]> = this.myService.getUtilityLines().pipe(
    concatMap((vals) =>
      from(vals).pipe(
        startWith({
          LineType: 'None',
          LineID: -1,
          LineColour: '',
          LineText: '',
          LineOrder: -1,
          TextColour: '',
          Dashed: [],
        } as CustomLine),
        toArray()
      )
    )
  );
  protected readonly DrawingTypes = DrawingTypes;
  // Services
  private layerManager = inject(LayerManagerService);
  // determine if the selected layer is a locate area layer
  protected isLocateAreaLayer$ = this.layerManager.selectedLayer$.pipe(
    map((x) => x?.get('subLayerID') === GlobalSubLayers.LocateAreas),
    distinctUntilChanged()
  );
  private drawingService = inject(DrawingService);
  private domSanitizer = inject(DomSanitizer);
  private dialog = inject(MatDialog);
  // Observables
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public myService: DrawingToolbarService,
    protected symbolService: MapSymbolsService
  ) {
    this.interactionSelection$$ = computed(() => this.myService.toolbarState$$().toolPage);
    this.stickerPreview$$ = computed(() => {
      let url: SafeUrl = '';
      const { sticker } = this.myService.toolbarState$$();
      if (sticker) {
        const { StickerFile } = sticker;
        if (StickerFile) {
          url = this.domSanitizer.bypassSecurityTrustUrl(StickerFile);
        }
      }
      return url;
    });

    const allowedLocateAreaMenus = [ToolType.selectionTool, ToolType.shapeTool];
    // combine drawing type and locate area layer to determine which menus to show
    const changes = this.drawingService.drawingType$.pipe(
      combineLatestWith(this.isLocateAreaLayer$),
      distinctUntilChanged((a, b) => isEqual(a, b))
    );

    // filter main menu items when 'changes' fires
    changes
      .pipe(
        switchMap(([drawingType, isLocateAreaLayer]) => {
          return of(
            mainMenu.filter((item) => {
              if (drawingType === DrawingTypes.map) {
                if (ToolType.imageTool === item.type) {
                  return false;
                }
                if (isLocateAreaLayer) {
                  return allowedLocateAreaMenus.includes(item.type);
                }
                return true;
              }
              return true;
            })
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((menu) => this.mainMenu$$.set(menu));

    // filter shape menu items when 'changes' fires
    changes
      .pipe(
        map(([type, isLocateArea]) => {
          if (type === DrawingTypes.map && isLocateArea) {
            return shapeTools.filter((subMenuItem) => subMenuItem.name !== ShapeTools.circle);
          } else {
            return shapeTools;
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((next) => {
        this.shapeTools$$.set(next);
      });

    // set the default tool page for when the type of selected layer changes
    this.isLocateAreaLayer$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res) {
        this.myService.updateToolbarState({ toolPage: ToolType.shapeTool, shapeTool: ShapeTools.square });
      }
    });

    // filters the symbols for the radio group
    this.symbols$ = this.symbolService.legacySymbolIcons$.pipe(
      map((group) =>
        group.map((symbol) => {
          return {
            value: symbol,
            altText: symbol['tooltip'],
            imgSource: '',
          };
        })
      )
    );
  }

  // lifecycle methods
  ngOnInit(): void {
    this.myService.gatherUtilityLines();
    this.myService.gatherStickers();
    this.handleViewportChange();
    this.myService.updateToolbarState({}, 'initialization');
  }

  bufferToImage(buffer: Buffer) {
    const base64Image = btoa(new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    return `data:image/jpeg;base64,${base64Image}`;
  }

  ngOnChanges() {
    this.realignTrigger$.next();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  deleteClick() {
    this.deleteClicked.emit();
    // this.myService.fireDelete();
  }

  @HostListener('window:resize')
  handleViewportChange() {
    this.isPortrait = window.innerHeight > window.innerWidth;
  }

  public tileRender(args: PaletteTileEventArgs): void {
    addClass([args.element], ['e-icons', 'e-custom-tile']);
  }

  // Triggers while selecting colors from palette.
  public onColourChange(args: ColorPickerEventArgs): void {
    const element = this.colourPreview.nativeElement;
    element.style.backgroundColor = args.currentValue.hex;
  }

  openPhotoModal() {
    const newPhotoSubject = new BehaviorSubject(null);
    firstValueFrom(
      this.dialog
        .open(CameraModalComponent, {
          disableClose: true,
          maxWidth: '100vw',
          maxHeight: '100vh',
          height: '100%',
          width: '100%',
          data: {
            newPhotoSubject,
            photoLimit: 1,
          },
          panelClass: 'nothing-modal',
        })
        .afterClosed()
        .pipe(switchMap(() => newPhotoSubject))
    ).then((val) => {
      if (val) {
        this.fileChanged.emit(dataURLToFile(val.imageAsDataUrl, 'file') as File);
        return;
      }
      this.fileChanged.emit(null);
    });
  }

  protected handleNewFile(input: HTMLInputElement) {
    if (input.files.length > 0) {
      this.fileChanged.emit(input.files[0]);
    } else {
      this.fileChanged.emit(null);
    }
  }

  protected clearFileInput(input: HTMLInputElement) {
    input.value = null;
    this.fileChanged.emit(null);
  }

  changePage(toolType: ToolType) {
    this.myService.updateToolbarState({ toolPage: toolType });
  }

  onSelectionToolChange(tool: SelectionTools) {
    this.myService.updateToolbarState({ selectionTool: tool });
  }

  onLineToolChange(tool: LineTools) {
    this.myService.updateToolbarState({ lineTool: tool });
  }

  onShapeToolChange(tool: ShapeTools) {
    this.myService.updateToolbarState({ shapeTool: tool });
  }

  onLineStyleChange(style: LineStyle) {
    this.myService.updateToolbarState({ lineStyle: style });
  }

  onLineThicknessChange(thickness: LineThickness) {
    this.myService.updateToolbarState({ lineThickness: thickness });
  }

  onStickerChange(sticker: StickerGroup) {
    this.myService.updateToolbarState({ sticker });
  }

  onUtilityLineChange(event: MatSelectChange) {
    this.myService.updateToolbarState({ utilityLine: event.value });
  }

  trimColourString(colour: string) {
    if (colour.length === 9) {
      return colour.substring(0, colour.length - 2);
    }
    return colour;
  }

  onStrokeColorChange(event: ColourChangeEvent) {
    this.myService.updateToolbarState({ strokeColour: this.trimColourString(event.value) });
  }

  onFillColorChange(event: ColourChangeEvent) {
    this.myService.updateToolbarState({ fillColour: this.trimColourString(event.value) });
  }

  onOpacityChange(event: string) {
    this.myService.updateToolbarState({ opacity: parseInt(event, 10) });
  }

  onManipulationToolChange(tool: ManipulationTools) {
    this.myService.updateToolbarState({ manipulationTool: tool });
  }

  protected readonly window = window;
}

export type ColourChangeEvent = {
  currentValue: ColourChangeEventValue;
  previousValue: ColourChangeEventValue;
  value: string;
  name: string;
};
export type ColourChangeEventValue = {
  hex: string;
  rgba: string;
};
