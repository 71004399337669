<div class="shadow-2xl bg-white rounded-md">
  <app-vertical-collapsible
    [showArrow]="false"
    [isOpen]="myService.isOpen$ | async"
    (isOpenChange)="myService.isOpen$ = $event">
    <div title class="w-full flex flex-row justify-between items-center py-1.5 pl-3 pr-1.5">
      <div class="flex flex-row gap-4 justify-start items-center">
        <mat-icon svgIcon="dispatchArea" />
        <span class="font-rajdhani font-semibold text-lg uppercase">Dispatch Area</span>
      </div>
      <span class="size-6 appearance-none bg-transparent cursor-pointer border-none p-0">
        <mat-icon svgIcon="arrow_down" class="{{ (myService.isOpen$ | async) ? '' : 'rotate-90' }}"></mat-icon>
      </span>
    </div>

    <div content>
      <div class="flex flex-col gap-4 p-3 pt-0">
        <div class="w-64 h-[30px]">
          <app-searchable-dropdown
            placeholder="Search Area By User"
            [fitParentContainer]="true"
            [disabled]="(myService.addingArea$ | async) || (myService.modifyingArea$ | async)"
            [multiple]="false"
            [inlined]="false"
            [options]="_formOptions$$()['areas']"
            [ngModel]="myService.areaQuery$ | async"
            (ngModelChange)="myService.areaQuery = $event"
            [ngModelOptions]="{ standalone: true }" />
        </div>
        <div class="w-64 h-[30px]">
          <app-searchable-dropdown
            placeholder="Select Layer"
            [fitParentContainer]="true"
            [disabled]="(myService.addingArea$ | async) || (myService.modifyingArea$ | async)"
            [multiple]="false"
            [inlined]="false"
            [options]="this.dispatchAreaService.dispatchLayers$ | async | MapArrayToSelectionOption: 'Name' : 'LayerID'"
            [ngModel]="myService.selectedLayer$ | async"
            (ngModelChange)="myService.selectedLayer = $event"
            [ngModelOptions]="{ standalone: true }" />
        </div>

        <!-- Slide toggle to show dispatch area colours -->
        @if (_showRouteEditingButtons) {
          <app-slide-toggle
            text="Toggle Dispatch Area Colors"
            textPosition="left"
            [(value)]="showHideDispatchAreaColors" />
        }

        <form class="flex flex-col gap-4 w-fit" [formGroup]="form">
          <div class="flex flex-row justify-between items-center w-64">
            <span class="font-rajdhani font-semibold uppercase text-md">Name</span>
            <div class="w-48 h-[30px]">
              <app-competers-depricated-input
                placeholder="Type..."
                inlined="true"
                [fitParentContainer]="true"
                [formControl]="getSubControls('name')" />
            </div>
          </div>
          <div class="flex flex-row justify-between items-center w-64">
            <span class="font-rajdhani font-semibold uppercase text-md">User</span>
            <div class="w-48 h-[30px]">
              <app-searchable-dropdown
                placeholder="Select User"
                [fitParentContainer]="true"
                [multiple]="false"
                [inlined]="false"
                [formControl]="getSubControls('user')"
                [options]="_formOptions$$()['users']" />
            </div>
          </div>
          <div class="flex flex-row justify-between items-center w-64">
            <span class="font-rajdhani font-semibold uppercase text-md">Manager</span>
            <div class="w-48 h-[30px]">
              <app-searchable-dropdown
                placeholder="Select Area Manager"
                [fitParentContainer]="true"
                [multiple]="false"
                [inlined]="false"
                [formControl]="getSubControls('managerArea')"
                [options]="
                  this.dispatchAreaService.managerAreas$
                    | async
                    | MapArrayToSelectionOption: 'ManagerAreaName' : 'ManagerAreaID'
                " />
            </div>
          </div>
          @if (_showRouteEditingButtons) {
            <div class="flex flex-row justify-between items-center w-64">
              <span class="font-rajdhani font-semibold uppercase text-md">Routing</span>
              <div class="w-48 h-[30px]">
                <app-searchable-dropdown
                  placeholder="Select Routing"
                  [fitParentContainer]="true"
                  [multiple]="false"
                  [inlined]="false"
                  [formControl]="getSubControls('routing')"
                  [options]="
                    this.dispatchAreaService.routingAlgorithms$
                      | async
                      | MapArrayToSelectionOption: 'AlgorithmName' : 'AlgorithmID'
                  " />
              </div>
            </div>
          }
        </form>
      </div>
      @if (myService.userCanModifySetting[0] && myService.userCanModifySetting[1] == '1') {
        <hr class="h-px m-0 bg-gray-200 border-0" />
        <div class="flex flex-row justify-between items-center p-3">
          <div class="flex flex-row justify-start items-center">
            @if (
              (myService.selectedArea$ | async).length > 0 &&
              !(myService.modifyingArea$ | async) &&
              !(myService.addingArea$ | async)
            ) {
              <button
                class="flex justify-center items-center appearance-none bg-transparent border-none"
                (click)="myService.deleteArea()">
                <mat-icon class="text-warn" svgIcon="trash" />
              </button>
            }
            @if ((myService.modifyingArea$ | async) || (myService.addingArea$ | async)) {
              <button
                class="flex justify-center items-center appearance-none bg-transparent border-none"
                (click)="submitForm()">
                <mat-icon svgIcon="save" />
              </button>
            }
          </div>
          <div class="flex flex-row justify-end items-center">
            @if ((myService.addingArea$ | async) || (myService.modifyingArea$ | async)) {
              <button
                class="flex justify-center items-center appearance-none bg-transparent border-none hover:bg-gray-100 rounded-full p-2"
                (click)="cancelAction()">
                <mat-icon class="rotate-45 scale-105 text-warn" svgIcon="add" />
              </button>
            } @else {
              <button
                class="flex justify-center items-center appearance-none bg-transparent border-none hover:bg-gray-100 rounded-full p-2"
                (click)="myService.addingArea = !myService.addingArea">
                <mat-icon svgIcon="add" />
              </button>
            }
            @if (!(myService.addingArea$ | async) && !(myService.modifyingArea$ | async)) {
              <button
                (click)="myService.modifyingArea = true"
                [disabled]="!((myService.selectedArea$ | async).length > 0)"
                class="appearance-none border-none rounded-md font-rajdhani font-semibold uppercase text-white bg-primary text-lg py-2 px-4">
                modify
              </button>
            }
          </div>
        </div>
      }
    </div>
  </app-vertical-collapsible>
</div>
