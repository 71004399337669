import { Component, OnDestroy, OnInit } from '@angular/core';
import { firstValueFrom, from, Subject, switchMap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { SavedSearchTileComponent } from './saved-search-tile/saved-search-tile.component';
import { map, takeUntil, tap } from 'rxjs/operators';
import { SavedSearch, TicketSearchService } from '../../../shared/services/ticket-search/ticket-search.service';
import { SearchHistoryTileComponent } from './current-search-tile/search-history-tile.component';
import { VerticalCollapsibleComponent } from '../../../shared/components/containers/vertical-collapsible/vertical-collapsible.component';

@Component({
  selector: 'app-saved-search-drawer',
  styleUrls: ['./saved-search-drawer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CdkAccordionModule,
    SavedSearchTileComponent,
    MatListModule,
    MatIconModule,
    SearchHistoryTileComponent,
    VerticalCollapsibleComponent,
  ],
  template: `
    <div class="relative w-auto h-full">
      <button
        (click)="handleClick()"
        class="fixed flex justify-center items-center w-8 h-8 rounded-full translate-y-[30px] {{
          isOpen ? 'translate-x-[225px] ' : '-translate-x-[15px]'
        }} transition-all ease-in-out duration-700 bg-gray-200 isolate z-50 border-none shadow-black shadow cursor-pointer">
        <div class="flex flex-row justify-center items-center">
          @if (isOpen) {
            <mat-icon style="font-size:16px; width:16px; height:16px">keyboard_arrow_left</mat-icon>
          } @else {
            <mat-icon style="font-size:16px; width:16px; height:16px">keyboard_arrow_right</mat-icon>
          }
        </div>
      </button>
      <div
        class="box-border flex flex-col {{
          isOpen ? 'w-[240px]' : 'w-0'
        }} transition-all ease-in-out duration-700 justify-start items-center py-3 gap-3 isolate shadow-md bg-white h-full max-h-full overflow-y-auto overflow-x-hidden">
        <app-vertical-collapsible class="w-[216px] min-w-fit">
          <span title class="font-rajdhani font-semibold text-sm uppercase">Search History</span>
          <div content>
            @if (searchService.lastSearch$$()) {
              <app-search-history-tile />
            } @else {
              <div class="flex flex-col items-center justify-center w-full h-full">
                <mat-icon class="text-gray-400 w-12 h-12">search</mat-icon>
                <span class="text-gray-400 text-sm">No search history</span>
              </div>
            }
          </div>
        </app-vertical-collapsible>
        <app-vertical-collapsible class="w-[216px] min-w-fit">
          <span title class="font-rajdhani font-semibold text-sm uppercase">My Searches</span>
          <div
            content
            class="flex flex-col justify-start items-start {{
              savedSearches.length > 0 ? 'border-gray-400' : 'border-transparent'
            }} border-solid border-1 border-opacity-30 rounded-md">
            @for (search of savedSearches; track $index) {
              <app-saved-search-tile
                [showCount]="false"
                [showDelete]="true"
                [collectionTitle]="'Saved Searches'"
                [search]="search"
                [isFirstChild]="$index === 0"
                [isLastChild]="$index === savedSearches.length - 1" />
            }
          </div>
        </app-vertical-collapsible>
        <app-vertical-collapsible class="w-[216px] min-w-fit">
          <span title class="font-rajdhani font-semibold text-sm uppercase">Universal Searches</span>
          <div
            content
            class="flex flex-col justify-start items-start {{
              universalSearches.length > 0 ? 'border-gray-400' : 'border-transparent'
            }} border-solid border-1 border-opacity-30 rounded-md">
            @for (search of universalSearches; track $index) {
              <app-saved-search-tile
                [showCount]="true"
                [collectionTitle]="'Saved Searches'"
                [search]="search"
                [isFirstChild]="$index === 0"
                [isLastChild]="$index === universalSearches.length - 1" />
            }
          </div>
        </app-vertical-collapsible>
      </div>
    </div>
  `,
})
export class SavedSearchDrawerComponent implements OnInit, OnDestroy {
  // observables && subscriptions
  private _destroy$ = new Subject<void>();

  savedSearches: SavedSearch[] = [];
  universalSearches: any[] | SavedSearch[] = [];
  protected isOpen = true;

  constructor(
    public searchService: TicketSearchService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.restoreValuesFromSessionStorage();
    this.searchService.savedSearches$
      .pipe(
        takeUntil(this._destroy$),
        tap(() => {
          this.universalSearches = [];
          this.savedSearches = [];
        }),
        switchMap((res) => from(res)),
        map(({ SavedSearchID, SearchName, SavedSearchCriteria, TemplateID, ClientID, Count }) => ({
          SavedSearchID,
          SearchName,
          SavedSearchCriteria,
          TemplateID,
          ClientID,
          Count,
        })),
        tap((search) => {
          if (search.ClientID == 0) {
            this.universalSearches.push(search);
          } else {
            this.savedSearches.push(search);
          }
        })
      )
      .subscribe();
    firstValueFrom(this.searchService.refreshSavedSearches()).then(() => null);
  }

  protected handleClick() {
    this.isOpen = !this.isOpen;
    this.saveToSessionStorage();
  }

  private restoreValuesFromSessionStorage() {
    const isOpen = localStorage.getItem('savedSearchDrawerComponent__isOpen');

    if (isOpen !== null) {
      this.isOpen = JSON.parse(isOpen);
    }
  }

  private saveToSessionStorage() {
    localStorage.setItem('savedSearchDrawerComponent__isOpen', JSON.stringify(this.isOpen));
  }

  ngOnDestroy(): void {
    this.saveToSessionStorage();
    this._destroy$.next();
    this._destroy$.complete();
  }
}
